import React, { useEffect, useState } from "react";
import bannerImage from "../../Images/bg-jtrc-banner.jpg";
import logoAdress from "../../Images/logo_adrees_bar.png";
import whatsappImg from "../../Images/whatsapp.png";
import callImg from "../../Images/Call.png";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import Loader from "../../Components/loader/loader";
import { Apiurl, SellerStoreId } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import MainStoreCategory from "../../Components/MainStore/MainStoreCategory";
import MainStoreResults from "../../Components/MainStore/MainStoreResults";

const Store = () => {
  const [isPageLoading, setIspageLoading] = useState(false);
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [MyAds, setMyAds] = useState([]);
  const [sellerStoreData, setSellerStoreData] = useState();

  const [storeName, setStoreName] = useState();
  const [brand, setBrand] = useState();

  const [model, setModel] = useState();
  const [category, setCategory] = useState();
  const [fuelType, setFuelType] = useState();
  const [kmDriven, setKmDriven] = useState();
  const [priceRange, setPriceRange] = useState();
  const [type, setType] = useState();
  const [brandData, setBrandData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [priceRangeData, setPriceRangeData] = useState([]);
  const [fuelTypedata, setFuelTypeData] = useState([]);
  const [kmdrivenData, setKmdrivenData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [transmissionData, setTransmissionData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [allData, setAllData] = useState();
  const [toggle, setToggle] = useState();
  const [transmission, setTransmission] = useState();

  // ----------check by url-----------
  const location = useLocation();
  const pathname = location?.pathname;
  const lastSlashIndex = pathname.lastIndexOf("/");
  const afterLastSlash = pathname.slice(lastSlashIndex + 1);
  // const ByStoreName = afterLastSlash?.replace(/-/g, " ");

  const navigate = useNavigate();
  const state = useLocation();

  useEffect(() => {
    setStoreName(state?.state?.storeName?.replace(/-/g, " "));
  }, [state]);

  // --------------Get Store Page Detai-------------------//
  const GetStoreData = (e) => {
    setIspageLoading(true);
    const data = {};
    data.storeName = afterLastSlash || storeName;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Seller/GetStorePageDetails`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const SellerData = JSON.parse(e?.data?.data);
        setSellerStoreData(SellerData);
        SellerStoreId(SellerData?.Id);
        setAllData(JSON.parse(SellerData?.Ads));
        setBrand(SellerData?.Filter?.Brand);
        setModel(SellerData?.Filter?.Model);
        setTransmission(SellerData?.Filter?.Transmission);
        setCategory(SellerData?.Filter?.Category);
        setFuelType(SellerData?.Filter?.FuelType);
        setKmDriven(SellerData?.Filter?.KmDriven);
        setPriceRange(SellerData?.Filter?.PriceRange);
        setType(SellerData?.Filter?.Type);
      })
      .catch((error) => {
        setIspageLoading(false);
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    if (storeName || afterLastSlash) {
      GetStoreData();
    }
  }, [storeName, toggle, afterLastSlash]);

  useEffect(() => {
    if (
      brandData?.length ||
      modelData?.length ||
      priceRangeData?.length ||
      fuelTypedata?.length ||
      transmissionData?.length ||
      kmdrivenData?.length ||
      typeData?.length ||
      categoryData?.length
    ) {
      setMyAds(filterData);
    } else {
      setMyAds(allData);
    }
  }, [
    allData,
    filterData,
    brandData,
    modelData,
    priceRangeData,
    fuelTypedata,
    transmissionData,
    kmdrivenData,
    kmdrivenData,
    typeData,
    categoryData,
  ]);

  return (
    <div>
      <Header />

      <section className="main-store-page">
        <div className="product-deal-section">
          <div className="row">
            <div className="col-lg-12">
              <img
                src={sellerStoreData?.SellerStore?.BannerImageUrl}
                alt="bg-jtrc-banner"
                className="store_main_image"
              />
            </div>
          </div>
        </div>

        <div className="detail_nav_section">
          <div className="row">
            <div className="logo-image-div">
              <img
                src={sellerStoreData?.SellerStore?.LogoUrl}
                alt="bg-jtrc-banner"
                className="w-80 logo-image-store"
              />
            </div>
            <div className="main-store-adress">
              <ul className="store_list_ul">
                <li>{sellerStoreData?.SellerStore?.StoreName}</li>
                <li>{sellerStoreData?.SellerStore?.ContactNo}</li>
                <li>{sellerStoreData?.SellerStore?.Address}</li>
              </ul>
            </div>
            <div className="main-store-contact">
              <div className="contact_sport">
                <h5>Contact Support</h5>
                <ul className="sociel_list">
                  <li className="mr-4">
                    <a />
                    <img src={whatsappImg} alt="WhatsApp" width="40" />
                  </li>
                  <li>
                    <a />
                    <img src={callImg} alt="Call" width="40" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="border-btm"></div>
      </section>

      <>
        <main className="main_wrapper">
          <section className="filer_heading main-store-head">
            <div className="container">
              <div className="colsm-12">
                <div className="row">
                  <div className="col-sm-3">
                    <MainStoreCategory
                      MyAds={MyAds}
                      setMyAds={setMyAds}
                      brand={brand}
                      model={model}
                      category={category}
                      fuelType={fuelType}
                      kmDriven={kmDriven}
                      priceRange={priceRange}
                      type={type}
                      brandData={brandData}
                      setBrandData={setBrandData}
                      modelData={modelData}
                      setModelData={setModelData}
                      priceRangeData={priceRangeData}
                      setPriceRangeData={setPriceRangeData}
                      fuelTypedata={fuelTypedata}
                      setFuelTypeData={setFuelTypeData}
                      transmission={transmission}
                      kmdrivenData={kmdrivenData}
                      setKmdrivenData={setKmdrivenData}
                      typeData={typeData}
                      setTypeData={setTypeData}
                      categoryData={categoryData}
                      transmissionData={transmissionData}
                      setCategoryData={setCategoryData}
                      setTransmissionData={setTransmissionData}
                      filterData={filterData}
                      setFilterData={setFilterData}
                      sellerStoreData={sellerStoreData}
                    />
                  </div>
                  <div className="col-sm-9">
                    <MainStoreResults
                      MyAds={MyAds}
                      isPageLoading={isPageLoading}
                      setIspageLoading={setIspageLoading}
                      setToggle={setToggle}
                      toggle={toggle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>

      <Footer />
    </div>
  );
};

export default Store;

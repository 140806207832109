import React, { useEffect, useState } from "react";
import "../../../src/App.css";
import { Accordion } from "react-bootstrap";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Category = ({
  setAccessoryFilterData,
  accessoryTypeDataArray,
  setAccessoryTypeDataArray,
  accessoryDataPriceRange,
  setAccesoryDataPriceRange,
  setPartsFilterData,
  setpartsTypeDataArray,
  partsTypeDataArray,
  setpartsDataPriceRange,
  partsDataPriceRange,
  setVehicleFilterData,
  setvehicleDataPriceRange,
  vehicleDataPriceRange,
  setvehicleTypeDataArray,
  setVehicleTransmissionData,
  vehicleTypeDataArray,
  setVehicleFuelTypeData,
  vehicleTransmissionData,
  setVehicleKmdrivenData,
  vehicleFuelTypedata,
  vehiclekmdrivenData,

  setvehicleDataBrand,
  setvehicleDataBrandPart,
  vehicleDataBrand,
  vehicleDataBrandPart,
  setvehicleDataModel,
  setvehicleDataModelPart,
  vehicleDataModel,
  vehicleDataModelPart,
  allData,
}) => {
  const [isPageLoading, setIspageLoading] = useState(false);

  const [categoryFilter, setCategoryFilter] = useState();
  const [categoryType, setcategoryType] = useState();
  const [categoryPrice, setCategoryPrice] = useState();
  const [categoryfilterVehicle, setCategoryFilterVehicle] = useState();
  const [categoryBrandVehicle, setCategoryBrandVehicle] = useState();
  const [categoryBrandVehiclePart, setCategoryBrandVehiclePart] = useState();
  const [categoryModelVehicle, setCategoryModelVehicle] = useState();
  const [categoryModelVehiclePart, setCategoryModelVehiclePart] = useState();
  const [categoryTypevehicle, setCategoryTypeVehicle] = useState();
  const [categoryTransmissionvehicle, setCategoryTransmissionvehicle] =
    useState();
  const [categoryTypepriceVehicle, setCategoryPriceVehicle] = useState();
  const { state } = useLocation();
  const [allDataShow, setAllDataShow] = useState([]);
  const navigate = useNavigate();
  const [resultsData, setResultsData] = useState();
  useEffect(() => {
    setAllDataShow(allData);
    setResultsData(allData.Results);
  }, [allData]);
  const shippingCharges = allDataShow.shippingCharge;
  const ShippingChargeLocal = localStorage.setItem(
    "DeliveryCharge",
    JSON.stringify(shippingCharges)
  );

  const filterCategory = state?.category;
  const filterSearchText = state?.searchText;

  const [categoryFilterParts, setCategoryFilterParts] = useState();
  const [categoryMakeModelsParts, setCategoryMakeModelsParts] = useState();
  const [categoryTypeParts, setCategoryTypeParts] = useState();
  const [categoryPriceParts, setCategoryPriceParts] = useState();
  const [categoryFuelTypeVehicle, setCategoryFuelTypeVehicle] = useState();
  const [categoryKmdrivenVehicle, setCategoryKmdrivenVehicle] = useState();
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));

  // -------------storing category data for accessory------------//
  useEffect(() => {
    setCategoryFilter(allDataShow.Filter);
    setcategoryType(allDataShow.Filter?.Type);
    setCategoryPrice(allDataShow.Filter?.PriceRange);
  }, [allDataShow]);

  // --------------- storing category data for vehicle-------------//
  useEffect(() => {
    setCategoryFilterVehicle(allDataShow.Filter);
    setCategoryBrandVehicle(allDataShow.Filter?.Brand);
    setCategoryModelVehicle(allDataShow.Filter?.Model);
    setCategoryTypeVehicle(allDataShow.Filter?.Type);
    setCategoryTransmissionvehicle(allDataShow.Filter?.Transmission);
    setCategoryPriceVehicle(allDataShow.Filter?.PriceRange);
    setCategoryFuelTypeVehicle(allDataShow.Filter?.FuelType);
    setCategoryKmdrivenVehicle(allDataShow.Filter?.KmDriven);
  }, [allDataShow]);

  // ----------------- Storing category data for parts-------------//
  useEffect(() => {
    setCategoryFilterParts(allDataShow.Filter);
    setCategoryBrandVehiclePart(allDataShow.Filter?.Brand);
    setCategoryModelVehiclePart(allDataShow.Filter?.Model);
    setCategoryTypeParts(allDataShow.Filter?.Type);
    setCategoryPriceParts(allDataShow.Filter?.PriceRange);
  }, [allDataShow]);

  // -----Accesory custom filtering-----------------
  const applyAccessoryFilter = () => {
    const filteredAccessoryData = resultsData?.filter((item) => {
      return (
        (accessoryTypeDataArray?.length === 0 ||
          accessoryTypeDataArray?.some((obj) => obj?.Id === item?.TypeId)) &&
        (accessoryDataPriceRange?.length === 0 ||
          accessoryDataPriceRange?.some(
            (price) =>
              price?.Maxprice >= item?.Price && price?.MinPrice <= item?.Price
          ))
      );
    });
    setAccessoryFilterData(filteredAccessoryData);
  };

  useEffect(() => {
    applyAccessoryFilter();
  }, [accessoryDataPriceRange, accessoryTypeDataArray]);
  // --------------Accessory type data Function-----------------/
  const GetAccessoryTypeData = (e, typeName) => {
    if (e.target.checked) {
      setAccessoryTypeDataArray([
        ...accessoryTypeDataArray,
        { Id: typeName.Id, Name: typeName.Name, Category: typeName.Category },
      ]);
    } else {
      setAccessoryTypeDataArray(
        accessoryTypeDataArray.filter(
          (unchecked) => unchecked.Id !== typeName.Id
        )
      );
    }
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // ---------------- Accessory Price Range Function------------------//
  const GetAccesoryPriceRange = (e, price) => {
    if (e.target.checked) {
      setAccesoryDataPriceRange([
        ...accessoryDataPriceRange,
        {
          MinPrice: price.MinPrice,
          Maxprice: price.MaxPrice,
          Pricerange: price.PriceRange,
        },
      ]);
    } else {
      setAccesoryDataPriceRange(
        accessoryDataPriceRange.filter(
          (unchecked) => unchecked.MinPrice !== price.MinPrice
        )
      );
    }
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // -------------Vehicle Filter Custom Function---------------//
  const applyFilters = () => {
    const filteredData = resultsData?.filter((item) => {
      const priceItem = item?.Price;
      const milage = item?.Mileage;
      const removecomaMilage = milage?.replace(/,/g, "");
      return (
        (vehicleDataBrand?.length === 0 ||
          vehicleDataBrand.some((brand) => brand.Name === item.Brand)) &&
        (vehicleDataModel?.length === 0 ||
          vehicleDataModel.some((model) => model.Name === item.Model)) &&
        (vehicleTransmissionData?.length === 0 ||
          vehicleTransmissionData.some(
            (trans) => trans.Name === item.Transmission
          )) &&
        (vehicleDataPriceRange?.length === 0 ||
          vehicleDataPriceRange.some(
            (price) =>
              price.Maxprice >= priceItem && price.MinPrice <= priceItem
          )) &&
        (vehicleTypeDataArray?.length === 0 ||
          vehicleTypeDataArray?.some((obj) => obj.Name === item.Type)) &&
        (vehicleFuelTypedata?.length === 0 ||
          vehicleFuelTypedata?.some((obj) => obj.Name === item.FuelType)) &&
        (vehiclekmdrivenData?.length === 0 ||
          vehiclekmdrivenData.some(
            (obj) =>
              obj.MaxMileage >= removecomaMilage &&
              obj.MinMileage <= removecomaMilage
          ))
      );
    });
    setVehicleFilterData(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [
    vehicleDataBrand,
    vehicleDataModel,
    vehicleDataPriceRange,
    vehicleTypeDataArray,
    vehicleTransmissionData,
    vehicleFuelTypedata,
    vehiclekmdrivenData,
  ]);

  const GetVehicleBrandRange = (e, brands) => {
    if (e.target.checked) {
      setvehicleDataBrand([
        ...vehicleDataBrand,
        {
          Name: brands.Name,
          Description: brands.Description,
          Type: brands.Type,
          Id: brands.Id,
        },
      ]);
    } else {
      setvehicleDataBrand(
        vehicleDataBrand.filter((unchecked) => unchecked.Name !== brands.Name)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const GetVehicleModelRange = (e, models) => {
    if (e.target.checked) {
      setvehicleDataModel([
        ...vehicleDataModel,
        {
          Name: models.Name,
          Description: models.Description,
          Type: models.Type,
          Id: models.Id,
        },
      ]);
    } else {
      setvehicleDataModel(
        vehicleDataModel.filter((unchecked) => unchecked.Name !== models.Name)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // ---------------Vehicle Price Range Function---------------//

  const GetVehiclePriceRange = (e, price) => {
    if (e.target.checked) {
      setvehicleDataPriceRange([
        ...vehicleDataPriceRange,
        {
          MinPrice: price.MinPrice,
          Maxprice: price.MaxPrice,
          Pricerange: price.PriceRange,
        },
      ]);
    } else {
      setvehicleDataPriceRange(
        vehicleDataPriceRange.filter(
          (unchecked) => unchecked.MinPrice !== price.MinPrice
        )
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // --------------vehicle transmission data----------
  const GetVehicleTransmissionData = (e, transmission) => {
    if (e.target.checked) {
      setVehicleTransmissionData([
        ...vehicleTransmissionData,
        { Name: transmission },
      ]);
    } else {
      setVehicleTransmissionData(
        vehicleTransmissionData.filter(
          (unchecked) => unchecked?.Name !== transmission
        )
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // ------------------Vehicle type Function-----------------//
  const GetVehicleTypeData = (e, type) => {
    if (e.target.checked) {
      setvehicleTypeDataArray([
        ...vehicleTypeDataArray,
        { Id: type.Id, Name: type.Name, Category: type.Category },
      ]);
    } else {
      setvehicleTypeDataArray(
        vehicleTypeDataArray.filter((unchecked) => unchecked.Id !== type.Id)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // -----------Vehicle fueltype Data------------------//
  const GetFuelTypeData = (e, fuelType, index) => {
    if (e.target.checked) {
      setVehicleFuelTypeData([
        ...vehicleFuelTypedata,
        { Id: index, Name: fuelType },
      ]);
    } else {
      setVehicleFuelTypeData(
        vehicleFuelTypedata.filter((unchecked) => unchecked.Id !== index)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // -------vehicle kmdriven data-----------------
  const GetKmDrivenData = (e, kmdriven, index) => {
    if (e.target.checked) {
      setVehicleKmdrivenData([
        ...vehiclekmdrivenData,
        {
          Id: index,
          MinMileage: kmdriven.MinMileage,
          MaxMileage: kmdriven.MaxMileage,
          Slot: kmdriven.Slot,
        },
      ]);
    } else {
      setVehicleKmdrivenData(
        vehiclekmdrivenData.filter((unchecked) => unchecked.Id !== index)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  //  ----------------------Part Filter custom function-----------------------------
  const applyPartsFilters = () => {
    const filteredPartsData = resultsData?.filter((item) => {
      return (
        (vehicleDataBrandPart?.length === 0 ||
          vehicleDataBrandPart?.some((brand) => brand?.Name === item?.Brand)) &&
        (vehicleDataModelPart?.length === 0 ||
          vehicleDataModelPart?.some((model) => model?.Name === item?.Model)) &&
        (partsDataPriceRange?.length === 0 ||
          partsDataPriceRange?.some(
            (price) =>
              price.Maxprice >= item.Price && price.MinPrice <= item.Price
          )) &&
        (partsTypeDataArray?.length === 0 ||
          partsTypeDataArray?.some((obj) => obj.Id === item.TypeId))
      );
    });
    setPartsFilterData(filteredPartsData);
  };

  useEffect(() => {
    applyPartsFilters();
  }, [
    vehicleDataBrandPart,
    vehicleDataModelPart,
    partsDataPriceRange,
    partsTypeDataArray,
  ]);

  // -------------part Make Models Function---------------//
  const GetVehicleBrandRangeParts = (e, brands) => {
    if (e.target.checked) {
      setvehicleDataBrandPart([
        ...vehicleDataBrandPart,
        {
          Name: brands.Name,
          Description: brands.Description,
          Type: brands.Type,
          Id: brands.Id,
        },
      ]);
    } else {
      setvehicleDataBrandPart(
        vehicleDataBrandPart.filter(
          (unchecked) => unchecked.Name !== brands.Name
        )
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // ----------------------part model function--------------------
  const GetVehicleModelRangeParts = (e, models) => {
    if (e.target.checked) {
      setvehicleDataModelPart([
        ...vehicleDataModelPart,
        {
          Name: models.Name,
          Description: models.Description,
          Type: models.Type,
          Id: models.Id,
        },
      ]);
    } else {
      setvehicleDataModelPart(
        vehicleDataModelPart.filter(
          (unchecked) => unchecked.Name !== models.Name
        )
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // ---------------------parts price range function-------------//
  const GetPartPriceRange = (e, price) => {
    if (e.target.checked) {
      setpartsDataPriceRange([
        ...partsDataPriceRange,
        {
          MinPrice: price.MinPrice,
          Maxprice: price.MaxPrice,
          Pricerange: price.PriceRange,
        },
      ]);
    } else {
      setpartsDataPriceRange(
        partsDataPriceRange.filter(
          (unchecked) => unchecked.MinPrice !== price.MinPrice
        )
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // ----------------- Part Type Function----------------------//

  const GetPartTypeData = (e, typeName) => {
    if (e.target.checked) {
      setpartsTypeDataArray([
        ...partsTypeDataArray,
        { Id: typeName.Id, Name: typeName.Name, Category: typeName.Category },
      ]);
    } else {
      setpartsTypeDataArray(
        partsTypeDataArray.filter((unchecked) => unchecked.Id !== typeName.Id)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/*--------------------------Accessories filters list-----------------------*/}

      {state?.category === "2" ? (
        <div className="accordion-main-head">
          <>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Budget{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryPrice?.map((price, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetAccesoryPriceRange(e, price)
                                  }
                                />
                                {price.PriceRange}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Type{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryType?.map((typeName, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetAccessoryTypeData(e, typeName)
                                  }
                                />
                                {typeName.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </div>
      ) : null}

      {/* ---------------------------------Vehicles filter list---------------- */}

      {state?.category === "1" ? (
        <div className="accordion-main-head">
          <>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Brand
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryBrandVehicle?.map((brands, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetVehicleBrandRange(e, brands)
                                  }
                                />
                                {brands.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Model
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryModelVehicle?.map((models, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetVehicleModelRange(e, models)
                                  }
                                />
                                {models.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Budget{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryTypepriceVehicle?.map((price, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetVehiclePriceRange(e, price)
                                  }
                                />
                                {price.PriceRange}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Type{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryTypevehicle?.map((typeName, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetVehicleTypeData(e, typeName)
                                  }
                                />
                                {typeName.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Fuel Type{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryFuelTypeVehicle?.map((fuelType, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetFuelTypeData(e, fuelType, index)
                                  }
                                />
                                {fuelType}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  KM Driven{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryKmdrivenVehicle?.map((kmdriven, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetKmDrivenData(e, kmdriven, index)
                                  }
                                />
                                {kmdriven.Slot}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Transmission{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryTransmissionvehicle?.map(
                          (transmission, index) => {
                            return (
                              <div key={index}>
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="emailFeild"
                                    id="74"
                                    value="novobrown@brown.com/74"
                                    onChange={(e) =>
                                      GetVehicleTransmissionData(
                                        e,
                                        transmission
                                      )
                                    }
                                  />
                                  {transmission}
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </div>
      ) : null}

      {/*------------------------------------------Parts filter list---------------- */}

      {state?.category === "0" ? (
        <div className="accordion-main-head">
          <>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Brand
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryBrandVehiclePart?.map((brands, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetVehicleBrandRangeParts(e, brands)
                                  }
                                />
                                {brands.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Model
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryModelVehiclePart?.map((models, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) =>
                                    GetVehicleModelRangeParts(e, models)
                                  }
                                />
                                {models.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Budget{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryPriceParts?.map((price, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) => GetPartPriceRange(e, price)}
                                />
                                {price.PriceRange}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion
              className="accordion custom_accordian"
              id="accordionPanelsStayOpenExample"
              alwaysOpen
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accordion-item">
                <Accordion.Header className="accordion-header">
                  Type{" "}
                </Accordion.Header>
                <Accordion.Body className="accodion-custom-height">
                  <div className="scroll-hello">
                    <div className="form-check" id="userDiv">
                      <div id="cont-div">
                        {categoryTypeParts?.map((typeName, index) => {
                          return (
                            <div key={index}>
                              <label className="form-check-label">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="emailFeild"
                                  id="74"
                                  value="novobrown@brown.com/74"
                                  onChange={(e) => GetPartTypeData(e, typeName)}
                                />
                                {typeName.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </div>
      ) : null}
    </div>
  );
};
export default Category;

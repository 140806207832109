import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Assured from "./Overview/Assured";
import Overview from "./Overview/Overview";
import "../../../src/App.css";

const Description = ({ singleAdData }) => {
  const googleLoginData = JSON.parse(localStorage.getItem("LoginData"));

  return (
    <div className="overview-tab">
      <div className="tab-content mt-3" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex="0"
        >
          <Tabs
            defaultActiveKey="Overview"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Overview" title="Overview" className="postAdd_btn_1">
              <Overview singleAdData={singleAdData} />
            </Tab>
            {/* <Tab eventKey="Assured" title="Assured" className="postAdd_btn_2">
                <Assured />
              </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Description;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../src/App.css";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import { toast } from "react-toastify";
import Loader from "../../Components/loader/loader";
import axios from "axios";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
const Home = () => {
  const navigate = useNavigate();

  const [isPageLoading, setIspageLoading] = useState(false);
  const [SearchText, SetSearchText] = useState("");
  const [Category, SetCategory] = useState("");
  const [allData, setAllData] = useState([]);
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const validate = () => {
   
    if (!Category) {
      toast.error("Please select category", {
        theme: "colored",
   
      });
      return false;
    }
    if (Category === "Category") {
      toast.error("Please select category", {
        theme: "colored",
   
      });
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    // try {
    //   setIspageLoading(true);
    //   const data = {};
    //   data.SearchText = SearchText;
    //   data.PageSize=15;
    //   data.PageNo=1;
    //   data.Category = Category;
    //   const option = {
    //     method: "POST",
    //     headers: {
    //       "access-control-allow-origin": "*",
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${tokenid}`,
    //     },
    //     data: { data: JSON.stringify(data) },
    //     url: `${Apiurl()}/Search/Search`,
    //   };
    //   const response = await axios(option);
    //   setIspageLoading(false);
    //   setAllData(JSON.parse(response.data.data));
    //   const checkData= JSON.parse(response.data.data)
    //   if (!checkData.Results?.length) {
    //     toast.error(response.data.message, {
    //       theme: "colored",
     
    //     });
    //   } else {
       
        navigate("/filter", {
          state: {
            category: Category,
            searchText: SearchText,}})
    //       },
    //     });
    //   }
    // } catch (error) {

    //   setIspageLoading(false);
    //   if(error?.code==="ERR_NETWORK"){
    //     RemoveSession();
    //     navigate("/");
    //     toast.error("Session Expired, Please login again", {
    //       theme: "colored",
     
    //     })
    //   }
    //   toast.error(error.response.data.message,{
    //     theme: "colored",
       
    //   })
    // }
  };
  return (
    <div className="home-main-div">
      {isPageLoading ? <Loader /> : null}
      <main className="main-section-homePage">
        <Header />

        <section className="search_page text-center">
          <div className="main-form-home">
            <h1>Search Cars</h1>
            <form className="form-input">
              <input
                type="text"
                className="search-input"
                placeholder="Type Car Name, Model & Etc.."
                onChange={(e) => SetSearchText(e.target.value)}
              />
              <div className="home-butt-div">
                <select
                  onChange={(e) => SetCategory(e.target.value)}
                  className="input"
                >
                  <option   defaultValue="Category">
                    Category
                  </option>
                  <option value="1">Vehicles</option>
                  <option value="0">Parts</option>
                  <option value="2">Accessories</option>
                </select>
                <button
                  className="search-input-butt"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <i className="fa fa-search"></i>Search
                </button>
              </div>
            </form>
          </div>
        </section>

        <Footer />
      </main>
    </div>
  );
};
export default Home;

import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import Loader from "../../Components/loader/loader";
import Footer from "../../Components/Common/Footer";
import {
  Apiurl,
  RemoveSession,
  getSellerId,
  getSellerStoreId,
  storeSuccessAdded,
} from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Resizer from "react-image-file-resizer";
import { ImCross } from "react-icons/im";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const StoreDetail = () => {
  const getSellerStore = getSellerId();
  const [isPageLoading, setIspageLoading] = useState(false);
  const navigate = useNavigate();
  const [storeName, setStoreName] = useState();
  const [contactPersonName, setContactPersonName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [countryId, setCountryId] = useState();
  const [logoImage, setLogoImage] = useState();
  const [logoImagePreview, setLogoImagePreview] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [bannerImagePreview, setBannerImagePreview] = useState();
  const [sellerStoreData, setSellerStoreData] = useState([]);
  const [stateId, setStateId] = useState();

  useEffect(() => {
    if (sellerStoreData?.Address) {
      setStoreName(sellerStoreData?.StoreName);
      setContactPersonName(sellerStoreData?.ContactPerson);
      setPhoneNumber(sellerStoreData?.ContactNo);
      setEmail(sellerStoreData?.Email);
      setAddress(sellerStoreData?.Address);
      setCity(sellerStoreData?.City);
      setCountryId(sellerStoreData?.Country?.Id);
      setState(sellerStoreData?.State);
      setStateId(sellerStoreData?.StateId);
      setBannerImagePreview([sellerStoreData?.BannerImageUrl]);
      setLogoImagePreview([sellerStoreData?.LogoUrl]);
    }
  }, [sellerStoreData]);

  const [oldStateid, setOldStateId] = useState();
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();

      return;
    }
  };

  // --------------Get Store Data-------------------//
  const GetStoreData = (e) => {
    const data = {};

    setIspageLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Seller/GetSellerStore`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        setSellerStoreData(JSON.parse(e.data.data));
      })
      .catch((error) => {
        setIspageLoading(false);

        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetStoreData();
  }, []);
  // ------------validation--------------
  const ValidateFields = () => {
    if (!storeName) {
      toast.error("Please enter store name", {
        theme: "colored",
      });
      return false;
    }

    if (!contactPersonName) {
      toast.error("Please enter contact person name", {
        theme: "colored",
      });
      return false;
    }

    if (!phoneNumber) {
      toast.error("Please enter your mobile number", {
        theme: "colored",
      });
      return false;
    }
    if (phoneNumber.length < 10) {
      toast.error("Please enter your correct mobile number", {
        theme: "colored",
      });
      return false;
    }

    if (!email) {
      toast.error("Please enter your email", {
        theme: "colored",
      });
      return false;
    }
    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      toast.error("email invalid", {
        theme: "colored",
      });
      return false;
    }
    if (!address) {
      toast.error("Please enter your adress", {
        theme: "colored",
      });
      return false;
    }

    if (!city) {
      toast.error("Please Fill city", {
        theme: "colored",
      });
      return false;
    }

    if (!countryId) {
      toast.error("Please select country ", {
        theme: "colored",
      });
      return false;
    }
    if (countryId === "Select") {
      toast.error("Please select country", {
        theme: "colored",
      });
      return false;
    }
    if (!stateId) {
      toast.error("Please select state ", {
        theme: "colored",
      });
      return false;
    }
    if (!logoImagePreview?.length) {
      toast.error("Please upload logo image", {
        theme: "colored",
      });
      return false;
    }

    if (!bannerImagePreview?.length) {
      toast.error("Please upload banner image", {
        theme: "colored",
      });
      return false;
    }

    return true;
  };
  // ----------Image upload------------
  const handleLogoImageUpload = (event) => {
    const partsFiles = event.target.files;

    if (partsFiles.length === 0) {
      setIspageLoading(false);
      return;
    }

    const newImages = [];
    for (let i = 0; i < partsFiles.length; i++) {
      const file = partsFiles[i];

      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        100,
        0,
        (uri) => {
          newImages.push(uri);
          if (newImages.length === partsFiles.length) {
            setLogoImage(newImages);
            setLogoImagePreview(newImages);
          }
        },
        "base64",
        0.1 // Set the maximum size in megabytes to 0.1
      );
    }
  };
  // ----------Image upload------------
  const handlebannerImage = (event) => {
    const bannerFiles = event.target.files;

    if (bannerFiles.length === 0) {
      setIspageLoading(false);
      return;
    }

    const bannerImage = [];
    for (let i = 0; i < bannerFiles.length; i++) {
      const file = bannerFiles[i];

      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        100,
        0,
        (uri) => {
          bannerImage.push(uri);
          if (bannerImage.length === bannerFiles.length) {
            setBannerImagePreview(bannerImage);
            setBannerImage(bannerImage);
          }
        },
        "base64",
        0.1 // Set the maximum size in megabytes to 0.1
      );
    }
  };

  // --------------Get Country & State-------------------//
  const GetCountryStateData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetCountries`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const CountryData = JSON.parse(e.data.data);
        setCountry(CountryData);
      })
      .catch((error) => {
        setIspageLoading(false);
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetCountryStateData();
  }, []);
  // ---------Getting States Data-------------------//

  const [statesData, setStatesData] = useState([]);
  useEffect(() => {
    const filterStates = country?.filter(
      (item) => item?.Id === Number(countryId)
    );
    setStatesData(filterStates?.[0]?.States);
  }, [country, countryId]);

  // ----------add store api------------
  const Navto = () => {
    const StoreName = storeName?.toLowerCase().replace(/\s/g, "-");

    navigate(`/store/${StoreName}`, {
      state: {
        storeName: StoreName,
      },
    });
  };
  const handleAddStore = () => {
    if (!ValidateFields()) return;

    const data = {};
    data.StoreName = storeName.replace(/ /g, "-");
    data.Address = address;
    data.ContactPerson = contactPersonName;
    data.ContactNo = phoneNumber;
    data.Email = email;
    data.City = city;
    data.StateId = stateId;
    data.CountryId = countryId;
    data.images = [
      { FileName: "Logo", Base64Content: logoImage?.[0], Extenssion: "jpeg" },
      {
        FileName: "Banner",
        Base64Content: bannerImage?.[0],
        Extenssion: "jpeg",
      },
    ];

    setIspageLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Seller/PostSellerStore`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
        storeSuccessAdded(true);
        Navto();

        setStoreName("");
        setContactPersonName("");
        setPhoneNumber("");
        setEmail("");
        setAddress("");
        setCity("");
        setCountryId("");

        setLogoImage("");
        setBannerImage("");
      })
      .catch((error) => {
        setIspageLoading(false);

        toast.error(error.response?.data?.message, {
          theme: "colored",
        });
      });
  };

  const deleteLogoImage = (e) => {
    const deletedItem = logoImagePreview.filter((item, i) => i === e);
    setLogoImagePreview(deletedItem);
  };

  const deleteBannerImage = (e) => {
    const deletedItem = bannerImagePreview.filter((item, i) => i === e);
    setBannerImagePreview(deletedItem);
  };

  const handleMyStore = () => {
    const Mystore = sellerStoreData?.StoreName;

    navigate(`/store/${Mystore?.replace(/ /g, "-")}`, {
      state: {
        storeName: sellerStoreData?.StoreName,
      },
    });
  };

  return (
    <div className="profile-head">
      {isPageLoading ? <Loader /> : null}
      <main className="main_wrapper">
        <Header />
        <section className="profile_page">
          <div className="container store-container">
            <div className="profile_wraper">
              <div className="row">
                <div className="mb-4 store-heading">
                  <h2>Store Details</h2>
                </div>
                <div className="store-div-parts">
                  <div className="mb-4 store-div-sec ">
                    <label className="brnd_label mb-3 store-country">
                      Store Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control store-input-field type_input"
                      required
                      value={storeName}
                      onChange={(e) => setStoreName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 store-div-sec">
                    <label className="brnd_label mb-3 store-country">
                      Contact Person<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control store-input-field type_input"
                      required
                      value={contactPersonName}
                      onChange={(e) => setContactPersonName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="store-div-parts">
                  <div className="mb-4 store-div-sec">
                    <label className="brnd_label mb-3 store-country">
                      Phone Number<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      min="0"
                      onKeyPress={inputHandler}
                      className="form-control store-input-field type_input"
                      required
                      maxLength="10"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 store-div-sec">
                    <label className="brnd_label mb-3 store-country">
                      Email<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control store-input-field type_input"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="store-div-parts">
                  <div className="mb-4 store-div-sec">
                    <label className="brnd_label mb-3 store-country">
                      Address<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control store-input-field type_input"
                      required
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="mb-4 store-div-sec">
                    <label className="brnd_label mb-3 store-country">
                      City<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control store-input-field type_input"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="store-div-parts">
                  <div className="mb-4 store-div-sec list-location ">
                    <label className="brnd_label mb-3 store-country">
                      Country<span className="required">*</span>
                    </label>
                    <select
                      name="cars"
                      className="type_input store-country-select"
                      value={countryId}
                      onChange={(e) => {
                        setCountryId(e.target.value);
                      }}
                    >
                      <option value="0">Select a Country</option>
                      {country?.map((country) => (
                        <option key={country.Id} value={country.Id}>
                          {country.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4 store-div-sec">
                    <label className="brnd_label mb-3 store-country">
                      State<span className="required">*</span>
                    </label>
                    <select
                      name="cars"
                      className="type_input store-country-select"
                      onChange={(e) => setStateId(e.target.value)}
                      value={stateId || state}
                    >
                      <option>Select a State</option>
                      {statesData?.map((state) => (
                        <option key={state.Id} value={state.Id}>
                          {state.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="store-div-parts">
                  <div className="data_Category mb-4 store-div-sec">
                    <h2 className="selct_hding store-country">
                      Upload Logo Image<span className="required">*</span>
                    </h2>
                    <div className="upload-store-image-grid">
                      {logoImagePreview?.length > 0
                        ? logoImagePreview?.map((logoImage, i) => {
                            return (
                              <div className="outer-div-vehicle" key={i}>
                                <div className="image-parts-radio-buttons">
                                  <img
                                    src={logoImage?.imageUrl || logoImage}
                                    alt={logoImage}
                                    className="upload-store-images"
                                  />
                                </div>
                                <ImCross
                                  onClick={() => deleteLogoImage(logoImage, i)}
                                  className="delete-part-image-icon delete-image-icon-delete"
                                />
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <input
                      type="file"
                      multiple
                      name="partsuploadfile"
                      id="img"
                      className="input-parts-field-image"
                      onChange={handleLogoImageUpload}
                    />

                    <label htmlFor="img" className="label-store-upload-image">
                      <AiOutlineCloudUpload className="upload-icon-image" />
                      <h5 className="para-parts-upload-image">
                        browse to choose files
                      </h5>
                    </label>
                  </div>
                  {/* Banner Image */}
                  <div className="data_Category mb-4 store-div-sec">
                    <h2 className="selct_hding store-country">
                      Upload Banner Image<span className="required">*</span>
                    </h2>
                    <div className="upload-store-image-grid">
                      {bannerImagePreview?.length > 0
                        ? bannerImagePreview?.map((bannerImg, i) => {
                            return (
                              <div className="outer-div-vehicle " key={i}>
                                <div className="image-parts-radio-buttons">
                                  <img
                                    src={bannerImg?.imageUrl || bannerImg}
                                    alt={bannerImg}
                                    className="upload-store-images"
                                  />
                                </div>
                                <ImCross
                                  onClick={() =>
                                    deleteBannerImage(bannerImg, i)
                                  }
                                  className="delete-part-image-icon delete-image-icon-delete"
                                />
                              </div>
                            );
                          })
                        : ""}
                    </div>

                    <input
                      type="file"
                      name="partsbannerfile"
                      id="image1"
                      className="input-parts-field-image"
                      onChange={(e) => handlebannerImage(e)}
                    />

                    <label
                      htmlFor="image1"
                      className="label-store-upload-image"
                    >
                      <AiOutlineCloudUpload className="upload-icon-image" />
                      <h5 className="para-parts-upload-image">
                        browse to choose files
                      </h5>
                    </label>
                  </div>
                </div>
                <div className="div-store-parts">
                  <div className="store-div-button">
                    {sellerStoreData?.Address ? (
                      <>
                        {" "}
                        <button
                          onClick={handleAddStore}
                          className="store-submit"
                        >
                          Update
                        </button>
                      </>
                    ) : (
                      <button onClick={handleAddStore} className="store-submit">
                        Submit
                      </button>
                    )}
                    {sellerStoreData?.StoreName ? (
                      <>
                        <button
                          className="store-submit-myStore"
                          onClick={handleMyStore}
                        >
                          My Store
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
};

export default StoreDetail;

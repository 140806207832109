import React from "react";
import "../src/App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MainDetail from "./Pages/Details/MainDetail";
import MainFilter from "./Pages/Filter/MainFilter";
import Home from "./Pages/Home/Home";
import MainPost from "./Pages/PostAd/MainPost";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import Favourite from "./Pages/Favourite/Favourite";
import ChatWithSeller from "./Pages/Chat/ChatWithSeller";
import { Slide } from 'react-toastify';    
import StoreDetail from "./Pages/StoreDetail/StoreDetail";
import Store from "./Pages/Store/Store";

const App = () => {
  return (
    <div>
      <ToastContainer 
      position="top-right"
      transition = {Slide}
      autoClose= "5000"
      
      />
      <BrowserRouter>
        <Routes>
          <Route />
          <Route path="/" element={<Home />} />
          <Route path="/filter" element={<MainFilter />} />
          <Route path="/detail/:adsCategoryName/:GetVehicleId/:productName" element={<MainDetail />} />
          <Route path="/postaid" element={<MainPost />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/wishlist" element={<Favourite />} />
          <Route path="/storedetail" element={<StoreDetail />} />
          <Route path="/ChatWithSeller" element={<ChatWithSeller/>} />
          <Route path="/store/:Mystore" element={<Store/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

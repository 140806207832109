import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

const MainStoreCategory = ({
  brand,
  model,
  category,
  fuelType,
  kmDriven,
  priceRange,
  type,
  brandData,
  setBrandData,
  setModelData,
  modelData,
  priceRangeData,
  setPriceRangeData,
  fuelTypedata,
  setFuelTypeData,
  transmission,
  
  kmdrivenData,
  setKmdrivenData,
  typeData,
  setTypeData,
  categoryData,
  setCategoryData,
  setTransmissionData,
  transmissionData,
  MyAds,
  setMyAds,
  setFilterData,
  filterData,
  sellerStoreData,
}) => {
  const [resultData, setResultData] = useState();
  useEffect(() => {
    if (sellerStoreData) {
      setResultData(JSON.parse(sellerStoreData?.Ads));
    }
  }, [sellerStoreData]);
  // --------- Category filter--------
  const GetCategoryData = (e, typeName) => {
    if (e.target.checked) {
      setCategoryData([
        ...categoryData,
        {
          Id: typeName.Id,
          Name: typeName.Name,
        },
      ]);
    } else {
      setCategoryData(
        categoryData?.filter((unchecked) => unchecked.Name !== typeName.Name)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // -------Brand filter----------------------
  const GetBrandData = (e, brands) => {
    if (e.target.checked) {
      setBrandData([
        ...brandData,
        {
          Name: brands.Name,
          Description: brands.Description,
          Type: brands.Type,
          Id: brands.Id,
        },
      ]);
    } else {
      setBrandData(
        brandData?.filter((unchecked) => unchecked.Name !== brands.Name)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  //   ------Model Filter--------
  const GetModelData = (e, models) => {
    if (e.target.checked) {
      setModelData([
        ...modelData,
        {
          Name: models.Name,
          Description: models.Description,
          Type: models.Type,
          Id: models.Id,
        },
      ]);
    } else {
      setModelData(
        modelData.filter((unchecked) => unchecked.Name !== models.Name)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // ---------------Price Range Filter---------------//

  const GetPriceRangeData = (e, price) => {
    if (e.target.checked) {
      setPriceRangeData([
        ...priceRangeData,
        {
          MinPrice: price.MinPrice,
          Maxprice: price.MaxPrice,
          Pricerange: price.PriceRange,
        },
      ]);
    } else {
      setPriceRangeData(
        priceRangeData.filter(
          (unchecked) => unchecked.MinPrice !== price.MinPrice
        )
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // -----------Vehicle fueltype Data------------------//
  const GetFuelTypeData = (e, fuelType, index) => {
    if (e.target.checked) {
      setFuelTypeData([...fuelTypedata, { Id: index, Name: fuelType }]);
    } else {
      setFuelTypeData(
        fuelTypedata.filter((unchecked) => unchecked.Id !== index)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // -------vehicle kmdriven data-----------------
  const GetKmDrivenData = (e, kmdriven, index) => {
    if (e.target.checked) {
      setKmdrivenData([
        ...kmdrivenData,
        {
          Id: index,
          MinMileage: kmdriven.MinMileage,
          MaxMileage: kmdriven.MaxMileage,
          Slot: kmdriven.Slot,
        },
      ]);
    } else {
      setKmdrivenData(
        kmdrivenData.filter((unchecked) => unchecked.Id !== index)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // -------------Transmission type data
  const GetTransmissionData=(e, trans)=>{
    if (e.target.checked) {
      setTransmissionData([
        ...transmissionData,
        { Name: trans },
      ]);
    } else {
      setTransmissionData(transmissionData.filter((unchecked) => unchecked.Name !== trans));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  
  // ------------------Vehicle type Function-----------------//
  const GetTypeData = (e, type) => {
    if (e.target.checked) {
      setTypeData([
        ...typeData,
        { Id: type.Id, Name: type.Name, Category: type.Category },
      ]);
    } else {
      setTypeData(typeData.filter((unchecked) => unchecked.Id !== type.Id));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //     apply filters-------
  const applyFilters = () => {
    const filteredData = resultData?.filter((item) => {
      const priceItem = item?.Price;
      const milage = item?.Mileage;
      const removecomaMilage = milage?.replace(/,/g, "");
      return (
        (categoryData?.length === 0 ||
          categoryData.some((category) => category.Id === item.AdsCategory)) &&
        (brandData?.length === 0 ||
          brandData.some((brand) => brand.Name === item.Brand)) &&
        (modelData?.length === 0 ||
          modelData.some((model) => model.Name === item.Model)) &&
          (transmissionData?.length===0 || 
            transmissionData?.some((trans)=>trans.Name===item.Transmission)
            ) &&
        (priceRangeData?.length === 0 ||
          priceRangeData.some(
            (price) =>
              price.Maxprice >= priceItem && price.MinPrice <= priceItem
          )) &&
        (typeData?.length === 0 ||
          typeData?.some((obj) => obj.Name === item.Type)) &&
        (fuelTypedata?.length === 0 ||
          fuelTypedata?.some((obj) => obj.Name === item.FuelType)) &&
        (kmdrivenData?.length === 0 ||
          kmdrivenData.some(
            (obj) =>
              obj.MaxMileage >= removecomaMilage &&
              obj.MinMileage <= removecomaMilage
          ))
      );
    });
    setFilterData(filteredData);
  };
  useEffect(() => {
    applyFilters();
  }, [
    brandData,
    modelData,
    priceRangeData,
    typeData,
    fuelTypedata,
    kmdrivenData,
    categoryData,
    transmissionData
    
  ]);
  return (
    <div className="accordion-main-head">
      <>
      {category?.length?<>
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Category{" "}
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {category?.map((typeName, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                          {typeName?<>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) => GetCategoryData(e, typeName)}
                            />
                          </>:null}
                            {typeName.Name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      
      </>:null}

      {brand?.length?<>
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Brand
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {brand?.map((brands, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {brands?<>  <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) => GetBrandData(e, brands)}
                            /></>:null}
                          
                            {brands.Name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>:null}
       
       {model?.length?<>
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Model
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {model?.map((models, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {models?<> <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) => GetModelData(e, models)}
                            /></>:null}
                           
                            {models.Name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
       </>:null}
      
       
       {priceRange?.length?<>
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Budget{" "}
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {priceRange?.map((price, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {price?<> <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) => GetPriceRangeData(e, price)}
                            /></>:null}
                           
                            {price.PriceRange}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
       </>:null}
       
       {fuelType?.length?<>
       
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Fuel Type{" "}
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {fuelType?.map((fuelType, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {fuelType?<>
                              <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) =>
                                GetFuelTypeData(e, fuelType, index)
                              }
                            />
                            </>: null}
                           
                            {fuelType}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
       </>:null}
      
      {kmDriven?.length?<>
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              KM Driven{" "}
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello" >
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {kmDriven?.map((kmdriven, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {kmDriven?<><input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) =>
                                GetKmDrivenData(e, kmdriven, index)
                              }
                            /></>:null}
                            
                            {kmdriven.Slot}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>:null}
     {type?.length?<>
      <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Type{" "}
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {type?.map((typeName, index) => {
                   
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {typeName?<>  <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) => GetTypeData(e, typeName)}
                            /></>:null}
                          
                            {typeName.Name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
     </>:null}
     {transmission?.length?<>
        <Accordion
          className="accordion custom_accordian"
          id="accordionPanelsStayOpenExample"
          alwaysOpen
          defaultActiveKey={["0"]}
        >
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Header className="accordion-header">
              Transmission
            </Accordion.Header>
            <Accordion.Body className="accodion-custom-height">
              <div className="scroll-hello">
                <div className="form-check" id="userDiv">
                  <div id="cont-div">
                    {transmission?.map((trans, index) => {
                      return (
                        <div key={index}>
                          <label className="form-check-label">
                            {trans?<> <input
                              className="form-check-input"
                              type="checkbox"
                              name="emailFeild"
                              id="74"
                              value="novobrown@brown.com/74"
                              onChange={(e) => GetTransmissionData(e, trans)}
                            /></>:null}
                           
                            {trans}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
       </>:null}
      
      </>
    </div>
  );
};

export default MainStoreCategory;

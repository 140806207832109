import React, { useEffect, useState } from "react";
import "../../../src/App.css";
import axios from "axios";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
import { Tab, Tabs } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import Resizer from "react-image-file-resizer";
import { Cloudinary, Transformation } from "@cloudinary/url-gen";
import { opacity } from "@cloudinary/url-gen/actions/adjust";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { Position } from "@cloudinary/url-gen/qualifiers";
import { compass } from "@cloudinary/url-gen/qualifiers/gravity";
import { text } from "@cloudinary/url-gen/qualifiers/source";
import { TextStyle } from "@cloudinary/url-gen/qualifiers/textStyle";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import Location from "./List/Location";
import List from "./List/List";
import { useNavigate } from "react-router-dom";
import PartsList from "./List/PartsList";

const Parts = ({ PartseditLocation }) => {
  const [showImage, setShowImage] = useState([]);
  const [PartsUserId, setPartsUserId] = useState();
  const [partsImageSrc, setPartsImagesSrc] = useState([]);
  const [partsSelectedFile, setPartsSelectedFile] = useState([]);
  const [partsImageName, setPartsImageName] = useState(""); //---
  const [partsBase64id, setPartsBase64id] = useState(""); //---
  const [partsImageFormat, setPartsImageFormat] = useState("");
  const [isPageLoading, setIspageLoading] = useState(false);
  const [partsBrands, setPartsBrands] = useState([]);
  const [partsBrandId, setPartsBrandId] = useState();
  const [partsModals, setPartsModal] = useState([]);
  const [partsImages, setPartsImages] = useState([]);
  const [partsModalId, setPartsModalId] = useState();
  const [partName, setPartName] = useState("");
  const [partType, setPartType] = useState("");
  const [partTitle, setPartTitle] = useState("");
  const [partsDescription, setPartsDescription] = useState("");
  const [partsPrice, setPartsPrice] = useState("");
  const [partsSelectedImage, setPartsSelectedImage] = useState("");
  const [partPriceState, setPartPriceState] = useState("");
  const [city, setCity] = useState();
  const [neighbourhood, setNeighbourhood] = useState("");
  const [countryId, setCountryId] = useState();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState();
  const [oldStateid, setOldStateId] = useState();
  const [userSelectedDefaultImagePart, setUserSelectedDefaultImagePart] =
    useState(false);
  const [trueImageDataPart, setTrueImageDataPart] = useState();
  const [oneImagTruePart, setOneImageTruePart] = useState();

  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const navigate = useNavigate();
  const [dummyDataImage, setDummyDataImg] = useState();
  const [dummyImageValue, setDummyImageValue] = useState();

  // -------------------------------------------

  const partsbrandsid = partsBrands?.find(
    (item) => item.Name === PartseditLocation?.Brand
  );
  const partsModelsid = partsModals?.find(
    (item) => item.Name === PartseditLocation?.Model
  );
  const [dummyTest, setDummyTest] = useState();

  useEffect(() => {
    if (PartseditLocation) {
      if (!dummyDataImage && showImage?.length > 1) {
        setUserSelectedDefaultImagePart(true);
      }
    }
  }, [dummyDataImage, showImage]);

  useEffect(() => {
    if (dummyImageValue === true) {
      setUserSelectedDefaultImagePart(true);
    }
  }, [dummyImageValue]);

  useEffect(() => {
    if (dummyTest?.length === 1) {
      setUserSelectedDefaultImagePart(false);
    }
  }, [dummyTest]);

  useEffect(() => {
    setPartsBrandId(partsbrandsid?.Id);
    if (partsBrandId) {
      GetModalsData();
    }
  }, [partsbrandsid]);
  useEffect(() => {
    setPartsModalId(partsModelsid?.Id);
  }, [partsModelsid]);

  const Editimage = PartseditLocation?.Images;

  useEffect(() => {
    if (Editimage) {
      const gettrueData = Editimage?.find(
        (item) => item?.DefaultImage === true
      );
      setTrueImageDataPart(gettrueData?.UrlLink);
      setDummyDataImg(gettrueData);

      setUserSelectedDefaultImagePart(false);

      const partsImageSrc = Editimage?.map((img) => ({
        imageId: img.Id,
        imageName: img.FileName || "",
        imageUrl: img.UrlLink,
        imageFormat: img.ImageExtenssion,
        base64id: "",
        MarkDelete: false,
      }));
      setPartsImagesSrc(partsImageSrc);
      setShowImage(partsImageSrc);
    }
  }, [PartseditLocation, Editimage]);

  useEffect(() => {
    setPartName(PartseditLocation?.Name);

    setPartType(PartseditLocation?.Type);
    setPartTitle(PartseditLocation?.AdvertisementTitle);
    setPartsDescription(PartseditLocation?.Description);
    setPartsPrice(PartseditLocation?.Price);
    setPartsUserId(PartseditLocation?.Id);
    setCity(PartseditLocation?.City);

    setState(PartseditLocation?.State?.Id);
    setNeighbourhood(PartseditLocation?.Neighbourhood);
    setPartPriceState(PartseditLocation?.PriceType);
    const typeExternalData = partType;
    if (typeExternalData === "External") {
      setPartType(0);
    } else {
      setPartType(1);
    }
  }, [PartseditLocation]);
  useEffect(() => {
    if (country) {
      const selectedCountry = country?.find(
        (c) => c.Name === PartseditLocation?.Country?.Name
      );
      const oldstate = setOldStateId(PartseditLocation?.State?.Id);
      if (selectedCountry) {
        setCountryId(selectedCountry.Id);
      }
    }
  }, [country, PartseditLocation?.Country]);
  // -----------------getting user location---------------/

  const handlePartsImageUpload = (event) => {
    setTrueImageDataPart("");
    setDummyDataImg("");
    const partsFiles = event.target.files;
    if (showImage?.length === 1 && event.target.files?.length === 1) {
      setDummyImageValue(true);
    } else if (event.target.files.length > 1) {
      setDummyImageValue(false);
    }
    if (event.target.files.length > 1) {
      setUserSelectedDefaultImagePart(true);
    }

    if (partsFiles.length === 0) {
      setIspageLoading(false);
      return;
    }
    const newFiles = [...partsSelectedFile, ...partsFiles];
    setIspageLoading(true);

    if (newFiles.length > 20) {
      toast.error("You can select up to 20  only.", {
        theme: "colored",
      });
      setIspageLoading(false);
      return;
    }
    if (partsSelectedFile === partsFiles) {
  
      return;
    }

    setPartsSelectedFile(newFiles);
    const newImages = [];
    for (let i = 0; i < partsFiles.length; i++) {
      const file = partsFiles[i];
      setPartsImageName(file.name);

      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        100,
        0,
        (uri) => {
          newImages.push(uri);
          if (newImages.length === partsFiles.length) {
            setPartsImages(newImages);
            partsUploadImages(newImages);
          }
        },
        "base64",
        0.1 // Set the maximum size in megabytes to 0.1
      );
    }
  };
  const handleImageSectionPart = (imageUrl) => {
    setTrueImageDataPart("");
    setPartsSelectedImage(imageUrl);
    setUserSelectedDefaultImagePart(false);
  };

  // -----------Upload to Cloudinary and then fetch it--------//
  const partsUploadImages = async (images) => {
    let imgArray = [...partsImageSrc];
    let multiplePartImage = [...showImage];
    for (const image of images) {
      const body = new FormData();
      body.append("upload_preset", "z5yxtsvv");
      body.append("file", image);
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dmrw6iq4q/image/upload",
        {
          method: "POST",
          body,
        }
      ).then((r) => r.json());
      const myImage = cld.image(response.public_id);
      myImage
        .overlay(
          source(
            text("JTC Marketplace", new TextStyle("arial", 17))
              .textColor("#ffffff")
              .transformation(
                new Transformation()
                  .adjust(opacity(100))
                  .backgroundColor("#08080898")
                  .adjust(opacity(30))
                  .roundCorners(byRadius(10))
              )
          ).position(new Position().gravity(compass("center")).offsetY(5))
        )
        .format("png");
      const myUrl = myImage.toURL();
      imgArray.push(myUrl);
      multiplePartImage.push(myUrl);
      // --------------converting image into base 64--------------------------//
      const imageUrl = myUrl;
      // Get the image data using fetch
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Convert the image data to a base64 string
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            const base64String = reader.result;

            const modifiedBase64Content = base64String.replace(
              /^data:image\/png;base64,/,
              ""
            );

            // Update the image name and base64 content for this image
            const partsImageName = response.public_id;
            const partsImageFormat = response.format;
            const partsBase64id = modifiedBase64Content;
            setPartsBase64id(partsBase64id);
            setPartsImageFormat(partsImageFormat);
            // Add the image object to the state
            setPartsImagesSrc((prevState) => [
              ...prevState,
              { imageUrl, partsImageName, partsImageFormat, partsBase64id },
            ]);
            setShowImage(multiplePartImage);
            setOneImageTruePart(multiplePartImage?.[0]);
          };
        });
    }
    setIspageLoading(false);
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dmrw6iq4q",
    },
  });

  // --------------delete uploaded Image--------------------------//
  const deleteItem = (e, index) => {
    const datan = partsImageSrc?.map((item) =>
      item?.imageId
        ? item?.imageId === e?.imageId
          ? { ...item, MarkDelete: "true" }
          : item
        : item
    );
    const newData = datan?.filter((item) =>
      item?.imageId ? item : item?.partsImageName !== e?.partsImageName
    );

    const preview = newData?.filter(
      (item) => item?.MarkDelete === false || item?.partsImageName?.length > 0
    );
    setPartsImagesSrc(newData);
    setShowImage(preview);
    setPartsSelectedFile(preview);
    setDummyTest(preview);
    if (
      e?.imageUrl === trueImageDataPart ||
      e?.imageUrl === partsSelectedImage ||
      e === trueImageDataPart ||
      e === partsSelectedImage
    ) {
      setUserSelectedDefaultImagePart(true);
    }
  };
  // -------------BrandsData---------------///
  const GetBrandsData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetBrands`,
    };

    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const brand = JSON.parse(e.data.data);
        setPartsBrands(brand);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    GetBrandsData();
  }, []);
  // ------------------------Modals Data----------------------------------//

  const GetModalsData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetModels?parentId=${partsBrandId}`,
    };

    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const partsModals = JSON.parse(e.data.data);
        setPartsModal(partsModals);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    if (partsBrandId?.length) {
      GetModalsData();
    }
  }, [partsBrandId]);

  const PartsImages = partsImageSrc.map((img) => {
    const filterUrl = dummyTest?.filter(
      (item) => item.imageUrl === img.imageUrl
    );
    const imageObj = {
      ImageExtenssion: img.partsImageFormat,
      Type: "Parts",
      FileName: img.partsImageName,
      DefaultImage:
        (showImage?.length === 1 && img.imageUrl === oneImagTruePart) ||
        img.imageUrl === partsSelectedImage ||
        img.imageUrl === trueImageDataPart ||
        (dummyTest?.length === 1 && img.imageUrl === filterUrl?.[0]?.imageUrl),
      Base64Content: img.partsBase64id,
    };
    if (img?.imageId) {
      imageObj.Id = img.imageId;
    }
    if (img.MarkDelete !== undefined) {
      imageObj.MarkDelete = img.MarkDelete;
    }
    return imageObj;
  });

  // -----validations-------//
  // const hasSelectedImage = PartsImages.find(
  //   (item) => item.DefaultImage === true
  // );
  const Validate = () => {
    if (!partsBrandId) {
      toast.error("Please Select Brand", {
        theme: "colored",
      });
      return false;
    }
    if (!partsModalId) {
      toast.error("Please Select Model", {
        theme: "colored",
      });
      return false;
    }
    if (!partName) {
      toast.error("Please Fill Part Name", {
        theme: "colored",
      });
      return false;
    }
    if (!partType) {
      toast.error("Please Select Part Type", {
        theme: "colored",
      });
      return false;
    }
    if (!partTitle) {
      toast.error("Please Fill Part Title", {
        theme: "colored",
      });
      return false;
    }
    if (!partsDescription) {
      toast.error("Please Fill Part Description", {
        theme: "colored",
      });
      return false;
    }
    if (!partPriceState) {
      toast.error("Please Select Price Category", {
        theme: "colored",
      });
      return false;
    }
    if (!partsPrice) {
      toast.error("Please Fill Price ", {
        theme: "colored",
      });
      return false;
    }
    if (partsImageSrc.length === 0) {
      toast.error("Please Select Images To Upload", {
        theme: "colored",
      });
      return false;
    }
    if (userSelectedDefaultImagePart === true) {
      toast.error("Please select a default image ", {
        theme: "colored",
      });
      return false;
    }
    if (!countryId) {
      toast.error("Please select country ", {
        theme: "colored",
      });
      return false;
    }
    if (countryId === "Select") {
      toast.error("Please select country", {
        theme: "colored",
      });
      return false;
    }
    if (!state) {
      toast.error("Please select state ", {
        theme: "colored",
      });
      return false;
    }
    if (!city) {
      toast.error("Please Fill city", {
        theme: "colored",
      });
      return false;
    }
    if (!neighbourhood) {
      toast.error("Please Fill Neighbourhood", {
        theme: "colored",
      });
      return false;
    }
    return true;
  };
  // --------------------
  const handlePriceChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    value = new Intl.NumberFormat("en-IN").format(value); // add commas
    setPartsPrice(value);
  };

  // ----------------post Vehicle data---------//

  const handlePartsSubmit = async (e) => {
    e.preventDefault();
    if (!Validate()) return;
    setIspageLoading(true);
    try {
      const data = {};

      data.BrandId = parseInt(partsBrandId);
      data.ModelId = parseInt(partsModalId);
      data.PartName = partName;
      data.TypeId = partType;
      data.Description = partsDescription;
      data.Price = partsPrice;
      data.PriceType = partPriceState;
      data.PartImages = PartsImages;
      data.AdvertisementTitle = partTitle;
      data.CountryId = countryId;
      data.StateId = state;
      data.City = city;
      data.Neighbourhood = neighbourhood;

      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },

        url: `${Apiurl()}/Seller/PostPartAd`,
      };

      const response = await axios(option);
      setIspageLoading(false);
      toast.success("Ad Submitted Successfully", {
        theme: "colored",
      });
      setPartsBrandId("");
      setPartsModal("");
      setPartName("");
      setPartType("");
      setPartsDescription("");
      setPartsPrice("");
      setPartPriceState("");
      setPartsImages("");
      setPartTitle("");
      setShowImage("");

      setCountryId("");
      setState("");
      setCity("");
      setNeighbourhood("");

      navigate("/dashboard");
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  ///

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!Validate()) return;
    setIspageLoading(true);

    try {
      const data = {};
      data.Id = JSON.stringify(PartsUserId);
      data.BrandId = parseInt(partsBrandId);
      data.ModelId = parseInt(partsModalId);
      data.PartName = partName;
      data.TypeId = partType;
      data.Description = partsDescription;
      data.Price = partsPrice;
      data.PriceType = partPriceState;
      data.PartImages = PartsImages;
      data.AdvertisementTitle = partTitle;
      data.CountryId = countryId;
      data.StateId = state;
      data.City = city;
      data.Neighbourhood = neighbourhood;

      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },

        url: `${Apiurl()}/Seller/UpdatePartAd`,
      };

      const response = await axios(option);
      setIspageLoading(false);
      toast.success("Ad Submitted Successfully", {
        theme: "colored",
      });
      setPartsBrandId("");
      setPartsModal("");
      setPartName("");
      setPartType("");
      setPartsDescription("");
      setPartsPrice("");
      setPartPriceState("");
      setPartsImages("");
      setShowImage("");
      setPartTitle("");
      setCountryId("");
      setState("");
      setCity("");
      setNeighbourhood("");

      navigate("/dashboard");
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="main-all-div-post">
      {isPageLoading ? <Loader /> : null}

      <div className="main_border postAdd-2">
        <div className="data_Category mt-3">
          <h2 className="text-center fw-bold mb-4">Post Your Ad</h2>
          <h3 className="selct_hding mb-4">Select Category</h3>
          <form>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Brand<span className="required">*</span>
              </label>
              <select
                name="brands"
                className="type_input"
                onChange={(e) => setPartsBrandId(e.target.value)}
                value={partsBrandId}
              >
                <option value="">Select a brand</option>
                {partsBrands.map((brand) => (
                  <option key={brand.Id} value={brand.Id}>
                    {brand.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Models<span className="required">*</span>
              </label>
              <select
                name="brands"
                className="type_input"
                onChange={(e) => setPartsModalId(e.target.value)}
                value={partsModalId}
              >
                <option value="">Select a Model</option>
                {partsModals.map((modal) => (
                  <option key={modal.Id} value={modal.Id}>
                    {modal.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Part Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control type_input"
                required
                value={partName}
                onChange={(e) => setPartName(e.target.value)}
              />
            </div>
            <div className="mb-4 radio--buttons">
              <label className="brnd_label mt-4 mb-3">
                Part Type<span className="required">*</span>
              </label>
              <div className="parts-input-type">
                <input
                  label="Internal"
                  type="radio"
                  name="Transmission"
                  value="-1"
                  defaultChecked={PartseditLocation?.Type === "Internal"}
                  onClick={(e) => setPartType(e.target.value)}
                />
                <input
                  label="External"
                  type="radio"
                  name="Transmission"
                  value="-2"
                  defaultChecked={PartseditLocation?.Type === "External"}
                  onClick={(e) => setPartType(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Ad Title<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control type_input"
                required
                value={partTitle}
                onChange={(e) => setPartTitle(e.target.value)}
              />
              <p className="mntion_key">
                Mention the key features of your item (e.g. brand, model, age,
                type)
              </p>
            </div>

            <div className="mb-4">
              <label className="brnd_label mb-3">
                Ad Description<span className="required">*</span>
              </label>
              <textarea
                id="w3review"
                name="w3review"
                rows="7"
                cols="50"
                className="type_input"
                value={partsDescription}
                onChange={(e) => setPartsDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="data_Category mb-4">
              <h2 className="selct_hding">
                Set A Price<span className="required">*</span>
              </h2>
              <div className="buttons mb-3">
                <div className="parts-input-type">
                  <input
                    label="Per Piece"
                    type="radio"
                    name="price"
                    value="Per Piece"
                    defaultChecked={
                      PartseditLocation?.PriceType === "Per Piece"
                    }
                    onClick={(e) => setPartPriceState(e.target.value)}
                  />
                  <input
                    label="Per Pair"
                    type="radio"
                    name="price"
                    value="Per Pair"
                    defaultChecked={PartseditLocation?.PriceType === "Per Pair"}
                    onClick={(e) => setPartPriceState(e.target.value)}
                  />
                  <input
                    label="Per Set"
                    type="radio"
                    name="price"
                    value="Per Set"
                    defaultChecked={PartseditLocation?.PriceType === "Per Set"}
                    onClick={(e) => setPartPriceState(e.target.value)}
                  />
                  <input
                    label="Bulk"
                    type="radio"
                    name="price"
                    value="Bulk"
                    defaultChecked={PartseditLocation?.PriceType === "bulk"}
                    onClick={(e) => setPartPriceState(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="brnd_label mb-3">
                  Price<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="kmDriven"
                  name="kmDriven"
                  className="form-control type_input"
                  value={partsPrice}
                  onChange={handlePriceChange}
                />
              </div>
            </div>

            <div className="data_Category mb-4">
              <h2 className="selct_hding ">
                Upload up to 20 photos<span className="required">*</span>
              </h2>
              {!showImage?.length ? (
                <p className="mntion_key-image">
                  Please Select at least 1 Image
                </p>
              ) : null}
              <div className="upload-parts-image-grid">
                {showImage?.length > 0
                  ? showImage?.map((partImg, i) => {
                      return (
                        <div className="outer-div-vehicle" key={i}>
                          <div className="image-parts-radio-buttons">
                            <label>
                              <input
                                type="radio"
                                className="image-radio select-icons-image"
                                name="image"
                                onChange={() =>
                                  handleImageSectionPart(
                                    partImg.imageUrl || partImg
                                  )
                                }
                                checked={
                                  partsSelectedImage ===
                                    (partImg.imageUrl || partImg) ||
                                  (oneImagTruePart &&
                                    showImage?.length === 1) ||
                                  partImg?.imageUrl === trueImageDataPart ||
                                  (dummyTest?.length === 1 &&
                                    partImg?.imageUrl ===
                                      dummyTest?.[0]?.imageUrl)
                                }
                              />

                              <img
                                src={partImg.imageUrl || partImg}
                                alt={partImg}
                                className="upload-part-images"
                              />
                            </label>
                          </div>
                          <ImCross
                            onClick={() => deleteItem(partImg, i)}
                            className="delete-part-image-icon delete-image-icon-delete"
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>

              <input
                type="file"
                multiple
                name="partsuploadfile"
                id="img"
                className="input-parts-field-image"
                onChange={handlePartsImageUpload}
              />

              <label htmlFor="img" className="label-parts-upload-image">
                <AiOutlineCloudUpload className="upload-icon-image" />
                <h5 className="para-parts-upload-image">
                  browse to choose files
                </h5>
              </label>
            </div>
            <div className="data_Category mb-4">
              <h2 className="selct_hding">Confirm Your Location</h2>

              <div className="mt-2">
                <div className="upload_tabs">
                  <div className="tab-content mt-3" id="pills-tabContent">
                    <Tabs
                      defaultActiveKey="PartsList"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                    >
                      <Tab
                        eventKey="PartsList"
                        title="List"
                        className="postAdd_btn_1"
                      >
                        <PartsList
                          setCity={setCity}
                          city={city}
                          neighborhood={neighbourhood}
                          state={state}
                          setNeighbourhood={setNeighbourhood}
                          countryId={countryId}
                          setCountryId={setCountryId}
                          country={country}
                          setCountry={setCountry}
                          setState={setState}
                          oldStateid={oldStateid}
                        />
                      </Tab>
                      <Tab
                        eventKey="Location"
                        title="Location"
                        className="postAdd_btn_2"
                      >
                        <Location />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="data_Category mt-4">
              <div className="mt-3">
                {PartseditLocation ? (
                  <button onClick={handleUpdate} className="sbmit_btn_end">
                    Update
                  </button>
                ) : (
                  <button onClick={handlePartsSubmit} className="sbmit_btn_end">
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Parts;

import React, { Fragment, useEffect, useState } from "react";
import "../../../src/App.css";
import car1 from "../../Images/car1.png";
import quality from "../../Images/quality.png";
import like from "../../Images/like.png";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../loader/loader";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import cross from "../../Images/cross.png";
import arrowDown from "../../Images/arrow-down.png";
import jwt_decode from "jwt-decode";
import Select from "react-select";
const Results = ({
  accessoryFilterData,
  accessoryTypeDataArray,
  accessoryDataPriceRange,
  partsFilterData,
  partsTypeDataArray,
  partsDataPriceRange,
  vehicleFilterData,
  vehicleDataBrand,
  vehicleDataBrandPart,
  vehicleDataModel,
  vehicleDataModelPart,
  vehicleTypeDataArray,
  vehicleTransmissionData,
  vehicleFuelTypedata,
  vehiclekmdrivenData,
  vehicleDataPriceRange,
  addToFavData,
  setAddToFavData,
  allData,
  toggle,
  setToggle,
  AdsCategory,
  setDumVal,
  
}) => {
  const navigate = useNavigate();
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [parseDataAccessory, setParseDataAccesories] = useState([]);
  const [show, setShow] = useState(false);
  const [isPageLoading, setIspageLoading] = useState(false);
  const [parseDataParts, setParseDataParts] = useState([]);
  const [parseDataVehicle, setPartsDataVehicle] = useState([]);
  const [googleTokenId, setGoogleTokenId] = useState("");
  const [vehicleIdOne, setVehicleIdOne] = useState();
  const [dummy, setDummy] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(12);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // ----------Sort Accesory Data-------//
  const sortedDataAccesory = parseDataAccessory?.sort(
    (a, b) => a.Price - b.Price
  );
  const sortDataByDateAccesory = sortedDataAccesory?.sort(
    (a, b) => new Date(b.PublishOn) - new Date(a.PublishOn)
  );

  // ----------Sort Parts Data-------//

  const sortedDataParts = parseDataParts?.sort((a, b) => a.Price - b.Price);
  const sortDataByDateParts = sortedDataParts?.sort(
    (a, b) => new Date(b.PublishDate) - new Date(a.PublishDate)
  );
  // ----------Sort Vehicle Data-------//
  const sortedDataVehicle = parseDataVehicle?.sort((a, b) => a.Price - b.Price);
  const sortDataByDateVehicle = sortedDataVehicle?.sort(
    (a, b) => new Date(b.PublishDate) - new Date(a.PublishDate)
  );

  // -----------Pagination----------------
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    setBlogPosts(sortDataByDateVehicle);
  }, [sortDataByDateVehicle]);
  useEffect(() => {
    setBlogPosts(sortDataByDateParts);
  }, [sortDataByDateParts]);
  useEffect(() => {
    setBlogPosts(sortDataByDateAccesory);
  }, [sortDataByDateAccesory]);

  // -------------Pagination----------//
  const totalPosts = blogPosts?.length;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts?.slice(indexOfFirstPost, indexOfLastPost);
  const lastPage = Math.ceil(blogPosts?.length / postsPerPage);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const nextPage = () => {
    if (currentPage !== Math.ceil(blogPosts?.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const startResult = Math.min(indexOfFirstPost, totalPosts);
  const endResult = Math.min(indexOfLastPost, totalPosts);

  // --------scroll to top------------------//
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage, postsPerPage]);

  // --------------Google Login------------------//
  const onSuccess = (credentialResponse) => {
    const decoded = jwt_decode(credentialResponse.credential);
    localStorage.setItem("LoginData", JSON.stringify(decoded));
    const googleToken = credentialResponse.credential;
    setGoogleTokenId(googleToken);
    // setProfile(profile);
    setShow(false);
  };
  const onError = () => {};
  const loginData = async () => {
    try {
      setIspageLoading(true);
      const data = { tokenid: googleTokenId };
      const obj = { data: JSON.stringify(data) };
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
        },
        data: JSON.stringify(obj),
        url: `${Apiurl()}/Account/SignInWithGoogle`,
      };

      const response = await axios(option);
      setIspageLoading(false);
      const tokenId = response.data.data;
      localStorage.setItem("tokenID", JSON.stringify(tokenId));
      setDumVal(tokenId)

      setDummy(true);

      // navigate("/profile");
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (googleTokenId) {
      loginData();
    }
  }, [googleTokenId]);

  // -----------------Accesories Filtering-------------------

  useEffect(() => {
    if (accessoryTypeDataArray?.length || accessoryDataPriceRange?.length) {
      setParseDataAccesories(accessoryFilterData);
    } else {
      const parseResult = allData;

      setParseDataAccesories(parseResult.Results);
    }
  }, [
    allData,
    accessoryTypeDataArray,
    accessoryDataPriceRange,
    accessoryFilterData,
  ]);

  // --------------------Parts Filtering-----------------------//
  useEffect(() => {
    if (
      vehicleDataBrandPart?.length ||
      vehicleDataModelPart?.length ||
      partsTypeDataArray?.length ||
      partsDataPriceRange?.length
    ) {
      setParseDataParts(partsFilterData);
    } else {
      const parseResultParts = allData;
      setParseDataParts(parseResultParts.Results);
    }
  }, [
    allData,
    vehicleDataBrandPart,
    vehicleDataModelPart,
    partsTypeDataArray,
    partsDataPriceRange,
    partsFilterData,
  ]);

  // --------------------------Vehicle Filtering-------------//-----------

  useEffect(() => {
    if (
      vehicleDataBrand?.length ||
      vehicleDataModel?.length ||
      vehicleTypeDataArray?.length ||
      vehicleDataPriceRange?.length ||
      vehicleFuelTypedata?.length ||
      vehiclekmdrivenData?.length ||
      vehicleTransmissionData?.length
    ) {
      setPartsDataVehicle(vehicleFilterData);
    } else {
      const parseResultVehicle = allData;

      setPartsDataVehicle(parseResultVehicle.Results);
    }
  }, [
    allData,
    vehicleDataBrand,
    vehicleDataModel,
    vehicleTypeDataArray,
    vehicleTransmissionData,
    vehicleDataPriceRange,
    vehicleFilterData,
    vehicleFuelTypedata,
    vehiclekmdrivenData,
  ]);

  const vehicleSingleItem = (item) => {
    const productName = item.AdvertisementTitle?.toLowerCase().replace(
      /\s/g,
      "-"
    );
    const vehicleId = item?.Id;
    let adsCategoryName;
    if (AdsCategory == 1) {
      adsCategoryName = "Vehicle";
    }

    navigate(`/detail/${adsCategoryName}/${vehicleId}/${productName}`, {
      state: {
        item: item,
        adsCategory: AdsCategory,
      },
    });
  };
  const AccessorySingleItem = (item) => {
    const productName = item.Name?.toLowerCase().replace(/\s/g, "-");
    const GetVehicleId = item?.Id;

    let adsCategoryName;
    if (AdsCategory == 2) {
      adsCategoryName = "Accessories";
    }

    navigate(`/detail/${adsCategoryName}/${GetVehicleId}/${productName}`, {
      state: {
        item: item,
        adsCategory: AdsCategory,
      },
    });
  };
  const PartSingleItem = (item) => {
    const productName = item.PartName?.toLowerCase().replace(/\s/g, "-");
    const GetVehicleId = item?.Id;

    let adsCategoryName;
    if (AdsCategory == 0) {
      adsCategoryName = "Parts";
    }
    navigate(`/detail/${adsCategoryName}/${GetVehicleId}/${productName}`, {
      state: {
        item: item,
        adsCategory: AdsCategory,
      },
    });
  };
  // -----------------Add to Fav Function-----------//
  const handleHeartClicked = (vehicleId, e) => {
    setVehicleIdOne(vehicleId);
    if (!tokenid) {
      handleShow();
      return;
    }
    setIspageLoading(true);
    const data = {};
    data.Id = vehicleId || vehicleIdOne;
    data.AdsCategory = AdsCategory;
    data.IsFavorite = e === "liked" ? false : true;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Customer/Favorite-UnFavorite`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        setToggle(e);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
      })
      .catch((error) => {
        setIspageLoading(false);
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    if (dummy) {
      handleHeartClicked();
      setDummy(false);
    }
  }, [dummy]);

  // ---------Results per page-------//
  const options = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
  ];

  const resultPerPage = (selectedOption) => {
    setPostPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  return (
    <div>
      <div className="row result-div-page">
        {isPageLoading ? <Loader /> : null}
        {currentPosts?.map((item, index) => {
          const Dimage = item?.PartImages?.find((i) => i?.DefaultImage);
          const partImage = item?.VehicleImages?.find((i) => i?.DefaultImage);
          const accessoriesImage = item?.AccesoryImages?.find(
            (i) => i?.DefaultImage
          );
          const VehicleId = item?.Id;

          const value = item.Price;
          const valueprice = new Intl.NumberFormat("en-IN").format(value);

          return (
            <Fragment key={item?.Id}>
              {item.AccesoryImages ? (
                <>
                  <div className="col-sm-4">
                    <div className="cars_deal">
                      <a>
                        <img
                          src={
                            Dimage?.UrlLink ||
                            partImage?.UrlLink ||
                            accessoriesImage?.UrlLink
                          }
                          onClick={(e) => AccessorySingleItem(item)}
                          alt="Cars"
                          className="imgclassName"
                        />
                      </a>
                      <a className="likes_btn">
                        {item.IsFavourite === true ? (
                          <>
                            <AiFillHeart
                              onClick={() =>
                                handleHeartClicked(VehicleId, "liked")
                              }
                            />
                          </>
                        ) : (
                          <AiOutlineHeart
                            onClick={() =>
                              handleHeartClicked(VehicleId, "dislike")
                            }
                          />
                        )}
                      </a>
                      <div className="cars_deal_wrap">
                        <div className="mb-2 heart-name ">
                          <h5
                            onClick={(e) => AccessorySingleItem(item)}
                            className="paragraph-detail"
                          >
                            {item?.Name}
                          </h5>
                        </div>
                        <div className="mb-2">
                          <p className="paragraph-detail">{item.Description}</p>
                        </div>
                        <h4 className="paragraph-detail">
                          ${valueprice} ({item.PriceType})
                        </h4>
                        {/* <span>
                          <img src={quality} alt="Quality" />
                          Assured
                        </span>
                        <span className="regular_off mt-2">
                          $2% Regular Off
                        </span> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </Fragment>
          );
        })}

        {currentPosts?.length ? (
          <>
            {currentPosts?.map((item, index) => {
              const Dimage = item?.PartImages?.find((i) => i?.DefaultImage);
              const partImage = item?.VehicleImages?.find(
                (i) => i?.DefaultImage
              );
              const accessoriesImage = item?.AccesoryImages?.find(
                (i) => i?.DefaultImage
              );
              const VehicleId = item?.Id;

              const value = item.Price; // remove non-numeric characters
              const valueprice = new Intl.NumberFormat("en-IN").format(value);
              return (
                <Fragment key={item?.Id}>
                  {item?.VehicleImages ? (
                    <>
                      <div className="col-sm-4">
                        <div className="cars_deal">
                          <a>
                            <img
                              src={
                                Dimage?.UrlLink ||
                                partImage?.UrlLink ||
                                accessoriesImage?.UrlLink
                              }
                              onClick={(e) => vehicleSingleItem(item)}
                              alt="Cars"
                              className="imgclassName"
                            />
                          </a>
                          <a className="likes_btn">
                            {item.IsFavourite === true ? (
                              <>
                                <AiFillHeart
                                  onClick={() =>
                                    handleHeartClicked(VehicleId, "liked")
                                  }
                                />
                              </>
                            ) : (
                              <AiOutlineHeart
                                onClick={() =>
                                  handleHeartClicked(VehicleId, "dislike")
                                }
                              />
                            )}
                          </a>
                          <div className="cars_deal_wrap">
                            <div className="mb-2 heart-name">
                              <h5
                                onClick={(e) => vehicleSingleItem(item)}
                                className="paragraph-detail"
                              >
                                {item.AdvertisementTitle.toUpperCase()}
                              </h5>
                            </div>
                            <div className="mb-2">
                              <p className="paragraph-detail-vehicle">
                                Km Driven : {item.Mileage} kms <br />
                                Fuel Type : {item.FuelType} <br />
                                Transmission : {item.Transmission}
                              </p>
                            </div>
                            <div className="mb-2">
                              <p className="paragraph-detail">
                                {item.Description}
                              </p>
                            </div>

                            <h4 className="paragraph-detail">${valueprice}</h4>
                            {/* <span>
                            <img src={quality} alt="Quality" />
                            Assured
                          </span> */}
                            {/* <span className="regular_off mt-2">
                          $2% Regular Off
                        </span> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </Fragment>
              );
            })}
          </>
        ) : (
          <p className="no-result-filter">No Result!</p>
        )}

        {currentPosts?.map((item, index) => {
          const Dimage = item?.PartImages?.find((i) => i?.DefaultImage);
          const partImage = item?.VehicleImages?.find((i) => i?.DefaultImage);
          const accessoriesImage = item?.AccesoryImages?.find(
            (i) => i?.DefaultImage
          );
          const VehicleId = item?.Id;

          const value = item.Price; // remove non-numeric characters
          const valueprice = new Intl.NumberFormat("en-IN").format(value);
          return (
            <Fragment key={item?.Id}>
              {item.PartImages ? (
                <>
                  <div className="col-sm-4">
                    <div className="cars_deal">
                      <a>
                        <img
                          src={
                            Dimage?.UrlLink ||
                            partImage?.UrlLink ||
                            accessoriesImage?.UrlLink
                          }
                          onClick={(e) => PartSingleItem(item)}
                          alt="Cars"
                          className="imgclassName"
                        />
                      </a>
                      <a className="likes_btn">
                        {item.IsFavourite === true ? (
                          <>
                            <AiFillHeart
                              onClick={() =>
                                handleHeartClicked(VehicleId, "liked")
                              }
                            />
                          </>
                        ) : (
                          <AiOutlineHeart
                            onClick={() =>
                              handleHeartClicked(VehicleId, "dislike")
                            }
                          />
                        )}
                      </a>
                      <div className="cars_deal_wrap">
                        <div className="mb-2 heart-name">
                          <h5
                            onClick={() => PartSingleItem(item)}
                            className="paragraph-detail"
                          >
                            {item.PartName} ({item.Brand})
                          </h5>
                        </div>

                        <div className="mb-2">
                          <p className="paragraph-detail">{item.Description}</p>
                        </div>
                        <h4 className="paragraph-detail">
                          ${valueprice} ({item.PriceType})
                        </h4>
                        {/* <span>
                          <img src={quality} alt="Quality" />
                          Assured
                        </span>
                        <span className="regular_off mt-2">
                          $2% Regular Off
                        </span> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </Fragment>
          );
        })}
        {currentPosts?.length ? (
          <>
            <nav
              aria-label="Page navigation example "
              className="pagination-botttom"
            >
              <div className="showing-para-pagination">
                <p>
                  Showing {startResult + 1} - {endResult} of {totalPosts}{" "}
                  results
                </p>
              </div>
              <div>
                <ul className="pagination result-pagination">
                  <li className="page-item">
                    <button
                      disabled={currentPage === 1 ? true : false}
                      className={`page-link ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={previousPage}
                    >
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      onClick={() => paginate(number)}
                      className={
                        "page-item" + (number === currentPage ? "active" : "")
                      }
                    >
                      <a className="page-link">{number}</a>
                    </li>
                  ))}

                  <li className="page-item">
                    <button
                      disabled={currentPage === lastPage ? true : false}
                      className={`page-link ${
                        currentPage === lastPage ? "disabled" : ""
                      }`}
                      onClick={nextPage}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
              <div className="result-per-page-div">
                <p>Results Per Page</p>
                <Select
                  options={options}
                  onChange={resultPerPage}
                  placeholder="Per Page"
                  menuPosition="fixed"
                  menuPlacement="top"
                />
              </div>
            </nav>
          </>
        ) : null}

        {/* <div className="pagination-container">
			<ul className="pagination">
				<li onClick={previousPage} className="page-number">
					Prev
				</li>
				{pageNumbers.map((number) => (
					<li
						key={number}
						onClick={() => paginate(number)}
						className={
							'page-number ' + (number === currentPage ? 'active' : '')
						}
					>
						{number}
					</li>
				))}
				<li onClick={nextPage} className="page-number">
					Next
				</li>
			</ul>
		</div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-google"
        id="logIN"
      >
        <Modal.Body className="modal_body_content text-center">
          <div className="body_popup">
            <a
              className="close_button"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src={cross} alt="Cross" />
            </a>
            <h1>
              Sign in with <span>Google</span>
            </h1>
            <a>
              <img src={arrowDown} className="aroow_down" alt="arrowDown" />
            </a>
            {/* <button className="signin-butt-google" onClick={() => login()}>
              <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              
            </button> */}
            {/* <button className="signin-butt-google">
            <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              Login with Google{" "} */}
            <div className="googleLoginButt">
              <GoogleLogin
                theme="filled_blue"
                size="large"
                shape="circle"
                width="220px"
                logo_alignment="left"
                onSuccess={onSuccess}
                onError={onError}
                className="google-butt"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Results;

import React from "react";
import "../../../src/App.css";

const Footer = () => {
  return (
    <section className="main_footer footer-main">
      <div className="container">
        <div className="footer-main-div">
          <div className="footer-part1">
            <p className="m-0">Copyright © MarketPlace 2023</p>
          </div>
          <div className="footer-part2">
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

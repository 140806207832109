import React, { useEffect, useState } from "react";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import DetailTab from "../../Components/Details/DetailTab";
import ImageSlicker from "../../Components/Details/ImageSlicker";
import Description from "../../Components/Details/Description";
import "../../../src/App.css";
import { useLocation } from "react-router-dom";
import { Apiurl } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";

const MainDetail = () => {
  const googleLoginData = JSON.parse(localStorage.getItem("LoginData"));
  const [country, setCountry] = useState([]);
  const [isPageLoading, setIspageLoading] = useState(false);

  const [adsCategory, setAdsCategory] = useState();

  const [adSourceCountryId, setAdSourceCountryId] = useState();
  const [sourceCountryId, setSourceCountryId] = useState();
  const [DummyValueLogin, setDummyValueLogin] = useState();
  const [singleAdData, setSingleAdData] = useState();
  const state = useLocation();
  const location = useLocation();
  const pathname = location?.pathname;
  const urlParts = pathname.split("/");

  // Step 2: Extract the values from the split parts
  const categ = urlParts[2];
  const GetId = urlParts[3];

  const getAdsByName = () => {
    setIspageLoading(true);
    const data = {};
    data.Id = GetId;
    data.AdsCategory = categ;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Seller/GetAdsByname`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const getData = JSON.parse(e.data.data);
        setSingleAdData(getData);
      })
      .catch((error) => {
        setIspageLoading(false);
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    getAdsByName();
  }, []);

  useEffect(() => {
    setAdSourceCountryId(
      state?.state?.item?.Country?.Id || state?.state?.Country?.Id
    );
    setAdsCategory(state?.state?.adsCategory);
  }, []);

  return (
    <>
      <main className="main_wrapper">
        <Header
          setSourceCountryId={setSourceCountryId}
          sourceCountryId={sourceCountryId}
          setCountry={setCountry}
          country={country}
          DummyValueLogin={DummyValueLogin}
          checkFind={true}
        />
        <section className="filer_heading">
          <div className="container">
            <section className="colsm_detil">
              <div className="row">
                <div className="col-sm-7">
                  <ImageSlicker singleAdData={singleAdData} />
                  <Description singleAdData={singleAdData} />
                </div>
                <DetailTab
                  singleAdData={singleAdData}
                  adSourceCountryId={adSourceCountryId}
                  setAdSourceCountryId={setAdSourceCountryId}
                  setSourceCountryId={setSourceCountryId}
                  sourceCountryId={sourceCountryId}
                  setCountry={setCountry}
                  country={country}
                  adsCategory={adsCategory}
                  setDummyValueLogin={setDummyValueLogin}
                  DummyValueLogin={DummyValueLogin}
                />
              </div>
            </section>
          </div>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default MainDetail;

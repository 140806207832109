import React, { useState, CSSProperties, useEffect } from "react";
import "../../../src/App.css";
import { Tab, Tabs } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { Cloudinary, Transformation } from "@cloudinary/url-gen";
import { opacity } from "@cloudinary/url-gen/actions/adjust";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { Position } from "@cloudinary/url-gen/qualifiers";
import { compass } from "@cloudinary/url-gen/qualifiers/gravity";
import { text } from "@cloudinary/url-gen/qualifiers/source";
import { TextStyle } from "@cloudinary/url-gen/qualifiers/textStyle";
import { ImCross } from "react-icons/im";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { ClipLoader } from "react-spinners";
import { Apiurl, IsStoreAdded, RemoveSession, SellerCanAddStore, Url } from "../../Utils/Utils";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import { RiArrowDropDownLine } from "react-icons/ri";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import Location from "./List/Location";
import List from "./List/List";
import { useLocation, useNavigate } from "react-router-dom";

const Vehicle = ({ editLocation }) => {
  const [isPageLoading, setIspageLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [brands, setBrands] = useState([]);

  const [brandId, setBrandId] = useState();
  const [modalId, setModalId] = useState();
  const [typeData, setTypesData] = useState([]);
  const [typeDataId, setTypeDataId] = useState();

  const [userId, setUserId] = useState();
  const [modals, setModals] = useState([]);
  const [registerationMonth, setRegistrationMonth] = useState("");
  const [registrationYear, setRegistrationYear] = useState("");
  const [variants, setVariants] = useState([]);
  const [variantsId, setVariantsId] = useState();
  const [fuelType, SetFuelType] = useState("");
  const [transmission, setTransmission] = useState("");
  const [kmDriven, setKmDriven] = useState("");
  const [ownership, setOwnership] = useState("");
  const [advertisementTitle, setAdvertisementTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState([]);
  const [base64id, setBase64id] = useState("");
  const [imageFormat, setImageFromat] = useState("");
  const [city, setCity] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [imageName, setImageName] = useState("");
  const [Profile, setProfile] = useState([]);
  const [countryId, setCountryId] = useState();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState();
  const [stateData, setStateData] = useState([]);
  const [oldbrandId, setOldBrandId] = useState();
  const [oldStateid, setOldStateId] = useState();
  const [dummyDataImage, setDummyDataImg] = useState();
  const [userSelectedDefaultImageVehicle, setUserSelectedDefaultImageVehicle] =
    useState(false);

  const [trueImageDataVehicle, setTrueImageDataVehicle] = useState();
  const [oneImagTrueVehicle, setOneImageTrueVehicle] = useState();
  const [dummyImageValue, setDummyImageValue] = useState();

  const navigate = useNavigate();

  //---------------------------Edit Data---------------------//

  // --------------Edit Brand Model and Variants-------------//
  const brandsid = brands?.find((item) => item.Name === editLocation?.Brand);

  const typesid = typeData?.find((item) => item.Name === editLocation?.Type);
  const Modelsid = modals?.find((item) => item.Name === editLocation?.Model);
  const variantsid = variants?.find(
    (item) => item.Name === editLocation?.Variant
  );
  const [dummyTest, setDummyTest] = useState();

  useEffect(() => {
    if (editLocation) {
      if (!dummyDataImage && imagePreview?.length > 1) {
    
        setUserSelectedDefaultImageVehicle(true);
      }
    }
  }, [dummyDataImage, imagePreview]);

  useEffect(() => {
    if (!editLocation && dummyImageValue === true) {
  
      setUserSelectedDefaultImageVehicle(true);
    }
  }, [dummyImageValue]);

  useEffect(() => {
    if (dummyTest?.length === 1) {
      setUserSelectedDefaultImageVehicle(false);
    }
  }, [dummyTest]);

  const BrandFun = (e) => {
    setBrandId(e.target.value);
    setModalId("");
    setVariantsId("");
  };
  const ModalFun = (e) => {
    setModalId(e.target.value);

    setVariantsId("");
  };
  useEffect(() => {
    setBrandId(brandsid?.Id);
    if (brandId) {
      GetModalsData();
    }
  }, [brandsid]);
  useEffect(() => {
    setModalId(Modelsid?.Id);
  }, [Modelsid]);
  useEffect(() => {
    if (modalId) {
      GetVariantsData();
    }
  }, [modalId]);
  useEffect(() => {
    setVariantsId(variantsid?.Id);
  }, [variants]);
  useEffect(() => {
    setTypeDataId(typesid?.Id);
  }, [typeData]);

  // --------------------------Edit Image----------------------//

  const Editimage = editLocation?.Images;
  useEffect(() => {
    if (Editimage) {
      const gettrueData = Editimage?.find(
        (item) => item?.DefaultImage === true
      );
      setTrueImageDataVehicle(gettrueData?.UrlLink);
      setDummyDataImg(gettrueData);
      setUserSelectedDefaultImageVehicle(false);
      const imageSrc = Editimage?.map((img) => ({
        imageId: img.Id,
        imageName: img.FileName || "",
        imageUrl: img.UrlLink,
        imageFormat: img.ImageExtenssion,
        base64id: "",
        MarkDelete: false,
      }));

      setImageSrc(imageSrc);
      setImagePreview(imageSrc);
    }
  }, [editLocation, Editimage]);

  // -------------------Edit Country and state------------------------//
  useEffect(() => {
    if (country) {
      const selectedCountry = country?.find(
        (c) => c.Name === editLocation?.Country?.Name
      );
      const oldstate = setOldStateId(editLocation?.State?.Id);
      if (selectedCountry) {
        setCountryId(selectedCountry.Id);
      }
    }
  }, [country, editLocation?.Country]);

  // -----------Editing other fields-------------------//
  useEffect(() => {
    setAdvertisementTitle(editLocation?.AdvertisementTitle);
    setKmDriven(editLocation?.Mileage);
    setDescription(editLocation?.Description);
    setPrice(editLocation?.Price);
    setModalId(editLocation?.Modals?.Id);
    setRegistrationYear(editLocation?.RegYear);
    setRegistrationMonth(editLocation?.RegMonth);
    SetFuelType(editLocation?.FuelType);
    setTransmission(editLocation?.Transmission);
    setOwnership(editLocation?.Ownership);
    setCity(editLocation?.City);
    setState(editLocation?.State?.Id);
    setNeighbourhood(editLocation?.Neighbourhood);
    setUserId(editLocation?.Id);
    setTypeDataId(editLocation?.Type);
  }, [editLocation]);

  // ------------------Getting user Location--------------------//
  const [location, setLocation] = useState({
    state: "",
    city: "",
    neighborhood: "",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }, []);

  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // use reverse geocoding API to get the address information
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const state = data.address.city_district;
        const city = data.address.city;
        const neighborhood = data.address.neighbourhood;

        setLocation({
          state: state,
          city: city,
          neighborhood: neighborhood,
        });
      })
      .catch((error) => {});
  };

  // --------Upload Image and compress it------------//

  const handleImageUpload = (event) => {
    setTrueImageDataVehicle("");
    setDummyDataImg("");
    const files = event?.target?.files;
    if (imagePreview?.length === 1 && event.target.files?.length === 1) {
      setDummyImageValue(true);
    } else if (event.target.files.length > 1) {
      setDummyImageValue(false);
    }
    if (event.target.files.length > 1) {
      setUserSelectedDefaultImageVehicle(true);
    }

    if (files.length === 0) {
      setIspageLoading(false);
      return;
    }
    const newFiles = [...selectedFiles, ...files];
    setIspageLoading(true);

    if (newFiles.length > 20) {
      toast.error("You can select up to 20  only.", {
        theme: "colored",
      });
      setIspageLoading(false);
      return;
    }

    setSelectedFiles(newFiles);
    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setImageName(file.name);

      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        100,
        0,
        (uri) => {
          newImages.push(uri);
          if (newImages.length === files.length) {
            setImages(newImages);
            uploadImages(newImages);
          }
        },
        "base64",
        0.1 // Set the maximum size in megabytes to 0.1
      );
    }
  };
  const handleImageSectionVehicle = (imageUrl) => {
    setTrueImageDataVehicle("");
    setSelectedImage(imageUrl);
    setUserSelectedDefaultImageVehicle(false);
  };

  // -----------Upload to Cloudinary and then fetch it--------//
  const uploadImages = async (images) => {
    let imgArray = [...imageSrc];
    let multipleImage = [...imagePreview];
    for (const image of images) {
      const body = new FormData();
      body.append("upload_preset", "z5yxtsvv");
      body.append("file", image);
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dmrw6iq4q/image/upload",
        {
          method: "POST",
          body,
        }
      ).then((r) => r.json());
      const myImage = cld.image(response.public_id);
      myImage
        .overlay(
          source(
            text("JTC Marketplace", new TextStyle("arial", 17))
              .textColor("#ffffff")
              .transformation(
                new Transformation()
                  .adjust(opacity(100))
                  .backgroundColor("#08080898")
                  .adjust(opacity(30))
                  .roundCorners(byRadius(10))
              )
          ).position(new Position().gravity(compass("center")).offsetY(5))
        )
        .format("png");
      const myUrl = myImage.toURL();
      imgArray.push(myUrl);
      multipleImage.push(myUrl);
      // --------------converting image into base 64--------------------------//
      const imageUrl = myUrl;
      // Get the image data using fetch
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Convert the image data to a base64 string
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            const base64String = reader.result;

            const modifiedBase64Content = base64String.replace(
              /^data:image\/png;base64,/,
              ""
            );

            // Update the image name and base64 content for this image
            const imageName = response.public_id;
            const imageFormat = response.format;
            const base64id = modifiedBase64Content;
            setBase64id(base64id);
            setImageFromat(imageFormat);
            // Add the image object to the state
            setImageSrc((prevState) => [
              ...prevState,
              { imageUrl, imageName, imageFormat, base64id },
            ]);
            setImagePreview(multipleImage);
            setOneImageTrueVehicle(multipleImage?.[0]);
          };
        });
    }
    setIspageLoading(false);
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dmrw6iq4q",
    },
  });

  // --------------delete uploaded Image--------------------------//
  const deleteItem = (e, index) => {
    const datan = imageSrc?.map((item) =>
      item?.imageId
        ? item?.imageId === e?.imageId
          ? { ...item, MarkDelete: "true" }
          : item
        : item
    );
    const newdata = datan?.filter((item) =>
      item?.imageId ? item : item?.imageName !== e?.imageName
    );
    const preview = newdata?.filter(
      (item) => item?.MarkDelete === false || item?.imageName?.length > 0
    );
    setImageSrc(newdata);
    setImagePreview(preview);
    setSelectedFiles(preview);
    setDummyTest(preview);
    if (
      e?.imageUrl === trueImageDataVehicle ||
      e?.imageUrl === selectedImage ||
      e === trueImageDataVehicle ||
      e === selectedImage
    ) {
      setUserSelectedDefaultImageVehicle(true);
    }
  };

  // -------------Brands Data--------------------------------------//

  const GetBrandsData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetBrands`,
    };

    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const brand = JSON.parse(e.data.data);
        setBrands(brand);
      })
      .catch((error) => {
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    GetBrandsData();
  }, []);
  // ------------------------Modals Data----------------------------------//

  const GetModalsData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetModels?parentId=${brandId}`,
    };

    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const modals = JSON.parse(e.data.data);
        setModals(modals);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    if (brandId?.length) {
      GetModalsData();
    }
  }, [brandId]);

  // ----------------Variants Data---------//
  const GetVariantsData = (e) => {
    const data = {};
    setIspageLoading(true);

    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/Getvariant?parentId=${modalId}`,
    };

    axios(option)
      .then((e) => {
        setIspageLoading(false);

        const variants = JSON.parse(e.data.data);
        setVariants(variants);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    if (modalId?.length) {
      GetVariantsData();
    }
  }, [modalId]);
  // get types data//---------
  const GetTypesData = (e) => {
    const data = "1";
    setIspageLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Master/GetTypes`,
    };

    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const typesData = JSON.parse(e.data.data);
        setTypesData(typesData);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    GetTypesData();
  }, []);

  // --------------
  const handleKmDrivenChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    value = new Intl.NumberFormat("en-IN").format(value); // add commas
    setKmDriven(value);
  };
  const handlePriceChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    value = new Intl.NumberFormat("en-IN").format(value); // add commas
    setPrice(value);
  };

  // --------------------Get Profile Data---------------//
  const GetProfileData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const ProfileData = JSON.parse(e.data.data);
        setProfile(ProfileData);
        if (ProfileData?.PackageDetails?.Enabled === false) {
          SellerCanAddStore();
          IsStoreAdded();
        }
      })
      .catch((error) => {
        setIspageLoading(false);

        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetProfileData();
  }, []);
  //-----------------storing data of vehicle images in array-------//
  const vehicleImages = imageSrc?.map((img) => {
    const filterUrl = dummyTest?.filter(
      (item) => item.imageUrl === img.imageUrl
    );
    const imageObj = {
      ImageExtenssion: img.imageFormat,
      Type: "Vehicle",
      FileName: img.imageName,
      DefaultImage:
        (imagePreview?.length === 1 && img.imageUrl === oneImagTrueVehicle) ||
        img.imageUrl === selectedImage ||
        img.imageUrl === trueImageDataVehicle ||
        img.imageUrl === dummyTest ||
        (dummyTest?.length === 1 && img.imageUrl === filterUrl?.[0]?.imageUrl),
      Base64Content: img.base64id,
    };

    if (img.imageId) {
      imageObj.Id = img.imageId;
    }

    if (img.MarkDelete !== undefined) {
      imageObj.MarkDelete = img.MarkDelete;
    }

    return imageObj;
  });

  // -----validation---------//
  const hasSelectedImage = vehicleImages.find(
    (item) => item.DefaultImage === true
  );
  const Validate = () => {
    if (!brandId) {
      toast.error("Please Select Brand", {
        theme: "colored",
      });
      return false;
    }
    if (!modalId) {
      toast.error("Please Select Model", {
        theme: "colored",
      });
      return false;
    }
    if (!variantsId) {
      toast.error("Please Select Variant", {
        theme: "colored",
      });
      return false;
    }
    if (!typeDataId) {
      toast.error("Please Select Type", {
        theme: "colored",
      });
      return false;
    }
    if (!registerationMonth) {
      toast.error("Please Select Registration Month.", {
        theme: "colored",
      });
      return false;
    }
    if (!registrationYear) {
      toast.error("Please Select Registration Year.", {
        theme: "colored",
      });
      return false;
    }
    if (!fuelType) {
      toast.error("Please Select Fuel Type", {
        theme: "colored",
      });
      return false;
    }
    if (!transmission) {
      toast.error("Please Select Transmission Type", {
        theme: "colored",
      });
      return false;
    }
    if (!kmDriven) {
      toast.error("Please enter KM Driven", {
        theme: "colored",
      });
      return false;
    }
    if (!ownership) {
      toast.error("Please Select No. of Owners", {
        theme: "colored",
      });
      return false;
    }
    if (!advertisementTitle) {
      toast.error("Please Fill Advertisement Title", {
        theme: "colored",
      });
      return false;
    }
    if (!description) {
      toast.error("Please Fill Description", {
        theme: "colored",
      });
      return false;
    }
    if (!price) {
      toast.error("Please Select Price", {
        theme: "colored",
      });
      return false;
    }
    if (imagePreview.length === 0) {
      toast.error("Please Select Images To Upload", {
        theme: "colored",
      });
      return false;
    }
    if (userSelectedDefaultImageVehicle === true) {
      toast.error("Please select a default image ", {
        theme: "colored",
      });
      return false;
    }
    if (!countryId) {
      toast.error("Please select country ", {
        theme: "colored",
      });
      return false;
    }
    if (countryId === "Select") {
      toast.error("Please select country", {
        theme: "colored",
      });
      return false;
    }
    if (!state) {
      toast.error("Please select state ", {
        theme: "colored",
      });
      return false;
    }
    if (!city) {
      toast.error("Please Enter City Name", {
        theme: "colored",
      });
      return false;
    }
    if (!neighbourhood) {
      toast.error("Please Enter Neighbourhood", {
        theme: "colored",
      });
      return false;
    }

    return true;
  };

  // ----------------post Vehicle data---------//

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!Validate()) return;
      setIspageLoading(true);
      const data = {};

      data.BrandId = parseInt(brandId);
      data.ModelId = parseInt(modalId);
      data.VariantId = parseInt(variantsId);
      data.TypeId = typeDataId;
      data.FuelType = fuelType;
      data.Transmission = transmission;
      data.Mileage = kmDriven;
      data.Ownership = ownership;
      data.AdvertisementTitle = advertisementTitle;
      data.Description = description;
      data.Price = price;
      data.RegMonth = registerationMonth;
      data.RegYear = parseInt(registrationYear);
      data.VehicleImages = vehicleImages;
      data.CountryId = countryId;
      data.StateId = state;
      data.City = city;
      data.Neighbourhood = neighbourhood;

      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },

        url: `${Apiurl()}/Seller/PostVehicleAd`,
      };

      const response = await axios(option);
      setIspageLoading(false);

      navigate("/dashboard");
      toast.success("Ad Submitted Successfully", {
        theme: "colored",
      });
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  // ---------update vehicle--------------//

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      if (!Validate()) return;
      setIspageLoading(true);
      const data = {};
      data.Id = JSON.stringify(userId);
      data.BrandId = parseInt(brandId);
      data.ModelId = parseInt(modalId);
      data.VariantId = parseInt(variantsId);
      data.TypeId = typeDataId;
      data.FuelType = fuelType;
      data.Transmission = transmission;
      data.Mileage = kmDriven;
      data.Ownership = ownership;
      data.AdvertisementTitle = advertisementTitle;
      data.Description = description;
      data.Price = price;
      data.RegMonth = registerationMonth;
      data.RegYear = registrationYear;
      data.VehicleImages = vehicleImages;
      data.CountryId = countryId;
      data.StateId = oldStateid;
      data.City = city;
      data.Neighbourhood = neighbourhood;
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },

        url: `${Apiurl()}/Seller/UpdateVehicleAd`,
      };

      const response = await axios(option);
      setIspageLoading(false);

      navigate("/dashboard");
      toast.success("Ad Submitted Successfully", {
        theme: "colored",
      });
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  const inputHandler = (e) => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();

      return;
    }
  };

  return (
    <div className="main-all-div-post">
      {isPageLoading ? <Loader /> : null}
      <div className="main_border postAdd-1">
        <div className="data_Category mt-3">
          <h2 className="text-center fw-bold mb-4">Post Your Ad</h2>
          <h3 className="selct_hding mb-4">Select Category</h3>
          <form>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Brand<span className="required">*</span>
              </label>
              <select
                name="brands"
                className="type_input"
                onChange={(e) => BrandFun(e)}
                value={brandId}
              >
                <option value="">Select a brand</option>
                {brands?.map((brand) => (
                  <option key={brand.Id} value={brand.Id}>
                    {brand.Name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="brnd_label mb-3">
                Model<span className="required">*</span>
              </label>
              <select
                name="brands"
                className="type_input"
                onChange={(e) => ModalFun(e)}
                value={modalId}
              >
                <option value="">Select a Model</option>
                {modals?.map((modal) => (
                  <option key={modal.Id} value={modal.Id}>
                    {modal.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Variant<span className="required">*</span>
              </label>
              <select
                name="brands"
                className="type_input"
                onChange={(e) => setVariantsId(e.target.value)}
                value={variantsId}
              >
                <option value="">Select a Variant</option>
                {variants?.map((variant) => (
                  <option key={variant.Id} value={variant.Id}>
                    {variant.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Type<span className="required">*</span>
              </label>
              <select
                name="brands"
                className="type_input"
                onChange={(e) => setTypeDataId(e.target.value)}
                value={typeDataId}
              >
                <option value="">Select a Type</option>
                {typeData?.map((typeData) => (
                  <option key={typeData.Id} value={typeData.Id}>
                    {typeData.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4 month-year-div">
              <div className="mt-5">
                <label className="brnd_label mb-3" htmlFor="month-select">
                  Registration Month<span className="required">*</span>
                </label>
                <select
                  className="type_input"
                  id="month-select"
                  name="month"
                  value={registerationMonth}
                  // selected={registerationMonth}
                  onChange={(e) => setRegistrationMonth(e.target.value)}
                >
                  <option defaultChecked>Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div className="mt-5 my-div">
                <label className="brnd_label mb-3">
                  Registration Year<span className="required">*</span>
                </label>
                <input
                  className="Registration-Year-input"
                  type="number"
                  name="year"
                  id="year"
                  min="1900"
                  max="2099"
                  placeholder="YYYY"
                  onKeyPress={inputHandler}
                  maxLength="4"
                  required
                  value={registrationYear}
                  onChange={(e) => setRegistrationYear(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-4 buttons">
              <label className="brnd_label mb-3">
                Fuel<span className="required">*</span>
              </label>
              <div className="fuel-input-select">
                <input
                  label="CNG & Hybrids"
                  type="radio"
                  name="gender"
                  value="CNG & Hybrids"
                  defaultChecked={editLocation?.FuelType === "CNG & Hybrids"}
                  onChange={(e) => SetFuelType(e.target.value)}
                />
                <input
                  label="Diesel"
                  type="radio"
                  name="gender"
                  value="Diesel"
                  defaultChecked={editLocation?.FuelType === "Diesel"}
                  onChange={(e) => SetFuelType(e.target.value)}
                />
                <input
                  label="Electric"
                  type="radio"
                  name="gender"
                  value="Electric"
                  defaultChecked={editLocation?.FuelType === "Electric"}
                  onChange={(e) => SetFuelType(e.target.value)}
                />
                <input
                  label="LPG"
                  type="radio"
                  name="gender"
                  value="LPG"
                  defaultChecked={editLocation?.FuelType === "LPG"}
                  onChange={(e) => SetFuelType(e.target.value)}
                />
                <input
                  label="Petrol"
                  type="radio"
                  name="gender"
                  value="Petrol"
                  defaultChecked={editLocation?.FuelType === "Petrol"}
                  onChange={(e) => SetFuelType(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-4 radio--buttons">
              <label className="brnd_label mt-4 mb-3">
                Transmission<span className="required">*</span>
              </label>
              <div className="select-transmisson">
                <input
                  label="Automatic"
                  type="radio"
                  name="Transmission"
                  value="Automatic"
                  defaultChecked={editLocation?.Transmission === "Automatic"}
                  onChange={(e) => setTransmission(e.target.value)}
                />
                <input
                  label="Manual"
                  type="radio"
                  name="Transmission"
                  value="Manual"
                  defaultChecked={editLocation?.Transmission === "Manual"}
                  onChange={(e) => setTransmission(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                KM driven<span className="required">*</span>
              </label>
              <input
                type="text"
                id="kmDriven"
                name="kmDriven"
                className="form-control type_input"
                value={kmDriven}
                onChange={handleKmDrivenChange}
              />
            </div>

            <div className="mb-4 radio---buttons">
              <label className="brnd_label mt-4 mb-3">
                No. of Owners<span className="required">*</span>
              </label>
              <div className="owner-select">
                <input
                  label=" 1st"
                  type="radio"
                  name="owners"
                  value="1"
                  defaultChecked={editLocation?.Ownership === "1"}
                  onChange={(e) => setOwnership(e.target.value)}
                />
                <input
                  label="2nd"
                  type="radio"
                  name="owners"
                  value="2"
                  defaultChecked={editLocation?.Ownership === "2"}
                  onChange={(e) => setOwnership(e.target.value)}
                />
                <input
                  label="3rd"
                  type="radio"
                  name="owners"
                  value="3"
                  defaultChecked={editLocation?.Ownership === "3"}
                  onChange={(e) => setOwnership(e.target.value)}
                />
                <input
                  label="4th"
                  type="radio"
                  name="owners"
                  value="4"
                  defaultChecked={editLocation?.Ownership === "4"}
                  onChange={(e) => setOwnership(e.target.value)}
                />
                <input
                  label="4++"
                  type="radio"
                  name="owners"
                  value="4++"
                  defaultChecked={editLocation?.Ownership === "4++"}
                  onChange={(e) => setOwnership(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="brnd_label mb-3">
                Ad title<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control type_input"
                required
                onChange={(e) => setAdvertisementTitle(e.target.value)}
                value={advertisementTitle}
              />
              <p className="mntion_key">
                Mention the key features of your item (e.g. brand, model, age,
                type)
              </p>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Ad Description<span className="required">*</span>
              </label>
              <textarea
                id="w3review"
                name="w3review"
                rows="7"
                cols="50"
                className="type_input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              <p className="mntion_key">
                Include condition, features and reason for selling
              </p>
            </div>
          </form>
        </div>

        <div className="data_Category mb-4">
          <h2 className="selct_hding">Set A Price</h2>
          <div className="mt-2">
            <label className="brnd_label mb-3">
              Price<span className="required">*</span>
            </label>
            <input
              type="text"
              id="kmDriven"
              name="kmDriven"
              className="form-control type_input"
              value={price}
              onChange={handlePriceChange}
            />
          </div>
        </div>

        <div className="data_Category mb-4">
          <h2 className="selct_hding">
            Upload up to 20 photos<span className="required">*</span>
          </h2>
          {!imagePreview?.length ? (
            <p className="mntion_key-image">Please Select at least 1 Image</p>
          ) : null}
          <div id="upload-image-grid">
            {imagePreview?.length > 0
              ? imagePreview?.map((img, i) => {
                  return (
                    <div className="outer-div-vehicle" key={i}>
                      <div id="image-radio-buttons">
                        <label htmlFor={i}>
                          <input
                            type="radio"
                            className="image-radio select-icons-image"
                            name="image"
                            id={i}
                            onChange={() =>
                              handleImageSectionVehicle(img.imageUrl || img)
                            }
                            checked={
                              selectedImage === (img.imageUrl || img) ||
                              (oneImagTrueVehicle &&
                                imagePreview?.length === 1) ||
                              img?.imageUrl === trueImageDataVehicle ||
                              (dummyTest?.length === 1 &&
                                img?.imageUrl === dummyTest?.[0]?.imageUrl)
                            }
                          />

                          <img
                            src={img.imageUrl || img}
                            alt={img}
                            id="upload-images"
                          />
                        </label>
                      </div>
                      <ImCross
                        onClick={() => deleteItem(img, i)}
                        className="delete-image-icon delete-image-icon-delete"
                      />
                    </div>
                  );
                })
              : ""}
          </div>
          <input
            type="file"
            multiple
            name="uploadfile"
            id="vehicleImg"
            className="input-field-image"
            onChange={handleImageUpload}
          />
          <label htmlFor="vehicleImg" className="label-upload-image">
            <AiOutlineCloudUpload className="upload-icon-image" />
            <h5 className="para-upload-image">browse to choose files</h5>
          </label>
          {/* <div className="mt-2">
            <div className="upload_stock">
              <ul>
                <li></li>
              </ul>
            </div>
          </div> */}
        </div>

        <div className="data_Category mb-4">
          <h2 className="selct_hding">Confirm Your Location</h2>

          <div className="mt-2">
            <div className="upload_tabs">
              <div className="tab-content mt-3" id="pills-tabContent">
                <Tabs
                  defaultActiveKey="List"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="List" title="List" className="postAdd_btn_1">
                    <List
                      editLocation={editLocation}
                      setCity={setCity}
                      city={city}
                      neighborhood={neighbourhood}
                      state={state}
                      setNeighbourhood={setNeighbourhood}
                      countryId={countryId}
                      setCountryId={setCountryId}
                      country={country}
                      setCountry={setCountry}
                      setState={setState}
                      oldStateid={oldStateid}
                    />
                  </Tab>
                  <Tab
                    eventKey="Location"
                    title="Location"
                    className="postAdd_btn_2"
                  >
                    <Location />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className="data_Category mb-4">
          <h2 className="selct_hding">Review Your Details</h2>
          <div className="review_data">
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control type_input type_width"
                required
                value={Profile.DisplayName}
                disabled
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="review_detail">
              <label className="brnd_label mb-3">
                Email<span className="required">*</span>
              </label>
              <input
                type="email"
                className="form-control type_input type_width"
                required
                value={Profile.UserEmail}
                disabled
              />
            </div>
          </div>
          <div className="data_Category mt-4">
            <div className="mt-4">
              {editLocation ? (
                <button onClick={handleUpdate} className="sbmit_btn_end">
                  Update
                </button>
              ) : (
                <button onClick={handleSubmit} className="sbmit_btn_end">
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;

import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import car1 from "../../Images/car1.png";
import "../../../src/App.css";

import Slider from "react-slick";
const ImageSlicker = ({ singleAdData }) => {
  const [singleVehicleImages, setSingleVehicleImage] = useState();

  useEffect(() => {
    const singleVehicleData = setSingleVehicleImage(
      singleAdData?.VehicleImages || singleAdData?.Images ||
      singleAdData?.PartImages ||
      singleAdData?.AccesoryImages
    );
  }, [singleAdData]);
  const googleLoginData = JSON.parse(localStorage.getItem("LoginData"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="home-demo">
        <h1 className="detals_heading">Details</h1>

        <Slider {...settings}>
          {singleVehicleImages?.map((item,index) => {
            return (
              <div key={index}>
                {item.Type === "Vehicle" ? (
                  <img
                    className="d-block w-100 image-slick-main"
                    src={item.UrlLink}
                    alt="First slide"
                  />
                ) : null}
                {item.Type === "Parts" ? (
                  <img
                    className="d-block w-100 image-slick-parts"
                    src={item.UrlLink}
                    alt="First slide"
                  />
                ) : null}
                {item.Type === "Accessories" ? (
                  <img
                    className="d-block w-100 image-slick-accesories"
                    src={item.UrlLink}
                    alt="First slide"
                  />
                ) : null}
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default ImageSlicker;

import React, { useEffect, useState } from "react";
import Footer from "../../Components/Common/Footer";
import "../../../src/App.css";
import Header from "../../Components/Common/Header";
import Loader from "../../Components/loader/loader";
import axios from "axios";
import {
  ActiveStore,
  Apiurl,
  GetActiveStore,
  IsStoreAdded,
  RemoveSession,
  SellerCanAddStore,
  UserId,
  sellerId,
} from "../../Utils/Utils";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";

const Profile = () => {
  const [isPageLoading, setIspageLoading] = useState(false);
  const [Profile, setProfile] = useState([]);
  const [daysLeft, setDaysLeft] = useState(null);
  const PlanEndDate = Profile?.PackageDetails?.EndDate;
  const [allPackageData, setAllPackageData] = useState();
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [packageData, setPackageData] = useState();
  const navigate = useNavigate();
  const [addAllowStore, setAddAllowStore] = useState();
  const [profileTrueFalse, setProfileTrueFalse] = useState();
  const ActivePackage = packageData?.filter(
    (item) => item?.Id === Profile?.PackageDetails?.PurchasedPackage?.Id
  );
  const activePackageAddtoStore = ActivePackage?.find(
    (item) => item?.AllowToAddStore === true
  );

  useEffect(() => {
    if (ActivePackage) {
      ActiveStore(activePackageAddtoStore?.AllowToAddStore);
      // const DataSetInLocal= localStorage.setItem("activePlan",activePackageAddtoStore?.AllowToAddStore);
    }
  }, [ActivePackage]);
  useEffect(() => {
    if (ActivePackage) {
      const getStoreTrueFalse = GetActiveStore();
      setProfileTrueFalse(getStoreTrueFalse);
    }
  }, [ActivePackage]);
  const googleLoginData = JSON.parse(localStorage.getItem("LoginData"));
  const GetProfileData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const ProfileData = JSON.parse(e.data.data);
        setProfile(ProfileData);
        IsStoreAdded(ProfileData?.SellerHasStore);
        sellerId(ProfileData?.SellerStore?.SellerId);
        SellerCanAddStore(
          ProfileData?.PackageDetails?.PurchasedPackage?.AllowToAddStore
        );
        if (ProfileData?.PackageDetails?.Enabled === false) {
          SellerCanAddStore();
          IsStoreAdded();
        }

        UserId(ProfileData?.Id);
      })
      .catch((error) => {
        setIspageLoading(false);
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetProfileData();
  }, []);

  useEffect(() => {
    if (PlanEndDate) {
      const planEndDate = new Date(PlanEndDate);
      const currentDate = new Date();
      const timeDifference = planEndDate - currentDate;
      const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      setDaysLeft(remainingDays);
    }
  }, [Profile, PlanEndDate]);
  const GetPackage = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Package/GetPackageList`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const data = JSON.parse(e.data.data);
        const allData = JSON.parse(data?.Data);
        setPackageData(allData);
      })
      .catch((error) => {
        setIspageLoading(false);
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetPackage();
  }, []);
  const PackageSelected = (item) => {
    const data = {};
    data.PackageId = item?.Id;
    data.BuyerCustomerId = item?.CreateByUser?.Id;
    data.StartDate = "";
    data.EndDate = "";
    data.PurchaseAmount = item?.Price;
    setIspageLoading(true);
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Package/UpgradePackage`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        // setSelectedPackagePlan(e)
        toast.success(e?.data?.message, {
          theme: "colored",
        });
        setAddAllowStore(item.AllowToAddStore);
        GetProfileData();
      })
      .catch((error) => {
        setIspageLoading(false);
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };

  const sortedData = packageData
    ?.sort((a, b) => a?.Price - b?.Price)
    .sort((a, b) => {
      if (a.Id === ActivePackage?.[0]?.Id) {
        return -1;
      } else if (b.Id === ActivePackage?.[0]?.Id) {
        return 1;
      } else {
        return 0;
      }
    });

  return (
    <div className="profile-head">
      {isPageLoading ? <Loader /> : null}
      <main className="main_wrapper">
        <Header
          activePackageAddtoStore={activePackageAddtoStore}
          addAllowStore={addAllowStore}
        />
        <section className="profile_page">
          <div className="container">
            <div className="profile_wraper">
              <div className="row">
                <div className="col-lg-3 col-xl-3 align-items-center d-flex">
                  <ul>
                    <li>
                      <img
                        referrerPolicy="no-referrer"
                        src={Profile?.Photo}
                        alt="Profile Photo"
                        width="120px"
                        className="profile-photo"
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-lg-9 col-xl-9 align-items-center d-flex name-email-div">
                  <ul>
                    <li>
                      <b>Name :</b> {Profile?.DisplayName}
                    </li>
                    <li>
                      <b>Email :</b> {Profile?.UserEmail}
                    </li>
                    <li className="profile_package_li">
                      <b>Customer Plan :</b>
                      {Profile?.PackageDetails ? (
                        <>
                          <p className="Profile_package_plan_head">
                            {Profile?.PackageDetails?.Enabled === true ? (
                              <>
                                {Profile?.PackageDetails?.PurchasedPackage
                                  ?.Name || Profile?.PackageDetails?.Name}
                              </>
                            ) : (
                              <>
                                <div className="expire_div">
                                  <div>
                                    {
                                      Profile?.PackageDetails?.PurchasedPackage
                                        ?.Name
                                    }
                                  </div>
                                  <div>
                                    (was valid for{" "}
                                    {
                                      Profile?.PackageDetails?.PurchasedPackage
                                        ?.ValidityInDays
                                    }
                                    days)
                                  </div>
                                  <div className="expired_value">
                                    (<p>Plan expired</p>)
                                  </div>
                                </div>
                              </>
                            )}
                            {/* {Profile?.PackageDetails?.PurchasedPackage?.Name ||
                            Profile?.PackageDetails?.Name || (Profile?.PackageDetails?.Enabled===false?  : null)} */}
                          </p>
                          <p className="Profile_package_plan_head">
                            {Profile?.PackageDetails?.Enabled === true &&
                            Profile?.PackageDetails?.PurchasedPackage?.Name !=
                              "Free Plan" ? (
                              <>
                                (Plan expires in {daysLeft} days) 
                                ({new Date(
                                  new Date().getTime() +
                                    daysLeft * 24 * 60 * 60 * 1000
                                ).toLocaleDateString("en-GB")})
                              </>
                            ) : null}
                          </p>
                        </>
                      ) : null}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* { activePackageAddtoStore || addAllowStore === true   ? <>
              <button onClick={handleStore}>
                store
              </button>
            </> : null} */}
            <div className="package-heading">
              <h1>Package Plans</h1>
            </div>
            <div className="newdiv">
              {sortedData?.map((item, index) => (
                <div className="package-detail-1" key={index}>
                  <div className="package-detail-11">
                    <div className="package-details-inner-div">
                      <p
                        className={
                          item?.Id === ActivePackage?.[0]?.Id &&
                          Profile?.PackageDetails?.Enabled === true
                            ? "package-selected-name"
                            : "package-name"
                        }
                      >
                        {item.Name.toUpperCase()}
                      </p>
                      <p
                        className={
                          item?.Id === ActivePackage?.[0]?.Id &&
                          Profile?.PackageDetails?.Enabled === true
                            ? "package-selected-Price"
                            : "package-Price"
                        }
                      >
                        ${item?.Price}
                      </p>
                      <p className="package-description">
                        {" "}
                        <BsCheckLg className="package-check" /> Package Validity
                        : {item?.ValidityInDays}
                      </p>
                      <p className="package-description">
                        {" "}
                        <BsCheckLg className="package-check" /> Package Detail:{" "}
                        {item?.Detail}
                      </p>
                      <p className="package-description">
                        {" "}
                        <BsCheckLg className="package-check" /> Number Of Ads
                        Allowed:{" "}
                        {item?.UnlimitedAds === true ? (
                          <> Unlimited Ads</>
                        ) : (
                          <>{item?.AllowNoOfAds}</>
                        )}
                      </p>
                      <p className="package-description">
                        {item.AllowToAddStore === true ? (
                          <>
                            <BsCheckLg className="package-check" /> Can Add Your
                            Store
                          </>
                        ) : null}
                      </p>
                      <div className="package-butt">
                        {item?.Price === 0 ||
                        item?.Id === ActivePackage?.[0]?.Id ? (
                          ""
                        ) : (
                          <button onClick={() => PackageSelected(item)}>
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
};
export default Profile;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/loader/loader";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import quality from "../../Images/quality.png";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import { toast } from "react-toastify";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
import axios from "axios";
import Select from "react-select";

const Favourite = () => {
  const state = useLocation();
  const [favouriteData, setFavouriteData] = useState();
  const [isPageLoading, setIspageLoading] = useState(false);
  const [filterFavData, setFilterFavData] = useState();
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));

  const navigate = useNavigate();

  const handleHeartClicked = (vehicleId, AdsCategory, e) => {
    setIspageLoading(true);

    const data = {};
    data.Id = vehicleId;
    data.AdsCategory = AdsCategory;
    data.IsFavorite = e === "liked" ? false : true;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Customer/Favorite-UnFavorite`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
        GetFavouriteData();
      })
      .catch((error) => {
        setIspageLoading(false);

        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };

  const GetFavouriteData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Customer/MyFavoriteList`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        setFilterFavData(JSON.parse(e.data.data));
      })
      .catch((error) => {
        setIspageLoading(false);
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetFavouriteData();
  }, []);

  const vehicleSingleItem = (item) => {
    let productName = "";
    if (item?.AdsDetail?.Images?.[0]?.VehicleId) {
      productName = item.AdsDetail.AdvertisementTitle?.toLowerCase().replace(
        /\s/g,
        "-"
      );
    }
    if (item?.AdsDetail?.Images?.[0]?.PartId) {
      productName = item.AdsDetail.Name?.toLowerCase().replace(/\s/g, "-");
    }
    if (item?.AdsDetail?.Images?.[0]?.AccessoryId) {
      productName = item.AdsDetail.Name?.toLowerCase().replace(/\s/g, "-");
    }
    const GetVehicleId = item?.AdsDetail?.Id;

    let adsCategoryName;
    if (item?.AdsDetail?.AdsCategory == 2) {
      adsCategoryName = "Accessories";
    } else if (item?.AdsDetail?.AdsCategory == 1) {
      adsCategoryName = "Vehicle";
    } else if (item?.AdsDetail?.AdsCategory == 0) {
      adsCategoryName = "Parts";
    }
    navigate(`/detail/${adsCategoryName}/${GetVehicleId}/${productName}`, {
      state: item.AdsDetail,
    });
  };

  const sortedDataAds = filterFavData?.sort((a, b) => a.Price - b.Price);
  // -----------Pagination----------------
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    setBlogPosts(sortedDataAds);
  }, [sortedDataAds]);

  const [currentPage, setCurrentPage] = useState(1);
  const [googleTokenId, setGoogleTokenId] = useState("");
  const [dummy, setDummy] = useState(false);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [postsPerPage, setPostPerPage] = useState(12);
  const totalPosts = blogPosts?.length;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts?.slice(indexOfFirstPost, indexOfLastPost);
  const lastPage = Math.ceil(blogPosts?.length / postsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(blogPosts?.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const options = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
  ];
  const resultPerPage = (selectedOption) => {
    setPostPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const startResult = Math.min(indexOfFirstPost, totalPosts);
  const endResult = Math.min(indexOfLastPost, totalPosts);

  return (
    <div className="fav-main-div">
      <main className="main_wrapper">
        <Header />
        <section className="filer_heading">
          <div className="container">
            <section className="colsm_detil favourite-section">
              <div className="row">
                {isPageLoading ? <Loader /> : null}
                {currentPosts?.length > 0 ? (
                  <>
                    {currentPosts?.map((item, index) => {
                      const imageMain = item?.AdsDetail?.Images?.find(
                        (i) => i?.DefaultImage
                      );
                      const VehicleId = item?.AdsDetail?.Id;
                      const value = item?.AdsDetail?.Price;
                      const AdsCategory = item?.AdsDetail?.AdsCategory;
                      const valueprice = new Intl.NumberFormat("en-IN").format(
                        value
                      );
                      return (
                        <div key={index} className="col-sm-4">
                          <div className="cars_deal cars-fav-deal">
                            <a>
                              <img
                                src={imageMain?.UrlLink}
                                alt="Cars"
                                className="imgclassName"
                                onClick={(e) => vehicleSingleItem(item)}
                              />
                            </a>
                            <a className="likes_btn">
                              <AiFillHeart
                                onClick={() =>
                                  handleHeartClicked(
                                    VehicleId,
                                    AdsCategory,
                                    "liked"
                                  )
                                }
                              />
                            </a>
                            <div className="cars_deal_wrap">
                              <div className=" mb-2 heart-name">
                                <h5
                                  className="paragraph-detail"
                                  onClick={(e) => vehicleSingleItem(item)}
                                >
                                  {item?.AdsDetail?.AdsCategory == 1 ? (
                                    <>{item?.AdsDetail?.AdvertisementTitle}</>
                                  ) : item?.AdsDetail?.AdsCategory == 0 ? (
                                    <>{item?.AdsDetail?.Name}</>
                                  ) : (
                                    <>{item?.AdsDetail?.Name}</>
                                  )}
                                </h5>
                              </div>
                              {item.VehicleId ? (
                                <div className="mb-2">
                                  <p className="paragraph-detail-vehicle">
                                    Km Driven : {item?.AdsDetail?.Mileage}kms{" "}
                                    <br />
                                    Fuel Type : {item?.AdsDetail?.FuelType}{" "}
                                    <br /> Transmission :{" "}
                                    {item?.AdsDetail?.Transmission}
                                  </p>
                                </div>
                              ) : null}
                              <div className="mb-2">
                                <p className="paragraph-detail">
                                  {item?.AdsDetail?.Description}
                                </p>
                              </div>
                              <h4>
                                ${valueprice} {item?.AdsDetail?.PriceType}
                                {/* {item.PartId?
                                    <>
                                     (
                                      {item?.AdsDetail?.PriceType})
                                    </>: null} */}
                              </h4>
                              {/* <span>
                                    <img src={quality} alt="Quality" />
                                    Assured
                                  </span>
                                  <span className="regular_off mt-2">
                                    $2% Regular Off
                                  </span> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <h3>You haven't liked any product yet</h3>
                )}
                {currentPosts?.length ? (
                  <>
                    <nav
                      aria-label="Page navigation example "
                      className="pagination-botttom"
                    >
                      <div className="showing-para-pagination">
                        <p>
                          Showing {startResult + 1} - {endResult} of{" "}
                          {totalPosts} results
                        </p>
                      </div>
                      <div>
                        <ul className="pagination result-pagination">
                          <li className="page-item">
                            <button
                              disabled={currentPage === 1 ? true : false}
                              className={`page-link ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              onClick={previousPage}
                            >
                              Previous
                            </button>
                          </li>
                          {pageNumbers.map((number) => (
                            <li
                              key={number}
                              onClick={() => paginate(number)}
                              className={
                                "page-item" +
                                (number === currentPage ? "active" : "")
                              }
                            >
                              <a className="page-link">{number}</a>
                            </li>
                          ))}

                          <li className="page-item">
                            <button
                              disabled={currentPage === lastPage ? true : false}
                              className={`page-link ${
                                currentPage === lastPage ? "disabled" : ""
                              }`}
                              onClick={nextPage}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="result-per-page-div">
                        <p>Results Per Page</p>
                        <Select
                          options={options}
                          onChange={resultPerPage}
                          placeholder="Per Page"
                          menuPosition="fixed"
                          menuPlacement="top"
                        />
                      </div>
                    </nav>
                  </>
                ) : null}
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
};

export default Favourite;

import React, { useEffect, useState } from "react";
import home from "../../Images/home.png";
import star from "../../Images/star.png";
import zero from "../../Images/zero.png";
import rsValue from "../../Images/rsValue.png";
import step from "../../Images/7step.png";
import quality from "../../Images/quality.png";
import "../../../src/App.css";
import { Button, Modal } from "react-bootstrap";
import cross from "../../Images/cross.png";
import googleicon from "../../Images/google-icon.png";
import arrowDown from "../../Images/arrow-down.png";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import {
  Apiurl,
  IsStoreAdded,
  RemoveSession,
  UserId,
  getSellerId,
  sellerId,
  setExistData,
} from "../../Utils/Utils";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import Loader from "../loader/loader";

const DetailTab = ({
  singleAdData,
  adSourceCountryId,
  setSourceCountryId,
  sourceCountryId,
  country,
  setCountry,
  adsCategory,
  setDummyValueLogin,
  DummyValueLogin,

}) => {
  const navigate = useNavigate();
  const getSellerStore = getSellerId();

  const singleVehicleData = singleAdData;

  const [destinationCountryId, setDestinationCountryId] = useState();
  const [sourceCountry, setSourceCountry] = useState();
  const [deliveryCharges, setDeliveryCharges] = useState();
  const [delivery, setDelivery] = useState();
  const [destinationCountry, setDestinationCountry] = useState();
  const [existingValue, setExistingValue] = useState();

  const [isPageLoading, setIspageLoading] = useState(false);
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showChatPopup, setShowChatPopup] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);
  const handleShowChatMessage = () => setShowChatPopup(true);
  const handleCloseChatPopup = () => setShowChatPopup(false);
  const [googleTokenId, setGoogleTokenId] = useState("");
  const [chatMessage, setChatMessage] = useState();
  const getShippingCharge = JSON.parse(localStorage.getItem("DeliveryCharge"));
  const getSource = localStorage.getItem("sourceCountry");
  const getCurrentEmail = JSON.parse(localStorage.getItem("LoginData"));
  const [buttonColor, setButtonColor] = useState("secondary");

  useEffect(() => {
    setDestinationCountryId(adSourceCountryId);
    setSourceCountryId(sourceCountryId || adSourceCountryId);
  }, [sourceCountryId, adSourceCountryId]);

  useEffect(() => {
    const filterDataCharges = getShippingCharge?.filter(
      (item) =>
        item.DestinationCountryId == destinationCountryId &&
        item.SourceCountryId == sourceCountryId
    );

    setDeliveryCharges(filterDataCharges);
  }, [sourceCountryId, destinationCountryId]);

  useEffect(() => {
    if (deliveryCharges) {
      const deliveryShipment =
        deliveryCharges[0]?.ShippingCharge + deliveryCharges[0]?.DeliveryCharge;
      setDelivery(+deliveryShipment);
    }
  }, [deliveryCharges]);
  const GetCountryStateData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetCountries`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const CountryData = JSON.parse(e.data.data);
        setCountry(CountryData);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    GetCountryStateData();
  }, []);
  const handleCountry = (e) => {
    setDestinationCountryId(e.target.value);
    const countryValue = e.target.value;
  };
  const value = singleVehicleData?.Price;
  const valueprice = new Intl.NumberFormat("en-IN").format(value);
  const formattedTotalCost = new Intl.NumberFormat("en-IN").format(
    value + delivery
  );
  //--------- signinwithgoogle--------------//
  const onSuccess = (credentialResponse) => {
    const decoded = jwt_decode(credentialResponse.credential);
    localStorage.setItem("LoginData", JSON.stringify(decoded));
    const googleToken = credentialResponse.credential;
    setGoogleTokenId(googleToken);

    setShow(false);
  };

  const onError = () => { };
  const loginData = async () => {
    handleClose2();

    try {
      setIspageLoading(true);
      const data = { tokenid: googleTokenId };
      const obj = { data: JSON.stringify(data) };
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
        },
        data: JSON.stringify(obj),
        url: `${Apiurl()}/Account/SignInWithGoogle`,
      };
      const response = await axios(option);
      setIspageLoading(false);
      const tokenId = response.data.data;
      setDummyValueLogin(tokenId);

      localStorage.setItem("tokenID", JSON.stringify(tokenId));

      if (
        singleVehicleData?.Seller?.UserEmail != getCurrentEmail?.email &&
        existingValue?.ParentChatId
      ) {
        handleShowChatMessage();
      }
    } catch (error) {
      setIspageLoading(false);
      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    if (googleTokenId) {
      loginData();
    }
  }, [googleTokenId]);
  const handlechat = () => {
    if (!tokenid) {
      handleShow2();
    } else {
      if (existingValue?.ParentChatId && existingValue?.Deleted === false) {
        setExistData(JSON.stringify(existingValue));
     
        navigate("/ChatWithSeller", {
          
          state: {
            AdsCategory: adsCategory,
            VehicleId: singleVehicleData?.Id,
            existChat: existingValue,
          },
        });
      } else {
        handleShowChatMessage();
      }
    }
  };

  const handleMyStore = () => {
    const Mystore = singleVehicleData?.Seller?.SellerStore?.StoreName;

    navigate(`/store/${Mystore.replace(/ /g, "-")}`, {
      state: {
        storeName: Mystore.replace(/ /g, "-"),
      },
    });
  };

  const messageValidation = () => {
    if (!chatMessage) {
      toast.error("message should not be empty", {
        theme: "colored",
      });
      return false;
    }
    return true;
  };
  const SendMessage = (e) => {
    if (!messageValidation()) return;
    setIspageLoading(true);
    const data = {};
    data.MessageBody = chatMessage;
    data.ParentChatId = existingValue?.ParentChatId;

    data.ToId = singleVehicleData?.Seller?.Id;
    if (singleVehicleData?.VehicleImages) {
      data.VehicleId = singleVehicleData?.Id;
    }
    if (singleVehicleData?.PartImages) {
      data.PartId = singleVehicleData?.Id;
    }
    if (singleVehicleData?.AccesoryImages) {
      data.AccessoryId = singleVehicleData?.Id;
    }
    data.Type = adsCategory;
    data.MessageDate = new Date();

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/CustomerChat/SendChatMesssage`,
    };
    axios(option)
      .then((e) => {
        const parentIdOfMsg = JSON.parse(e?.data?.data);
        setExistData(JSON.stringify(parentIdOfMsg));

        setIspageLoading(false);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
        navigate("/ChatWithSeller", {
          state: {
            AdsCategory: adsCategory,
            VehicleData: singleVehicleData,
            messageValue: e?.data?.message,
            myData: parentIdOfMsg,
            existChat: existingValue,

          },
        });
      })
      .catch((error) => {
        setIspageLoading(false);
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };

  // ----------Existing chat api---------
  const ExistingChat = () => {
    const data = {};
    data.ToId = singleVehicleData?.Seller?.Id;
    if (singleVehicleData?.VehicleImages) {
      data.VehicleId = singleVehicleData?.Id;
    }
    if (singleVehicleData?.PartImages) {
      data.PartId = singleVehicleData?.Id;
    }
    if (singleVehicleData?.AccesoryImages) {
      data.AccessoryId = singleVehicleData?.Id;
    }
    data.Type = adsCategory;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/CustomerChat/ChatExistsWithSeller`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
        const existChat = JSON.parse(e?.data?.data);
        setExistingValue(existChat);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    if (singleVehicleData || DummyValueLogin) {
      ExistingChat();
    }
  }, [singleVehicleData, DummyValueLogin]);

  useEffect(() => {
    if (
      singleVehicleData?.Seller?.UserEmail != getCurrentEmail?.email &&
      existingValue?.ParentChatId === null &&
      DummyValueLogin
    ) {
      handleShowChatMessage();
    } else if (existingValue?.ParentChatId && DummyValueLogin) {
      if (existingValue?.ParentChatId) {
        setExistData(JSON.stringify(existingValue));
      
        setExistData(existingValue);
        navigate("/ChatWithSeller", {
          state: {
            AdsCategory: adsCategory,
            VehicleId: singleVehicleData?.Id,
            existChat: existingValue,
          },
        });
      }
    }
  }, [existingValue, DummyValueLogin]);

  const handleChange = (e) => {
    setChatMessage(e.target.value);
    setButtonColor(e.target.value.trim() !== "" ? "primary" : "secondary");
  };
  return (
    <>
      {isPageLoading ? <Loader /> : null}

      {singleVehicleData?.VehicleImages ||
        singleVehicleData?.Images?.[0].VehicleId ? (
        <>
          <div className="col-sm-5">
            <div className="main_details">
              <div className="detail_cars_cvt">
                <div className="mb-3">
                  <h1 className="cvt_heding">
                    {singleVehicleData?.RegYear}{" "}
                    {singleVehicleData?.AdvertisementTitle?.toUpperCase()}
                  </h1>
                </div>
                <div className="mb-3 ">
                  <p className="overview-name-data">
                    {singleVehicleData?.Description || "----"}
                  </p>
                </div>

                <div className="mb-3 term_cond_div">
                  <h2 className="detail_view_benifit">${valueprice}</h2>
                  <a className="benefit_modal" onClick={handleShow}>
                    View Benefits & Breakup
                  </a>
                </div>
                <div className="mb-3 label-country-div">
                  <label className="country-label">
                    Select Your Delivery Country
                    <span className="required">*</span>
                  </label>
                  <select
                    name="cars"
                    className="countryType"
                    value={destinationCountryId}
                    onChange={(e) => {
                      handleCountry(e);
                    }}
                  >
                    <option value="0">Select a Country</option>
                    {country?.map((country) => (
                      <option key={country.Id} value={country.Id}>
                        {country.Name}
                      </option>
                    ))}
                  </select>

                  {/* <a className="detail_regular_price">Rs 21 Regular Off</a> */}
                  <div className=" price-main-div">
                    <div className="shipping-div">
                      <div className="price-main-div-head">
                        <h5>Delivery Charges:</h5>
                      </div>
                      <div className="price-detail-main">
                        <div className="price-over-div">
                          <h6 className="heading-price-money">
                            Vehicle Price :{" "}
                          </h6>
                          <h6> ${valueprice} </h6>
                        </div>
                        <div className="price-over-div">
                          <h6 className="heading-price-money">
                            Shipment+Delivery Cost :
                          </h6>
                          {deliveryCharges?.length ? (
                            <>
                              <h6>${delivery}</h6>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="price-over-div">
                          <h6 className="heading-price-money">Total Cost :</h6>
                          <h6>
                            {" "}
                            {deliveryCharges?.length ? (
                              <>${formattedTotalCost}</>
                            ) : (
                              <>${valueprice}</>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {singleVehicleData?.Seller?.UserEmail !=
                  getCurrentEmail?.email && singleVehicleData?.Status === 1 ? (
                  <div className="mb-3 price_home_test">
                    <a className="chat_with" onClick={handlechat}>
                      Chat With Seller
                    </a>
                  </div>
                ) : null}

                {singleVehicleData?.Seller?.SellerStore ? (
                  <>
                    <a
                      href=""
                      onClick={handleMyStore}
                      className="visit-store-link"
                    >
                      Visit seller store
                    </a>
                  </>
                ) : null}
                {singleVehicleData?.Status === 0 ? (
                  <>
                    <p className="Ad_del_stat">Ad is inactive!</p>
                  </>
                ) : singleVehicleData?.Status === 2 ? (
                  <>
                    <p className="Ad_del_stat">Ad is sold!</p>
                  </>
                ) : singleVehicleData?.Status === 4 ? (
                  <>
                    <p className="Ad_del_stat">Ad is expired!</p>
                  </>
                ) : null}
              </div>

              {/* <div className="detail_bottom text-center p-3">
                <p className="mb-0">
                  <i className="fa fa-"></i>
                  <b>Trending Car!</b> High chances of sale in next 6 days
                </p>
              </div> */}
            </div>
          </div>
        </>
      ) : null}
      {singleVehicleData?.PartImages ||
        singleVehicleData?.Images?.[0].PartId ? (
        <>
          <div className="col-sm-5">
            <div className="main_details">
              <div className="detail_cars_cvt">
                <div className="mb-3">
                  <h1 className="cvt_heding">
                    {singleVehicleData?.PartName?.toUpperCase() ||
                      singleVehicleData?.Name?.toUpperCase()}
                    ({singleVehicleData.Brand})
                    {/* <span>
                    <img src={quality} />
                    Assured
                  </span> */}
                  </h1>
                </div>
                <div className="mb-3 ">
                  <p className="overview-name-data">
                    {singleVehicleData?.Description || "----"}
                  </p>
                </div>
                <div className="mb-3">
                  <h2 className="detail_view_benifit">
                    ${singleVehicleData?.Price}{" "}
                    {singleVehicleData.PriceType ? (
                      <> ({singleVehicleData.PriceType})</>
                    ) : null}
                  </h2>
                  <a className="benefit_modal" onClick={handleShow}>
                    View Benefits & Breakup
                  </a>
                </div>

                {/* <a className="detail_regular_price">Rs 21 Regular Off</a> */}

                <div className="mb-3 label-country-div">
                  <label className="country-label">
                    Select Your Delivery Country
                    <span className="required">*</span>
                  </label>
                  <select
                    name="cars"
                    className="countryType"
                    value={destinationCountryId}
                    onChange={(e) => {
                      handleCountry(e);
                    }}
                  >
                    <option value="0">Select a Country</option>
                    {country?.map((country) => (
                      <option key={country.Id} value={country.Id}>
                        {country.Name}
                      </option>
                    ))}
                  </select>
                  <div className=" price-main-div">
                    <div className="shipping-div">
                      <div className="price-main-div-head">
                        <h5>Delivery Charges:</h5>
                      </div>
                      <div className="price-detail-main">
                        <div className="price-over-div">
                          <h6 className="heading-price-money">Part Price : </h6>
                          <h6> ${singleVehicleData?.Price} </h6>
                        </div>
                        <div className="price-over-div">
                          <h6 className="heading-price-money">
                            Shipment+Delivery Cost :
                          </h6>
                          {deliveryCharges?.length ? (
                            <>
                              <h6>${delivery}</h6>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div className="price-over-div">
                          <h6 className="heading-price-money">Total Cost :</h6>
                          <h6>
                            {" "}
                            {deliveryCharges?.length ? (
                              <>${formattedTotalCost}</>
                            ) : (
                              <>${valueprice}</>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {singleVehicleData?.Seller?.UserEmail !=
                  getCurrentEmail?.email && singleVehicleData?.Status == 1 ? (
                  <div className="mb-3 price_home_test">
                    <a className="chat_with" onClick={handlechat}>
                      Chat With Seller
                    </a>
                  </div>
                ) : null}
                {singleVehicleData?.Seller?.SellerStore ? (
                  <>
                    <a
                      href=""
                      onClick={handleMyStore}
                      className="visit-store-link"
                    >
                      Visit seller store
                    </a>
                  </>
                ) : null}
                {singleVehicleData?.Status === 0 ? (
                  <>
                    <p className="Ad_del_stat">Ad is inactive!</p>
                  </>
                ) : singleVehicleData?.Status === 2 ? (
                  <>
                    <p className="Ad_del_stat">Ad is sold!</p>
                  </>
                ) : singleVehicleData?.Status === 4 ? (
                  <>
                    <p className="Ad_del_stat">Ad is expired!</p>
                  </>
                ) : null}
                {/* <div className="price_home_test">
                  <ul>
                    <li>
                      <a>
                        <img src={star} alt="star" /> <b>9.5 </b>Inspection
                      </a>
                    </li>
                  </ul>
                <Link to="/ChatWithSeller" className="chat_with">Chat With Seller</Link>
                </div> */}
              </div>
              {/* <div className="detail_bottom text-center p-3">
                <p className="mb-0">
                  <i className="fa fa-"></i>
                  <b>Trending Car!</b> High chances of sale in next 6 days
                </p>
              </div> */}
            </div>
          </div>
        </>
      ) : null}
      {singleVehicleData?.AccesoryImages ||
        singleVehicleData?.Images?.[0].AccessoryId ? (
        <>
          <div className="col-sm-5">
            <div className="main_details">
              <div className="detail_cars_cvt">
                <div className="mb-3">
                  <h1 className="cvt_heding">
                    {singleVehicleData?.Name?.toUpperCase() ||
                      singleVehicleData?.AdvertisementTitle?.toUpperCase()}
                    {/* <span>
                    <img src={quality} />
                    Assured
                  </span> */}
                  </h1>
                </div>
                <div className="mb-3 ">
                  <p className="overview-name-data">
                    {singleVehicleData?.Description || "----"}
                  </p>
                </div>
                <div className="mb-3 view_term">
                  <h2 className="detail_view_benifit">
                    ${singleVehicleData?.Price}{" "}
                    {singleVehicleData.PriceType ? (
                      <> ({singleVehicleData.PriceType})</>
                    ) : null}
                  </h2>
                  <a className="benefit_modal" onClick={handleShow}>
                    View Benefits & Breakup
                  </a>
                </div>

                {/* <a className="detail_regular_price">Rs 21 Regular Off</a> */}
                <div className="mb-3 label-country-div">
                  <label className="country-label">
                    Select Your Delivery Country
                    <span className="required">*</span>
                  </label>
                  <select
                    name="cars"
                    className="countryType"
                    value={destinationCountryId}
                    onChange={(e) => {
                      handleCountry(e);
                    }}
                  >
                    <option value="0">Select a Country</option>
                    {country?.map((country) => (
                      <option key={country.Id} value={country.Id}>
                        {country.Name}
                      </option>
                    ))}
                  </select>
                  <div className="price-main-div">
                    <div className="shipping-div">
                      <div className="price-main-div-head">
                        <h5>Delivery Charges :-</h5>
                      </div>

                      <div className="price-detail-main">
                        <div className="price-over-div">
                          <h6 className="heading-price-money">
                            Accessory Price :{" "}
                          </h6>
                          <h6> ${singleVehicleData?.Price} </h6>
                        </div>
                        <div className="price-over-div">
                          <h6 className="heading-price-money">
                            Shipment+Delivery Cost :
                          </h6>
                          {deliveryCharges?.length ? (
                            <>
                              <h6>${delivery}</h6>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>

                        <div className="price-over-div">
                          <h6 className="heading-price-money">Total Cost :</h6>
                          <h6>
                            {" "}
                            {deliveryCharges?.length ? (
                              <>${formattedTotalCost}</>
                            ) : (
                              <>${valueprice}</>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {singleVehicleData?.Seller?.UserEmail !=
                  getCurrentEmail?.email && singleVehicleData?.Status === 1 ? (
                  <div className="mb-3 price_home_test">
                    <a className="chat_with" onClick={handlechat}>
                      Chat With Seller
                    </a>
                  </div>
                ) : null}
                {singleVehicleData?.Seller?.SellerStore ? (
                  <>
                    <a
                      href=""
                      onClick={handleMyStore}
                      className="visit-store-link"
                    >
                      Visit seller store
                    </a>
                  </>
                ) : null}
                {singleVehicleData?.Status === 0 ? (
                  <>
                    <p className="Ad_del_stat">Ad is inactive!</p>
                  </>
                ) : singleVehicleData?.Status === 2 ? (
                  <>
                    <p className="Ad_del_stat">Ad is sold!</p>
                  </>
                ) : singleVehicleData?.Status === 4 ? (
                  <>
                    <p className="Ad_del_stat">Ad is expired!</p>
                  </>
                ) : null}
                {/* <div className="price_home_test">
                  <ul>
                    <li>
                      <a>
                        <img src={star} alt="star" /> <b>9.5 </b>Inspection
                      </a>
                    </li>
                  </ul>
                <Link to="/ChatWithSeller" className="chat_with">Chat With Seller</Link>
           
                </div> */}
              </div>
              {/* <div className="detail_bottom text-center p-3">
                <p className="mb-0">
                  <i className="fa fa-"></i>
                  <b>Trending Car!</b> High chances of sale in next 6 days
                </p>
              </div> */}
            </div>
          </div>
        </>
      ) : null}
      {/* Term and condition model */}
      <Modal show={show} onHide={handleClose} className="Term_Modal">
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem,
              qui!
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem,
              qui!
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem,
              qui!
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem,
              qui!
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem,
              qui!
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Google login model */}
      <Modal
        show={show2}
        onHide={handleClose2}
        className="modal-google"
        id="logIN"
      >
        <Modal.Body className="modal_body_content text-center">
          <div className="body_popup">
            <a
              className="close_button"
              data-bs-dismiss="modal"
              onClick={handleClose2}
            >
              <img src={cross} alt="Cross" />
            </a>
            <h1>
              Sign in with <span>Google</span>
            </h1>
            <a>
              <img src={arrowDown} className="aroow_down" alt="arrowDown" />
            </a>
            {/* <button className="signin-butt-google" onClick={() => login()}>
              <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
            </button> */}
            {/* <button className="signin-butt-google">
            <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              Login with Google{" "} */}
            <div className="googleLoginButt">
              <GoogleLogin
                theme="filled_blue"
                size="large"
                shape="circle"
                width="220px"
                logo_alignment="left"
                onSuccess={onSuccess}
                onError={onError}
                className="google-butt"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------Chat message popup */}
      <Modal
        show={showChatPopup}
        onHide={handleCloseChatPopup}
        className="Term_Modal"
      >
        <Modal.Body>
          <label className="brnd_label  store-country">
            Write a message to seller
          </label>
          <input
            type="text"
            className="form-control store-input-field type_input send_message_input"
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                SendMessage();
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant={buttonColor} onClick={SendMessage}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DetailTab;

import React, { useEffect, useState } from "react";

import Results from "../../Components/Filter/Results";
import Category from "../../Components/Filter/Category";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import "../../../src/App.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Apiurl } from "../../Utils/Utils";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../Components/loader/loader";

const MainFilter = () => {
  const [accessoryFilterData, setAccessoryFilterData] = useState();

  const { state } = useLocation();
  const AdsCategory = state?.category;

  const [SearchText, SetSearchText] = useState(state?.searchText);
  const [category, SetCategory] = useState(state?.category);
  const [accessoryTypeDataArray, setAccessoryTypeDataArray] = useState([]);
  const [accessoryDataPriceRange, setAccesoryDataPriceRange] = useState([]);
  const [partsDataPriceRange, setpartsDataPriceRange] = useState([]);
  const [partsTypeDataArray, setpartsTypeDataArray] = useState([]);
  const [partsFilterData, setPartsFilterData] = useState();
  const [vehicleFilterData, setVehicleFilterData] = useState();
  const [vehicleDataPriceRange, setvehicleDataPriceRange] = useState([]);
  const [vehicleTypeDataArray, setvehicleTypeDataArray] = useState([]);
  const [vehicleTransmissionData, setVehicleTransmissionData] = useState([]);
  const [vehicleFuelTypedata, setVehicleFuelTypeData] = useState([]);
  const [vehiclekmdrivenData, setVehicleKmdrivenData] = useState([]);
  const [vehicleDataBrand, setvehicleDataBrand] = useState([]);
  const [vehicleDataBrandPart, setvehicleDataBrandPart] = useState([]);
  const [vehicleDataModel, setvehicleDataModel] = useState([]);
  const [vehicleDataModelPart, setvehicleDataModelPart] = useState([]);
  const [addToFavData, setAddToFavData] = useState([]);
  const [toggle, setToggle] = useState();
  const navigate = useNavigate();
  const [dumVal, setDumVal] = useState();

  const [isPageLoading, setIspageLoading] = useState(false);
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [allData, setAllData] = useState([]);

  const SourceCountryId = localStorage.getItem("sourceCountryId");
  const [location, setLocation] = useState({
    state: "",
    city: "",
    neighborhood: "",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }, []);

  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // use reverse geocoding API to get the address information
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const state = data.address.state || data.address.city_district;
        const city = data.address.town || data.county || data.address.city;
        const neighborhood = data.address.neighbourhood;

        setLocation({
          state: state,
          city: city,
          neighborhood: neighborhood,
        });
      })

      .catch((error) => {});
  };

  const handleSubmit = async (e) => {
    try {
      setIspageLoading(true);
      const data = {};
      data.SearchText = SearchText;
      // data.PageSize = 15;
      // data.PageNo = 1;
      data.Category = category;
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },
        url: `${Apiurl()}/Search/Search`,
      };
      const response = await axios(option);
      setIspageLoading(false);
      setAllData(JSON.parse(response.data.data));
      const checkData = JSON.parse(response.data.data);
      if (!checkData.Results?.length) {
        toast.error(response.data.message, {
          theme: "colored",
        });
      }
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
      navigate("/");
    }
  };
  useEffect(() => {
    handleSubmit();
  }, [toggle]);

  return (
    <>
      <Header
        addToFavData={addToFavData}
        setAddToFavData={setAddToFavData}
        dumVal={dumVal}
      />
      {isPageLoading ? <Loader /> : null}

      <main className="main_wrapper">
        <section className="filer_heading">
          {allData?.Results ? (
            <>
              <div className="container">
                <div className="location-name-head">
                  {/* <p>
                {location ? (
                  <>
                    {location.city}{location?.city?<>,</>: null}{location.state}
                  </>
                ) : null}
              </p> */}
                </div>
                <div className="colsm-12">
                  <div className="row main-filter-row">
                    <div className="col-sm-3">
                      <Category
                        setAccessoryFilterData={setAccessoryFilterData}
                        setAccessoryTypeDataArray={setAccessoryTypeDataArray}
                        accessoryTypeDataArray={accessoryTypeDataArray}
                        setAccesoryDataPriceRange={setAccesoryDataPriceRange}
                        accessoryDataPriceRange={accessoryDataPriceRange}
                        setPartsFilterData={setPartsFilterData}
                        setpartsDataPriceRange={setpartsDataPriceRange}
                        partsDataPriceRange={partsDataPriceRange}
                        setpartsTypeDataArray={setpartsTypeDataArray}
                        partsTypeDataArray={partsTypeDataArray}
                        setVehicleFilterData={setVehicleFilterData}
                        setvehicleDataPriceRange={setvehicleDataPriceRange}
                        vehicleDataPriceRange={vehicleDataPriceRange}
                        setvehicleTypeDataArray={setvehicleTypeDataArray}
                        setVehicleTransmissionData={setVehicleTransmissionData}
                        setVehicleFuelTypeData={setVehicleFuelTypeData}
                        vehiclekmdrivenData={vehiclekmdrivenData}
                        setVehicleKmdrivenData={setVehicleKmdrivenData}
                        vehicleTypeDataArray={vehicleTypeDataArray}
                        vehicleTransmissionData={vehicleTransmissionData}
                        vehicleFuelTypedata={vehicleFuelTypedata}
                        setvehicleDataBrand={setvehicleDataBrand}
                        setvehicleDataModel={setvehicleDataModel}
                        setvehicleDataBrandPart={setvehicleDataBrandPart}
                        setvehicleDataModelPart={setvehicleDataModelPart}
                        vehicleDataBrand={vehicleDataBrand}
                        vehicleDataBrandPart={vehicleDataBrandPart}
                        vehicleDataModel={vehicleDataModel}
                        vehicleDataModelPart={vehicleDataModelPart}
                        allData={allData}
                      />
                    </div>
                    <div className="col-sm-9">
                      <Results
                        setToggle={setToggle}
                        setDumVal={setDumVal}
                        toggle={toggle}
                        allData={allData}
                        accessoryFilterData={accessoryFilterData}
                        accessoryTypeDataArray={accessoryTypeDataArray}
                        accessoryDataPriceRange={accessoryDataPriceRange}
                        partsFilterData={partsFilterData}
                        partsTypeDataArray={partsTypeDataArray}
                        partsDataPriceRange={partsDataPriceRange}
                        vehicleFilterData={vehicleFilterData}
                        vehicleDataPriceRange={vehicleDataPriceRange}
                        vehicleTypeDataArray={vehicleTypeDataArray}
                        vehicleTransmissionData={vehicleTransmissionData}
                        vehicleFuelTypedata={vehicleFuelTypedata}
                        vehiclekmdrivenData={vehiclekmdrivenData}
                        vehicleDataBrand={vehicleDataBrand}
                        vehicleDataBrandPart={vehicleDataBrandPart}
                        vehicleDataModel={vehicleDataModel}
                        vehicleDataModelPart={vehicleDataModelPart}
                        setAddToFavData={setAddToFavData}
                        addToFavData={addToFavData}
                        AdsCategory={AdsCategory}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container">
                <div className="noresult-div"></div>
              </div>
            </>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default MainFilter;

import React, { useEffect, useState } from "react";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import { RiErrorWarningLine } from "react-icons/ri";
import { AiOutlineWarning } from "react-icons/ai";


import {
  Apiurl,
  GetUserId,
  IsStoreAdded,
  RemoveSession,
  SellerCanAddStore,
  getExistData,
  setExistData,
} from "../../Utils/Utils";
import axios from "axios";
import { MdSend } from "react-icons/md";
import { AiOutlineLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/loader/loader";
import { Button, Modal } from "react-bootstrap";
import moment from "moment/moment";
import { AiFillDelete } from "react-icons/ai";
import whatsappImg from "../../Images/whatsapp.png";

const ChatWithSeller = () => {
  const navigate = useNavigate();
  const googleLoginData = JSON.parse(localStorage.getItem("LoginData"));
  const [newData, setNewData] = useState();
  const [idArray, setIdArray] = useState([]);
  const [dummy, setDummy] = useState();
  const [clickData, setClickData] = useState();
  const [existingData, setExistingData] = useState([]);
  const state = useLocation();
  const getExistingData = JSON?.parse(getExistData() || '[]');

  const getSellerStore = GetUserId();
  const [Profile, setProfile] = useState([]);
  const [Show, setShow] = useState(false);
  const [inboxData, setInboxData] = useState();
  const [findId, setFindId] = useState();
  const [chatData, setChatData] = useState();
  const [isPageLoading, setIspageLoading] = useState(false);
  const [newMessage, setNewMessage] = useState();
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [messages, setMessages] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [toastDummy, setToastDummy] = useState();
  const [showMessage, setShowMessage] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleShowModal = () => setShow1(true);
  const [iconColor, setIconColor] = useState("gray");
  const [getId, setGetid] = useState();
  const [dummyvalue, setDummyValue] = useState(false);
  const [filterValue, setFilterValue] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [chatStatus, setChatStatus] = useState();
  const [existValue, setExistValue] = useState();
  const [deactivatedChat, setDeactivatedChat] = useState();
  const isSameDay = (date1, date2) => moment(date1).isSame(date2, "day");
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    RemoveSession();
    setProfile(null);
    navigate("/");
    setShow2(true);
  };
  useEffect(() => {
    const navigateData = state?.state?.data;
    const exist = state?.state?.existChat;
    setExistValue(exist);

    setNewData(navigateData);
  }, []);

  useEffect(() => {
    const exist = state?.state?.existChat;
    setExistValue(exist);
  }, []);

  useEffect(() => {
    if (newData && newData.length > 0) {
      const extractedIds = newData.map((item) => item.Id);
      setIdArray(extractedIds);
    }
  }, [newData]);
  const GetInbox = (e) => {
    const data = {};
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/CustomerChat/MyInbox`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        if (
          e.data.message === "You have no message in Inbox"
        ) {
          setInboxData();
          handleShowModal();
        }

        const parsedData = JSON.parse(e.data.data);
        setDummyValue(parsedData);
        parsedData.sort(
          (a, b) => new Date(b.MessageDate) - new Date(a.MessageDate)
        );
        setInboxData(parsedData);
      })
      .catch((error) => {
        setIspageLoading(false);

        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        const errorMessgae = error.response.data.data;

        if (errorMessgae?.active === false) {
          handleShow2();
        }

      });
  };
  useEffect(() => {
    GetInbox();
  }, []);

  const MarkMessageRead = () => {
    const data = idArray;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/CustomerChat/MarkChatsAsRead`,
    };
    axios(option)
      .then((e) => {
        setDummy(e.data.data);
        GetInbox();
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  useEffect(() => {
    if (getId?.length) {
      const filterData = getId.filter(
        (item) => item?.[0]?.ParentChatId == showMessage?.[0]?.ParentChatId
      );

      setFilterValue(filterData?.[0]);
    }
  }, [getId, showMessage]);

  useEffect(() => {
    if (filterValue?.length) {
      const GetIds = filterValue.map((item) => item.Id);
      setIdArray(GetIds);
    }
  }, [filterValue]);
  const GetProfileData = (e) => {
    const data = {};
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        const ProfileData = JSON.parse(e.data.data);
        setProfile(ProfileData);
        if (ProfileData?.PackageDetails?.Enabled === false) {
          SellerCanAddStore();
          IsStoreAdded();
        }
      })
      .catch((error) => { });
  };
  useEffect(() => {
    GetProfileData();
  }, []);
  useEffect(() => {
    if (idArray) {
      MarkMessageRead();
    }
  }, [idArray]);

  const OpenChat = (item, index) => {

    if (item?.From?.Active === false || item?.To?.Active === false) {
      setDeactivatedChat(true);

      setActiveIndex(
        item?.ParentChatId ||

        getExistingData?.ParentChatId
      );
      setShow(false);

    } else {
      setChatStatus(item?.Ads?.Status);
      if (activeIndex === item?.ParentChatId) {
        return;
      }
      setActiveIndex(
        item?.ParentChatId ||

        getExistingData?.ParentChatId
      );
      setIspageLoading(true);
      setMessageData("");
      const data = {};
      data.ParentChatId =
        item?.ParentChatId ||

        getExistingData?.ParentChatId;
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },
        url: `${Apiurl()}/CustomerChat/GetChatMessagesByParentChatId`,
      };
      axios(option)
        .then((e) => {
          setIspageLoading(false);
          const ChatDataAll = JSON.parse(e?.data?.data);
          const sortedMessages = ChatDataAll?.sort((a, b) => {
            const dateA = new Date(a.MessageDate);
            const dateB = new Date(b.MessageDate);
            return dateA - dateB;
          });
          const filterdelete = sortedMessages?.filter(
            (item) =>
              item?.DeleteForBuyerId !== getSellerStore &&
              item?.DeleteForSellerId !== getSellerStore
          );
          setChatData(filterdelete);


          setShowMessage(filterdelete);
          setExistData([]);
        })
        .catch((error) => {
          setIspageLoading(false);

          toast.error(error.response.data.message, {
            theme: "colored",
          });
        });


      setShow(true);
    }

  };

  // useEffect(() => {
  //   if (newData?.length ) {
  //     OpenChat();
  //   }
  // }, [newData]);

  useEffect(() => {
    if (getExistingData && getExistingData.ParentChatId && existValue) {
      OpenChat();

    }
  }, [getExistingData, existValue])

  const GobackToInbox = () => {
    setActiveIndex("");
    setShow(false);
  };

  const validation = () => {
    if (!newMessage) {
      toast.error("message should not be empty", {
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  const handleNewMessage = () => {
    if (!validation()) return;
    const data = {};
    data.MessageBody = newMessage;
    data.ToId =
      showMessage?.[0]?.From?.Id === getSellerStore
        ? showMessage?.[0]?.To?.Id
        : showMessage?.[0]?.From?.Id;

    if (showMessage?.[0]?.Ads?.AdsCategory == "1") {
      data.VehicleId = showMessage?.[0]?.Ads?.Id;
    }
    if (showMessage?.[0]?.Ads?.AdsCategory == "0") {
      data.PartId = showMessage?.[0]?.Ads?.Id;
    }
    if (showMessage?.[0]?.Ads?.AdsCategory == "2") {
      data.AccessoryId = showMessage?.[0]?.Ads?.Id;
    }
    data.Type = showMessage?.[0]?.Ads?.AdsCategory;
    data.ParentChatId = showMessage?.[0]?.ParentChatId;
    data.MessageDate = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      dateStyle: "medium",
      timeStyle: "medium",
    });

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/CustomerChat/SendChatMesssage`,
    };
    axios(option)
      .then((e) => {
        setNewMessage("");
        setIconColor("gray");
        GetInbox();

        // navigate("/ChatWithSeller")
      })
      .catch((error) => {
        setIspageLoading(false);
        setNewMessage("");
        const errorMessgae = error.response.data.data;

        if (errorMessgae?.active === false) {
          setDeactivatedChat(true);
          setShow(false);
          GetInbox();

        }


      });
  };

  useEffect(() => {
    if (messageData && messageData.length && chatData?.length) {
      const latestMessage = messageData[messageData.length - 1];
      if (showMessage?.[0]?.ParentChatId === latestMessage?.ParentChatId) {
        GetInbox();
        const mergedData = [...chatData, ...messageData];
        const mergeDatafilter = mergedData?.filter(
          (item) => item?.ParentChatId === showMessage?.[0]?.ParentChatId
        );
        setShowMessage(mergeDatafilter);
      }
    }
  }, [messageData]);

  useEffect(() => {
    if (toastDummy?.To?.Id || toastDummy?.From?.Id == getSellerStore) {
      GetInbox();
    }
  }, [toastDummy]);

  useEffect(() => {
    scrollToBottom();
  }, [showMessage]);

  const scrollToBottom = () => {
    const container = document.querySelector(".main-message-container");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };
  const handleNavigate = () => {
    navigate("/");
  };
  const handleChange = (e) => {
    setNewMessage(e.target.value);
    setIconColor(e.target.value.trim() !== "" ? "blue" : "gray");
  };

  // ------Chat Delete fun------
  const handleChatDel = (item) => {
    setIspageLoading(true);
    const data = {};
    data.SellerId = item?.Ads?.SellerId;
    data.ParentChatId = item?.ParentChatId;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/CustomerChat/DeleteChatMessage`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        setActiveIndex("");
        setShow(false);
        GetInbox();
      })
      .catch((error) => {
        setIspageLoading(false);

        toast.error(error.response?.data?.message, {
          theme: "colored",
        });
      });
  };

  let prevDate = null;

  return (
    <>
      {isPageLoading ? <Loader /> : null}
      <Header
        setMessages={setMessages}
        setMessageData={setMessageData}
        messageData={messageData}
        dummy={dummy}
        setGetid={setGetid}
        getId={getId}
        dummyvalue={dummyvalue}
        checkId={true}
        setToastDummy={setToastDummy}
      />

      <div className="Chat-main-container">
        <div
          className={
            Show === false
              ? "chat-side-container"
              : "chat-side-container-mobile chat-side-container"
          }
        >
          <div className="inbox_first_head">
            <h3>Inbox</h3>
            <div className="inbox_second_head">
              <p>{googleLoginData?.name}</p>
              <img
                src={googleLoginData?.picture}
                alt=""
                className="Advertise_image"
              />
            </div>
          </div>

          <div className="inbox_data_name">
            {inboxData?.map((item, index) => {
              const indexOfActive = activeIndex === item?.ParentChatId;
              return (
                <div
                  key={index}
                  className={`user-messages ${activeIndex === item?.ParentChatId ? "active" : ""
                    }`}
                  onClick={() => OpenChat(item, index)}
                >
                  {" "}
                  <img
                    src={item?.Ads?.Image}
                    alt=""
                    className="Advertise_image"
                  />
                  <img
                    referrerPolicy="no-referrer"
                    src={
                      item?.From?.Id === getSellerStore
                        ? item?.To?.Photo
                        : item?.From?.Photo
                    }
                    alt="Profile Photo"
                    className="chat-profile-photo"
                  />
                  <>
                    <div>
                      {item?.From?.Id === getSellerStore
                        ? item?.To?.DisplayName
                        : item?.From?.DisplayName}
                    </div>
                    {item?.From?.Active === false || item?.To?.Active === false ? <>
                      <div className="deactivated_chat">
                        <p>Deactivated</p>
                      </div>
                    </> : null}
                    {item?.NewMessageCount > 0 && !indexOfActive ? (
                      <>
                        <div className="message_count">
                          <p>{item?.NewMessageCount}</p>
                        </div>
                        <AiFillDelete
                          className="chat_del_icon_hello"
                          onClick={() => handleChatDel(item)}
                        />
                      </>
                    ) : (
                      <AiFillDelete
                        className="chat_del_icon"
                        onClick={() => handleChatDel(item)}
                      />
                    )}
                  </>
                </div>
              );
            })}
          </div>
        </div>
        {Show === true ? (
          <div className="chat-right-side-container">
            <div className="right-side-main-div">
              <div className="chat-messages">
                <div className="chat_ad_one">
                  <AiOutlineLeft
                    onClick={GobackToInbox}
                    className="back_icon_chat"
                  />{" "}
                  <div className="chat_ad_img">
                    <img
                      src={showMessage?.[0]?.Ads?.Image}
                      alt=""
                      className="Advertise_image"
                    />
                    <img
                      src={
                        showMessage?.[0]?.From?.Id === getSellerStore
                          ? showMessage?.[0]?.To?.Photo
                          : showMessage?.[0]?.From?.Photo
                      }
                      alt=""
                      className="chat-profile-photo-msg"
                      width="120px"
                    />
                    <p>
                      {showMessage?.[0]?.From?.Id === getSellerStore
                        ? showMessage?.[0]?.To?.DisplayName
                        : showMessage?.[0]?.From?.DisplayName}
                    </p>
                  </div>
                </div>
                <div className="Chat_ad_title">
                  <p className="chat_ad_prod"> Ad Title : </p>
                  <p className="chat_ad_adver">
                    {
                      showMessage?.[0]?.Ads?.AdsCategory === 0 ? (
                        <>{showMessage?.[0]?.Ads?.Name}</>
                      ) : showMessage?.[0]?.Ads?.AdsCategory === 1 ? (
                        <>{showMessage?.[0]?.Ads?.AdvertisementTitle}</>
                      ) : showMessage?.[0]?.Ads?.AdsCategory === 2 ? (
                        <>{showMessage?.[0]?.Ads?.Name}</>
                      ) : null // You can add a default case or handle it accordingly
                    }
                  </p>
                </div>
              </div>
              <div className="main-message-container">
                <div className="message-start-container">
                  <div></div>
                  {showMessage?.map((chatItem, index) => {
                    const messageDate = new Date(chatItem?.MessageDate);
                    const originalDate = messageDate;
                    const formattedDate = moment(
                      originalDate,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                      .add({ hours: 12, minutes: 30 })
                      .format("YYYY-MM-DD HH:mm:ss");

                    const showDateHeader = !isSameDay(formattedDate, prevDate);
                    prevDate = formattedDate;

                    return (
                      <div
                        key={index}
                        className={
                          chatItem?.From?.Id === getSellerStore
                            ? "right_message"
                            : "left_message"
                        }
                      >
                        <div className="left_right_msg_div">
                          {showDateHeader && (
                            <div className="date_header">
                              <p>
                                {" "}
                                {new Date(formattedDate).toLocaleDateString(
                                  [],
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </p>
                            </div>
                          )}
                          <div>
                            {chatItem?.To?.Id === getSellerStore ? (
                              <div className="message_body_both">
                                <div className="chat_message_img">
                                  <img src={chatItem?.From?.Photo} alt="" />
                                </div>

                                <div className="chat_msg_name">
                                  <div className="chat_msg_name_show">
                                    {chatItem?.To?.Id === getSellerStore ? (
                                      <>{chatItem?.From?.DisplayName}</>
                                    ) : null}

                                    <div className="span_message">
                                      {new Date(
                                        formattedDate
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      chatItem?.From?.Id === getSellerStore
                                        ? "receiveMessage"
                                        : "sentMessage"
                                    }
                                  >
                                    <p>{chatItem?.MessageBody}</p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="message_body_both_hello">
                                <div className="chat_msg_name_rec">
                                  <div className="chat_msg_name_show_rec">
                                    <div className="span_message">
                                      {new Date(
                                        formattedDate
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </div>
                                    You
                                  </div>
                                  <div
                                    className={
                                      chatItem?.From?.Id === getSellerStore
                                        ? "receiveMessage"
                                        : "sentMessage"
                                    }
                                  >
                                    <p>{chatItem?.MessageBody}</p>
                                  </div>
                                </div>
                                <div className="chat_message_img">
                                  <img src={chatItem?.From?.Photo} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="chat-input-div">
                {chatStatus === "Active" || existValue ? (
                  <>
                    <input
                      className="chat-input"
                      type="text"
                      placeholder="Write Something Here"
                      onChange={handleChange}
                      value={newMessage}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNewMessage();
                        }
                      }}
                    />
                    <MdSend
                      onClick={handleNewMessage}
                      className="send-icon"
                      style={{ color: iconColor }}
                    />
                  </>
                ) : (
                  <div className="chat_status">
                    <p>{chatStatus}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="chat-right-side-default-container">
            <div className="chat-messages"> </div>
            <div className="default-message-main">
              {deactivatedChat === true ? <>
                <div className="deactive_chat_admin">
                  <p>This chat has been blocked by admin</p>
                </div>
              </> :
                <>
                  <div className="default-message">Your Chats Will Appear Here</div>
                </>
              }
            </div>

            <div className="chat-input-div"></div>
          </div>
        )}
      </div>

      <Modal show={show1} className="dashboard-modal">
        <Modal.Header>
          <Modal.Title className="modal-head-warning">
            <RiErrorWarningLine />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-data">
          <div className="modal-body-aid">
            <p>No Chat Found</p>
          </div>
          <div className="modal-body-button">
            <Button
              variant="secondary"
              onClick={handleNavigate}
              className="btn-danger"
            >
              Go To Home
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* -----If user blocked than modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        id="logIN"
        className="modal_header_google"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-head-warning">
            <AiOutlineWarning className="google-block-icon" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body_content text-center">
          <p className="google-modal-block">Account is disable or inactive</p>
          <p className="google-modal-block">
            Please contact support for more info.
          </p>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};
export default ChatWithSeller;

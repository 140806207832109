import React, { useEffect, useState } from "react";
import "../../../../src/App.css";

const Location = () => {
  const [location, setLocation] = useState({
    state: "",
    city: "",
    neighborhood: "",
  });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }, []);

  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // use reverse geocoding API to get the address information
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const state = data.address.state || data.address.city_district;
        const city = data.address.city || data.adress.town || data.county;
        const neighborhood = data.address.neighbourhood;

        setLocation({
          state: state,
          city: city,
          neighborhood: neighborhood,
        });
      })
      .catch((error) => {});
  };

  return (
    <>
      <div>
        <div className="location-div">
          <p className="location-data">State</p>
          <p className="location-data">{location.state}</p>
        </div>
        <div className="location-div">
          <p className="location-data">City</p>
          <p className="location-data">{location.city}</p>
        </div>
        <div className="location-div">
          <p className="location-data">Neighbourhood</p>
          <p className="location-data">{location.neighborhood}</p>
        </div>
      </div>
    </>
  );
};

export default Location;

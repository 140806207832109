import React from "react";
import verify from "../../../Images/verify.png";
import "../../../../src/App.css";
import { MdAppRegistration } from "react-icons/md";
import { BsFuelPumpDiesel } from "react-icons/bs";
import { GiPathDistance } from "react-icons/gi";
import { AiFillProfile } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { GrManual } from "react-icons/gr";
import { IoLogoModelS } from "react-icons/io";
import { RiPriceTag2Line } from "react-icons/ri";
import { MdOutlineDescription } from "react-icons/md";
import { MdDescription } from "react-icons/md";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
import { BiCard } from "react-icons/bi";

const Overview1 = ({ singleAdData }) => {
  const singleVehicleData = singleAdData;

  const RegisterationMonthValue = () => {
    if (singleVehicleData?.RegMonth == "1") {
      return "Jan";
    }
    if (singleVehicleData?.RegMonth == "2") {
      return "Feb";
    }
    if (singleVehicleData?.RegMonth == "3") {
      return "Mar";
    }
    if (singleVehicleData?.RegMonth == "4") {
      return "Apr";
    }
    if (singleVehicleData?.RegMonth == "5") {
      return "May";
    }
    if (singleVehicleData?.RegMonth == "6") {
      return "Jun";
    }
    if (singleVehicleData?.RegMonth == "7") {
      return "Jul";
    }
    if (singleVehicleData?.RegMonth == "8") {
      return "Aug";
    }
    if (singleVehicleData?.RegMonth == "9") {
      return "Sep";
    }
    if (singleVehicleData?.RegMonth == "10") {
      return "Oct";
    }
    if (singleVehicleData?.RegMonth == "11") {
      return "Nov";
    }
    if (singleVehicleData?.RegMonth == "12") {
      return "Dec";
    }
  };

  return (
    <>
      {singleVehicleData?.VehicleImages ||
      singleVehicleData?.Images?.[0].VehicleId ? (
        <>
          <div>
            <div className="tabs_main">
              <h1 className="overview_heading">Car Overview</h1>

              <div className="">
                <div className="col-sm-3  details-div">
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <IoLogoModelS className="logo-icon-detail" />
                      <p className="logo-icon-p">Brand</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Brand || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <BsCardChecklist className="logo-icon-detail" />
                      <p className="logo-icon-p">Model</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Model || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <BiCard className="logo-icon-detail" />
                      <p className="logo-icon-p">Variant</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Variant || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdAppRegistration className="logo-icon-detail" />
                      <p className="logo-icon-p">Registration Year</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.RegYear || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdOutlineCalendarMonth className="logo-icon-detail" />
                      <p className="logo-icon-p">Registration Month</p>
                    </div>
                    <p className="overview-name-data">
                      {RegisterationMonthValue() || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <BsFuelPumpDiesel className="logo-icon-detail" />
                      <p className="logo-icon-p">Fuel Type</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.FuelType || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <GiPathDistance className="logo-icon-detail" />
                      <p className="logo-icon-p">kms Driven</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Mileage || "----"} Kms
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <AiFillProfile className="logo-icon-detail" />
                      <p className="logo-icon-p"> Ownership</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Ownership === "1"
                        ? `1st Owner`
                        : singleVehicleData?.Ownership === "2"
                        ? `2nd owner`
                        : singleVehicleData?.Ownership === "3"
                        ? `3rd owner`
                        : singleVehicleData?.Ownership === "4"
                        ? `4th owner`
                        : `4++ owner`}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <GrManual className="logo-icon-detail" />
                      <p className="logo-icon-p">Transmission</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Transmission || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdLocationOn className="logo-icon-detail" />
                      <p className="logo-icon-p"> Car Location</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.State ? (
                        <>
                          {singleVehicleData?.State?.Name || "----"} (
                          {singleVehicleData?.Country?.Name || "----"})
                        </>
                      ) : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {singleVehicleData?.PartImages ||
      singleVehicleData?.Images?.[0].PartId ? (
        <>
          <div>
            <div className="tabs_main">
              <h1 className="overview_heading">Part Overview</h1>

              <div className="row">
                <div className="col-sm-3 details-div">
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <IoLogoModelS className="logo-icon-detail" />
                      <p className="logo-icon-p">Brand</p>
                    </div>

                    <p className="overview-name-data">
                      {singleVehicleData?.Brand || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <BsCardChecklist className="logo-icon-detail" />
                      <p className="logo-icon-p">Model</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.Model || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <RiPriceTag2Line className="logo-icon-detail" />
                      <p className="logo-icon-p">Price Type</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.PriceType || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdAppRegistration className="logo-icon-detail" />
                      <p className="logo-icon-p">Part Type</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData.TypeId === 0 ? "Internal" : "External"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdLocationOn className="logo-icon-detail" />
                      <p className="logo-icon-p"> Part Location</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.State?.Name || "----"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {singleVehicleData?.AccesoryImages ||
      singleVehicleData?.Images?.[0].AccessoryId ? (
        <>
          <div>
            <div className="tabs_main">
              <h1 className="overview_heading">Accessory Overview</h1>

              <div className="row">
                <div className="col-sm-3 details-div">
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <RiPriceTag2Line className="logo-icon-detail" />
                      <p className="logo-icon-p">Price Type</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.PriceType || "----"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdAppRegistration className="logo-icon-detail" />
                      <p className="logo-icon-p">Accessory Type</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData.TypeId === 0 ? "Internal" : "External"}
                    </p>
                  </div>
                  <div className="icons-main-div-head">
                    <div className="icons-child-div">
                      <MdLocationOn className="logo-icon-detail" />
                      <p className="logo-icon-p"> Part Location</p>
                    </div>
                    <p className="overview-name-data">
                      {singleVehicleData?.State?.Name || "----"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Overview1;

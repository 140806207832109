import React from "react";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import Vehicle from "../../Components/PostAid/Vehicle";
import "../../../src/App.css";
import Accessories from "../../Components/PostAid/Accessories";
import Parts from "../../Components/PostAid/Parts";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const MainPost = () => {
  const editLocation = useLocation();
  return (
    <div>
      <Header />
      <section className="data_page">
        <div className="post-aid-div">
          <div className="">
            <h2 className="text-center fw-bold mb-3">Select Category</h2>
            {editLocation.state ? (
              <>
                <Tabs
                  defaultActiveKey={
                    editLocation?.state?.AdsCategory === 1
                      ? "Vehicle"
                      : editLocation?.state?.AdsCategory === 2
                      ? "Accessories"
                      : editLocation?.state?.AdsCategory === 0
                      ? "Parts"
                      : "Vehicle"
                  }
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab
                    eventKey="Vehicle"
                    title="Vehicle"
                    className="postAdd_btn_1"
                    disabled={
                      editLocation?.state?.AdsCategory
                        ? !(editLocation?.state?.AdsCategory === 1)
                        : true
                    }
                  >
                    <Vehicle editLocation={editLocation?.state} />
                  </Tab>
                  <Tab
                    eventKey="Parts"
                    title="Parts"
                    className="postAdd_btn_2"
                    disabled={
                      editLocation?.state?.AdsCategory
                        ? !(editLocation?.state?.AdsCategory === 0)
                        : false
                    }
                  >
                    <Parts PartseditLocation={editLocation?.state} />
                  </Tab>
                  <Tab
                    eventKey="Accessories"
                    title="Accessories"
                    className="postAdd_btn_3"
                    disabled={
                      editLocation?.state?.AdsCategory
                        ? !(editLocation?.state?.AdsCategory === 2)
                        : true
                    }
                  >
                    <Accessories
                      AccessorieseditLocation={editLocation?.state}
                    />
                  </Tab>
                </Tabs>
              </>
            ) : (
              <Tabs
                defaultActiveKey="Vehicle"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="Vehicle"
                  title="Vehicle"
                  className="postAdd_btn_1"
                >
                  <Vehicle editLocation={editLocation?.state} />
                </Tab>
                <Tab eventKey="Parts" title="Parts" className="postAdd_btn_2">
                  <Parts PartseditLocation={editLocation?.state} />
                </Tab>
                <Tab
                  eventKey="Accessories"
                  title="Accessories"
                  className="postAdd_btn_3"
                >
                  <Accessories AccessorieseditLocation={editLocation?.state} />
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default MainPost;

import React, { useEffect, useState } from "react";
import "../../../../src/App.css";
import { Apiurl } from "../../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
const PartsList = ({
  editLocation,
  state,
  oldStateid,
  city,
  setCity,
  neighborhood,
  setNeighbourhood,
  setState,
  setCountryId,
  setCountry,
  countryId,
  country,
}) => {
  const [isPageLoading, setIspageLoading] = useState(false);

  const tokenid = JSON.parse(localStorage.getItem("tokenID"));

  // --------------Get Country & State-------------------//
  const GetCountryStateData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetCountries`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const CountryData = JSON.parse(e.data.data);
        setCountry(CountryData);
      })
      .catch((error) => {
        setIspageLoading(false);
      toast.error(error.response.data.message,{
        theme: "colored",
           
      })
      });
  };
  useEffect(() => {
    GetCountryStateData();
  }, []);
  // ---------Getting States Data-------------------//

  const [statesData, setStatesData] = useState([]);
  useEffect(() => {
    const filterStates = country?.filter(
      (item) => item?.Id === Number(countryId)
    );
    setStatesData(filterStates?.[0]?.States);
  }, [countryId]);

  return (
    <div>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
        tabIndex="0"
      >
        <div className="mb-4 list-location">
        <label className="brnd_label mb-3">
            Country<span className="required">*</span>
          </label>
          <select
            name="cars"
            className="type_input"
            value={countryId}
            onChange={(e) => {
              setCountryId(e.target.value);
            }}
          >
            <option value="0">Select a Country</option>
            {country?.map((country) => (
              <option key={country.Id} value={country.Id}>
                {country.Name}
              </option>
            ))}
          </select>
          </div>
        <div className="mb-4">

        <label className="brnd_label mb-3">
            State<span className="required">*</span>
          </label>
          <select
            name="cars"
            className="type_input"
            onChange={(e) => setState(e.target.value)}
            value={state || oldStateid}
          >
            <option>Select a State</option>
            {statesData?.map((state) => (
              <option key={state.Id} value={state.Id}>
                {state.Name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
       <label className="brnd_label mb-3">
            City<span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control type_input"
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
       </div>

       <div className="mb-4">
        <label className="brnd_label mb-3">
            Neighbourhood<span className="required">*</span>
          </label>
          <input
            type="text"
            className="form-control type_input"
            required
            value={neighborhood}
            onChange={(e) => setNeighbourhood(e.target.value)}
          />
      </div>
    </div>
    </div>

  );
};
export default PartsList;

import React from "react";
import { Spinner } from "react-bootstrap";
import "../../../src/App.css";

const Loader = () => {
  return (
    <>
      <div className="loader-main-div">
        <div className="loader-second-div">
          <Spinner className="loader-spinner" animation="border" />
        </div>
      </div>
    </>
  );
};
export default Loader;

import React from "react";
import { useNavigate } from "react-router-dom";
export const Apiurl = () => {
  return "https://marketplace-admin.elitewebdemo.com/api";
};
export const RemoveSession = () => {
  localStorage.clear("tokenID");
  localStorage.clear("LoginData");
};

export const ActiveStore = (activeStore) => {
  localStorage.setItem("activePlan", activeStore);
};
export const GetActiveStore = () => {
  return localStorage.getItem("activePlan") || null;
};
export const SellerCanAddStore = (StoreStatus) => {
  localStorage.setItem("storeStatus", StoreStatus);
};
export const GetSellerCanAddStore = () => {
  return localStorage.getItem("storeStatus") || null;
};

export const IsStoreAdded = (addedStore) => {
  localStorage.setItem("sellerStoreAdded", addedStore);
};
export const getTheAddedStore = () => {
  return localStorage.getItem("sellerStoreAdded");
};

export const SellerStoreId = (sellerIdStore) => {
  localStorage.setItem("sellerStoreId", sellerIdStore);
};
export const getSellerStoreId = () => {
  return localStorage.getItem("sellerStoreId");
};

export const sellerId = (sellerId) => {
  localStorage.setItem("sellerId", sellerId);
};
export const getSellerId = () => {
  return localStorage.getItem("sellerId");
};
// export const AllChatMessage = (fullChat) => {
//   localStorage.setItem("allMessage", JSON.stringify(fullChat));
// };
// export const GetAllMessage = () => {
//   return JSON.parse(localStorage.getItem("allMessage"));
// };
export const UserId=(userId)=>{
  localStorage.setItem("userId", userId);

}
export const GetUserId=()=>{
  return localStorage.getItem("userId");
}
export const storeSuccessAdded=(storeSuccess)=>{
  localStorage.setItem("storeSuccess", storeSuccess);

}
export const GetstoreSuccessAdded=()=>{
  return localStorage.getItem("storeSuccess");
}
export const setExistData=(exist)=>{
  localStorage.setItem("existData", exist);

}
export const getExistData=()=>{
  return localStorage.getItem("existData");
}




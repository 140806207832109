import React, { useEffect, useState } from "react";
import { Container, Modal, Nav, Navbar, NavDropdown } from "react-bootstrap";
import marketplacelogo from "../../Images/marketplace-Logo.png";
import sedan from "../../Images/sedan.png";
import "../../../src/App.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Google from "./Google";
import axios from "axios";
import Loader from "../loader/loader";
import { MdNotifications } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { AiOutlineWarning } from "react-icons/ai";

import {
  Apiurl,
  GetActiveStore,
  GetSellerCanAddStore,
  GetUserId,
  GetstoreSuccessAdded,
  IsStoreAdded,
  RemoveSession,
  SellerCanAddStore,
  UserId,
  getSellerId,
  getSellerStoreId,
  getTheAddedStore,
  messageData,
  sellerId,
  setExistData,
} from "../../Utils/Utils";
import { AiFillHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import { AiFillEdit } from "react-icons/ai";
import * as signalR from "@microsoft/signalr";
const Header = ({
  sourceCountryId,
  setSourceCountryId,
  country,
  setMessages,
  setMessageData,
  messageData,
  dummy,
  getId,
  setGetid,
  checkId,
  dummyvalue,
  setToastDummy,
  DummyValueLogin,
  dumVal,
}) => {
  const googleLoginData = JSON.parse(localStorage.getItem("LoginData"));
  const [activePlan, setActivePlan] = useState();
  const [sellerStoreData, setSellerStoreData] = useState();
  const getStoreActive = GetActiveStore();
  const getAddedStore = getTheAddedStore();
  const getStoreStatusByvalue = GetSellerCanAddStore();
  const getStoreSuccessAdded = GetstoreSuccessAdded();

  const getSellerStore = getSellerStoreId();
  const Getuserid = GetUserId();
  const navigate = useNavigate();
  const [Profile, setProfile] = useState([]);
  const [countryId, setCountryId] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [countryHeader, setCountryHeader] = useState([]);
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [isPageLoading, setIspageLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const showNotificationModel = () => setShowNotification(true);
  const closeNotificationModel = () => setShowNotification(false);
  const [notificationData, setNotificationData] = useState();
  const [showMessageData, setShowMessageData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);

  const handleShow2 = () => {
    RemoveSession();
    setProfile(null);
    navigate("/");
    setShow2(true);
  };

  const GetProfileData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Profile/GetProfile`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const ProfileData = JSON.parse(e.data.data);
        setProfile(ProfileData);
        IsStoreAdded(ProfileData?.SellerHasStore);
        sellerId(ProfileData?.SellerStore?.SellerId);
        SellerCanAddStore(
          ProfileData?.PackageDetails?.PurchasedPackage?.AllowToAddStore
        );

        UserId(ProfileData?.Id);

        if (ProfileData?.PackageDetails?.Enabled === false) {
          SellerCanAddStore();
          IsStoreAdded();
        }
      })
      .catch((error) => {
        setIspageLoading(false);
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    if (dumVal || DummyValueLogin) {
      GetProfileData();
    }
  }, [dumVal, DummyValueLogin]);

  const handleLogout = () => {
    RemoveSession();
    setProfile(null);
    navigate("/");
  };
  const GetCountryStateData = (e) => {
    const data = {};
    setIspageLoading(true);
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/GetCountries`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        const CountryData = JSON.parse(e.data.data);
        setCountryHeader(CountryData);
      })
      .catch((error) => {
        setIspageLoading(false);
      });
  };
  const handleCountry = (e) => {
    setCountryId(e.target.value);
    const countryValue = e.target.value;
    setSourceCountryId(countryValue);
  };

  useEffect(() => {
    GetCountryStateData();
  }, []);
  const handleStore = () => {
    navigate("/storedetail");
  };
  const UnreadMessgae = () => {
    const data = {};

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/CustomerChat/MyUnReadMessage`,
    };
    axios(option)
      .then((e) => {
        setNotificationData(JSON.parse(e.data.data));
        setErrorMessage(e?.data?.message);
      })
      .catch((error) => {
        setIspageLoading(false);
        const errorMessgae=error.response.data.data ;

        if (errorMessgae?.active === false) {
          handleShow2();
        }
      
      });
  };

  useEffect(() => {
    if (tokenid) {
      UnreadMessgae();
    }
  }, [tokenid]);
  useEffect(() => {
    if (dummyvalue?.length) {
      UnreadMessgae();
    }
  }, [dummyvalue]);

  // // -----------------------signalr---------------

  const connection = () => {
    const hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.None)
      .withUrl("https://marketplace-admin.elitewebdemo.com/messagehub")
      .build();

    hubConnection.start();
    hubConnection.on("SendMessages", function (data) {
      const parsedData = JSON.parse(data.data);
      setToastMessage(parsedData);
      setToastDummy(parsedData);

      setMessages((prevMessages) => [...prevMessages, parsedData]);
      setMessageData((prevData) => [...prevData, parsedData]);






    });

    return hubConnection;
  };

  useEffect(() => {
    const hubConnection = connection();

    return () => {
      hubConnection?.stop();
    };
  }, []);
  const location = useLocation();
  const pathname = location?.pathname;
  const isChatWithSellerPath = location.pathname === "/ChatWithSeller";

  useEffect(() => {
    if (toastMessage) {
      if (pathname !== "/ChatWithSeller") {
        if (toastMessage?.To?.Id === Getuserid) {
          toast.success(
            "New Message Received from " + toastMessage?.From?.DisplayName,
            {
              theme: "colored",
            }
          );
          UnreadMessgae();
        }
      }
    }
  }, [toastMessage]);

  // ------------------notifcation model---------------
  const handleModel = () => {
    showNotificationModel();
  };

  const functionGroup = () => {
    const groupedData = {};

    notificationData?.forEach((item) => {
      const { AdvertisementId } = item?.Ads;
      const { DisplayName } = item?.From;

      if (AdvertisementId && DisplayName) {
        const key = `${AdvertisementId}-${DisplayName}`;

        if (!groupedData[key]) {
          groupedData[key] = [item];
        } else {
          groupedData[key].push(item);
        }
      }
    });

    const mergedArray = [];

    for (const key in groupedData) {
      const array = groupedData[key];
      const sortedArray = array.sort(
        (a, b) => new Date(b.MessageDate) - new Date(a.MessageDate)
      );
      mergedArray.push(sortedArray);
    }
    setShowMessageData(mergedArray);

    if (checkId) {
      setGetid(mergedArray);
    }
  };

  useEffect(() => {
    functionGroup();
  }, [notificationData]);

  const handleNavigateChat = (item) => {
    setNotificationData("");
    setExistData(JSON.stringify(item[0]));


    navigate("/ChatWithSeller", {
      state: {
        data: item,
        existChat:true,
      },
    });
  };
  return (
    <div className="header-main-div">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <img
              src={marketplacelogo}
              className="logo_jtc"
              alt="LogoMarketPlace"
              onClick={() => navigate("/")}
            />
            <img
              src={sedan}
              className="logo_car"
              alt="CarImage"
              onClick={() => navigate("/")}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              {tokenid || DummyValueLogin ? (
                <>
                  {getStoreStatusByvalue === "true" ? (
                    <>
                      {getAddedStore == "true" ||
                        getStoreSuccessAdded == "true" ? (
                        <>
                          <Nav.Link
                            className="nav-link active cell_btn_Edit "
                            onClick={handleStore}
                          >
                            <AiFillEdit />
                            Edit Store
                          </Nav.Link>
                        </>
                      ) : (
                        <>
                          <Nav.Link
                            className="nav-link active cell_btn "
                            onClick={handleStore}
                          >
                            <i
                              className="fa fa-plus plus_icon_cell"
                              onClick={handleStore}
                            ></i>
                            Store
                          </Nav.Link>
                        </>
                      )}
                    </>
                  ) : null}

                  <Nav.Link
                    className="nav-link active cell_btn "
                    onClick={() => navigate("/postaid")}
                  >
                    <i
                      className="fa fa-plus plus_icon_cell"
                      onClick={() => navigate("/postaid")}
                    ></i>
                    Sell
                  </Nav.Link>
                  <div className="notification_icon_div">
                    <MdNotifications
                      className="notification_icon"
                      onClick={handleModel}
                    />
                    {!isChatWithSellerPath && notificationData?.length ? (
                      <div className="notification_number">
                        <p>{notificationData.length}</p>
                      </div>
                    ) : null}
                  </div>
                  {country?.length ? (
                    <>
                      {countryHeader.length ? (
                        <>
                          <div className="label-country-div-1">
                            <select
                              name="cars"
                              className="countryType header-dropdown"
                              value={countryId || sourceCountryId}
                              onChange={(e) => {
                                handleCountry(e);
                              }}
                            >
                              <option value="0">Select a Country</option>
                              {countryHeader?.map((country) => (
                                <option key={country.Id} value={country.Id}>
                                  {country.Name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  <NavDropdown
                    title={
                      <img
                        referrerPolicy="no-referrer"
                        src={googleLoginData?.picture}
                        alt="Profile Photo"
                        width="50px"
                        className="profile-photo"
                      />
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavLink
                      to="/profile"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      Profile
                    </NavLink>
                    <NavLink
                      to="/ChatWithSeller"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      Inbox
                    </NavLink>
                    <NavLink
                      to="/wishlist"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      Wishlist
                    </NavLink>

                    <NavLink
                      to="/dashboard"
                      className={({ isActive }) =>
                        isActive ? "active" : "inactive"
                      }
                    >
                      Dashboard
                    </NavLink>

                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Google />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        show={showNotification}
        onHide={closeNotificationModel}
        className="notification_model"
      >
        <Modal.Body>
          <div className="notification_heading_first">
            <p>Notifications</p>
            <GrClose className="cross-icon" onClick={closeNotificationModel} />
          </div>

          <div className="notification_data">
            {errorMessage === "Unread message not found" ||
              isChatWithSellerPath ? (
              <>
                <div className="no_notification_div">
                  <p>No Pending Notication!</p>
                </div>
              </>
            ) : (
              <>
                {showMessageData?.map((item, index) => {
                  return (
                    <div
                      className="notfication_list_first"
                      onClick={() => handleNavigateChat(item)}
                      key={index}
                    >
                      <div>
                        <img
                          src={item?.[0]?.From?.Photo}
                          alt=""
                          className="Advertise_image_profile"
                        />
                        <img
                          src={item?.[0]?.Ads?.Image}
                          alt=""
                          className="Advertise_image"
                        />
                      </div>
                      <div className="notification_name_message">
                        <p>{item?.[0]?.From?.DisplayName}</p>
                        <p>{item?.[0]?.MessageBody}</p>
                      </div>
                      <div className="notification_count">
                        <p>{item?.length}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* -----If user blocked than modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        id="logIN"
        className="modal_header_google"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-head-warning">
            <AiOutlineWarning className="google-block-icon" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body_content text-center">
          <p className="google-modal-block">Account is disable or inactive</p>
          <p className="google-modal-block">
            Please contact support for more info.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Header;

import React, { useEffect, useState } from "react";
import Loader from "../loader/loader";
import { useNavigate } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineHeart } from "react-icons/ai";
import jwt_decode from "jwt-decode";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import cross from "../../Images/cross.png";
import arrowDown from "../../Images/arrow-down.png";
import Select from "react-select";

const MainStoreResults = ({
  MyAds,
  isPageLoading,
  setIspageLoading,
  toggle,
  setToggle,
}) => {
  const navigate = useNavigate();
  const [vehicleIdOne, setVehicleIdOne] = useState();
  const [adsCategory, setAdsCategory] = useState();

  const vehicleSingleItem = (item) => {
    let productName;
    if (item?.Images?.[0]?.AccessoryId) {
      productName = item?.Name?.toLowerCase().replace(/\s/g, "-");
    }
    if (item?.Images?.[0]?.PartId) {
      productName = item?.Name?.toLowerCase().replace(/\s/g, "-");
    }

    if (item?.Images?.[0]?.VehicleId) {
      productName = item?.AdvertisementTitle?.toLowerCase().replace(/\s/g, "-");
    }

    const GetVehicleId = item.Id;

    let adsCategoryName;
    if (item?.AdsCategory == 1) {
      adsCategoryName = "Vehicle";
    } else if (item?.AdsCategory == 0) {
      adsCategoryName = "Parts";
    } else if (item.AdsCategory == 2) {
      adsCategoryName = "Accessories";
    }
    navigate(`/detail/${adsCategoryName}/${GetVehicleId}/${productName}`, {
      state: item,
    });
  };
  const sortedDataAds = MyAds?.sort((a, b) => a.Price - b.Price);
  // -----------Pagination----------------
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    setBlogPosts(sortedDataAds);
  }, [sortedDataAds]);

  const [currentPage, setCurrentPage] = useState(1);
  const [googleTokenId, setGoogleTokenId] = useState("");
  const [dummy, setDummy] = useState(false);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [postsPerPage, setPostPerPage] = useState(12);
  const totalPosts = blogPosts?.length;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts?.slice(indexOfFirstPost, indexOfLastPost);
  const lastPage = Math.ceil(blogPosts?.length / postsPerPage);
  const tokenid = JSON.parse(localStorage.getItem("tokenID"));

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const options = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
  ];
  const resultPerPage = (selectedOption) => {
    setPostPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const nextPage = () => {
    if (currentPage !== Math.ceil(blogPosts?.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage, postsPerPage]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const startResult = Math.min(indexOfFirstPost, totalPosts);
  const endResult = Math.min(indexOfLastPost, totalPosts);

  // --------google login------------------
  const onSuccess = (credentialResponse) => {
    const decoded = jwt_decode(credentialResponse.credential);
    localStorage.setItem("LoginData", JSON.stringify(decoded));

    const googleToken = credentialResponse.credential;
    setGoogleTokenId(googleToken);
    // setProfile(profile);
    setShow(false);
  };
  const onError = () => {};

  const loginData = async () => {
    try {
      setIspageLoading(true);
      const data = { tokenid: googleTokenId };
      const obj = { data: JSON.stringify(data) };
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
        },
        data: JSON.stringify(obj),
        url: `${Apiurl()}/Account/SignInWithGoogle`,
      };

      const response = await axios(option);
      setIspageLoading(false);
      const tokenId = response.data.data;
      localStorage.setItem("tokenID", JSON.stringify(tokenId));

      setDummy(true);

      // navigate("/profile");
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (googleTokenId) {
      loginData();
    }
  }, [googleTokenId]);

  // -----add to fav ----------
  const handleHeartClicked = (vehicleId, Adscategory, e) => {
    setVehicleIdOne(vehicleId);
    setAdsCategory(Adscategory);
    if (!tokenid) {
      handleShow();
      return;
    }
    setIspageLoading(true);
    const data = {};

    data.Id = vehicleId || vehicleIdOne;
    data.AdsCategory = Adscategory || adsCategory;
    data.IsFavorite = e === "liked" ? false : true;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Customer/Favorite-UnFavorite`,
    };
    axios(option)
      .then((e) => {
        setIspageLoading(false);
        setToggle(e);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
      })
      .catch((error) => {
        setIspageLoading(false);
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    if (dummy) {
      handleHeartClicked();
      setDummy(false);
    }
  }, [dummy]);
  return (
    <div>
      <div className="row result-div-page">
        {isPageLoading ? <Loader /> : null}
        {currentPosts?.length ? (
          <>
            {currentPosts?.map((item, index) => {
              const imageMain = item?.Images?.find((i) => i?.DefaultImage);
              const value = item?.Price;
              const vehicleId = item?.Id;
              const Adscategory = item?.AdsCategory;

              const valueprice = new Intl.NumberFormat("en-IN").format(value);
              return (
                <div key={index} className="col-sm-4">
                  <div className="cars_deal cars-fav-deal">
                    <a>
                      <img
                        src={imageMain?.UrlLink}
                        alt="Cars"
                        className="imgclassName"
                        onClick={(e) => vehicleSingleItem(item)}
                      />
                    </a>
                    <div className="cars_deal_wrap">
                      <div className="mb-2 heart-name">
                        <h5
                          className="paragraph-detail"
                          onClick={(e) => vehicleSingleItem(item)}
                        >
                          {item.Images?.[0]?.VehicleId ? (
                            <>{item?.AdvertisementTitle}</>
                          ) : (
                            <>{item?.Name}</>
                          )}
                        </h5>
                        <a className="likes_btn">
                          {item.IsFavorite === true ? (
                            <>
                              <AiFillHeart
                                onClick={() =>
                                  handleHeartClicked(
                                    vehicleId,
                                    Adscategory,
                                    "liked"
                                  )
                                }
                              />
                            </>
                          ) : (
                            <AiOutlineHeart
                              onClick={() =>
                                handleHeartClicked(
                                  vehicleId,
                                  Adscategory,
                                  "dislike"
                                )
                              }
                            />
                          )}
                        </a>
                      </div>
                      {item.Images?.[0]?.VehicleId ? (
                        <div className="mb-2">
                          <p className="paragraph-detail-vehicle">
                            Km Driven : {item?.Mileage}kms <br />
                            Fuel Type : {item?.FuelType} <br />
                            Transmission : {item?.Transmission}
                          </p>
                        </div>
                      ) : null}
                      <div className="mb-2">
                        <p className="paragraph-detail">{item?.Description}</p>
                      </div>
                      <h4>
                        ${valueprice}
                        {item.PriceType ? <>({item?.PriceType})</> : null}
                      </h4>
                      {/* <span>
                                    <img src={quality} alt="Quality" />
                                    Assured
                                  </span>
                                  <span className="regular_off mt-2">
                                    $2% Regular Off
                                  </span> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <p className="no-result-filter">No Result!</p>
        )}
        {currentPosts?.length ? (
          <>
            <nav
              aria-label="Page navigation example "
              className="pagination-botttom"
            >
              <div className="showing-para-pagination">
                <p>
                  Showing {startResult + 1} - {endResult} of {totalPosts}{" "}
                  results
                </p>
              </div>
              <div>
                <ul className="pagination result-pagination">
                  <li className="page-item">
                    <button
                      disabled={currentPage === 1 ? true : false}
                      className={`page-link ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={previousPage}
                    >
                      Previous
                    </button>
                  </li>
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      onClick={() => paginate(number)}
                      className={
                        "page-item" + (number === currentPage ? "active" : "")
                      }
                    >
                      <a className="page-link">{number}</a>
                    </li>
                  ))}

                  <li className="page-item">
                    <button
                      disabled={currentPage === lastPage ? true : false}
                      className={`page-link ${
                        currentPage === lastPage ? "disabled" : ""
                      }`}
                      onClick={nextPage}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
              <div className="result-per-page-div">
                <p>Results Per Page</p>
                <Select
                  options={options}
                  onChange={resultPerPage}
                  placeholder="Per Page"
                  menuPosition="fixed"
                  menuPlacement="top"
                />
              </div>
            </nav>
          </>
        ) : null}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-google"
        id="logIN"
      >
        <Modal.Body className="modal_body_content text-center">
          <div className="body_popup">
            <a
              className="close_button"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src={cross} alt="Cross" />
            </a>
            <h1>
              Sign in with <span>Google</span>
            </h1>
            <a>
              <img src={arrowDown} className="aroow_down" alt="arrowDown" />
            </a>
            {/* <button className="signin-butt-google" onClick={() => login()}>
              <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              
            </button> */}
            {/* <button className="signin-butt-google">
            <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              Login with Google{" "} */}
            <div className="googleLoginButt">
              <GoogleLogin
                theme="filled_blue"
                size="large"
                shape="circle"
                width="220px"
                logo_alignment="left"
                onSuccess={onSuccess}
                onError={onError}
                className="google-butt"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MainStoreResults;

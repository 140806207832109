import React, { Fragment, useEffect, useState } from "react";
import "../../../src/App.css";
import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";
import Loader from "../../Components/loader/loader";
import axios from "axios";
import { toast } from "react-toastify";
import { Apiurl, RemoveSession } from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";
import Select from "react-select";
import moment from "moment";

const Dashboard = () => {
  const [isPageLoading, setIspageLoading] = useState(false);
  const [MyAds, setMyAds] = useState([]);

  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [deleteId, setDeleteId] = useState();
  const [advertismentId, setAdvertisementId] = useState();
  const [adsCategory, setAdsCategory] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [deletedId, setDeletedId] = useState();
  const [deletedAdsId, setDeletedAdsId] = useState();
  const [deletedAdsCategory, setDeleteAdsCategory] = useState();
  const [deleteReasonText, setDeleteReasonText] = useState();

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(true);
  const handleClose = () => setShow(false);
  const [radioButtons, setRadioButtons] = useState();
  const [radioButtonsId, setRadioButtonsId] = useState("");
  const [newFilterData, setNewFilterData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [filteredAds, setFilteredAds] = useState([]);

  // ------------search filter---------------//
  useEffect(() => {
    if (newFilterData?.length) {
      const filterDataDel = newFilterData?.filter(
        (item) =>
          item?.MarkDelete === false &&
          item?.Description?.toLowerCase().includes(searchValue.toLowerCase()) || 
          item?.Images?.[0]?.Type?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.AdvertisementTitle?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.Brand?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.Name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.PackagePlan?.Name?.toLowerCase().includes(searchValue.toLowerCase())||
          item?.Model?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.Variant?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.Type?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.Transmission?.toLowerCase().includes(searchValue.toLowerCase()) 
        

      );
      setFilteredAds(filterDataDel);
    }
  }, [newFilterData, searchValue]);

  const validateFields = () => {
    if (!radioButtonsId) {
      toast.error("Please select a reason", {
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  // -----------Radio Buttons data--------
  const GetRadioButonsData = (e) => {
    const data = {};
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Master/DeleteAdsOptions`,
    };
    axios(option)
      .then((e) => {
        setRadioButtons(JSON.parse(e.data.data));
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetRadioButonsData();
  }, []);

  const handleShow = (e, DeleteId, AdsId, AdsCategory) => {
    setDeletedId(DeleteId);
    setDeletedAdsId(AdsId);
    setDeleteAdsCategory(AdsCategory);
    setShow(true);
  };
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(false);

  const handleShowModal = (item) => {
    setSelectedImage(item);
    setShow2(true);
  };

  const currentDate = new Date();

  const navigate = useNavigate();
  const GetMyAdsData = (e) => {
    const data = {};
    setIspageLoading(true);

    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: JSON.stringify(data),
      url: `${Apiurl()}/Seller/GetMyAds`,
    };
    axios(option)
      .then((e) => {
        if (e.data.data === "[]") {
          setIspageLoading(false);
          handleShow1();
        } else {
          setIspageLoading(false);
          const MyAdsDataapi = JSON.parse(e.data.data);
          MyAdsDataapi.sort(
            (a, b) => new Date(b.PublishDate) - new Date(a.PublishDate)
          );
          setMyAds(MyAdsDataapi);
        }
      })
      .catch((error) => {
        setIspageLoading(false);
        if (error?.code === "ERR_NETWORK") {
          RemoveSession();
          navigate("/");
          toast.error("Session Expired, Please login again", {
            theme: "colored",
          });
        }
        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    if (MyAds?.length) {
      const filterDataDel = MyAds?.filter((item) => item?.MarkDelete === false);
      setNewFilterData(filterDataDel);
    }
  }, [MyAds]);
  // -----Republish----------------//
  const RepublishAd = (MyAds) => {
    setIspageLoading(true);
    const data = {};
    data.Id = MyAds?.Id;

    data.AdsCategory = MyAds?.AdsCategory;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Seller/RepublishAd`,
    };
    axios(option)
      .then((e) => {
        // handleClose();
        GetMyAdsData();
        setIspageLoading(false);
        toast.success(e?.data?.message, {
          theme: "colored",
        });
      })
      .catch((error) => {
        setIspageLoading(false);

        toast.error(error?.response?.data?.message, {
          theme: "colored",
        });
      });
  };
  // -----------Delete Aid------------------//
  const DeleteAid = () => {
    if (!validateFields()) return;
    setIspageLoading(true);
    // setDeleteId(DeleteId);
    // setAdvertisementId(AdsId);
    // setAdsCategory(AdsCategory);
    const data = {};
    data.Id = deletedId;
    data.AdvertisementId = deletedAdsId;
    data.AdsCategory = deletedAdsCategory;
    data.DeleteAdOption = radioButtonsId - 1;
    data.MarkDelete = true;
    data.DeleteDate = new Date();
    data.OtherReason = deleteReasonText;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        Authorization: `Bearer ${tokenid}`,
      },
      data: { data: JSON.stringify(data) },
      url: `${Apiurl()}/Seller/DeleteAds`,
    };
    axios(option)
      .then((e) => {
        handleClose();
        GetMyAdsData();
        setIspageLoading(false);
        toast.success(e.data.message, {
          theme: "colored",
        });
      })
      .catch((error) => {
        setIspageLoading(false);

        toast.error(error.response.data.message, {
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    GetMyAdsData();
  }, []);
  const EditVehicleData = (MyAds) => {
    navigate("/postaid", { state: MyAds });
  };

  const handleNavigate = () => {
    navigate("/postaid");
  };

  const handleOptionChange = (radio) => {
    setRadioButtonsId(radio.Id + 1);
  };
  // -----------Pagination----------------
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    setBlogPosts(filteredAds);
  }, [filteredAds]);

  const [currentPage, setCurrentPage] = useState(1);
  const [googleTokenId, setGoogleTokenId] = useState("");

  const [postsPerPage, setPostPerPage] = useState(12);
  const totalPosts = blogPosts?.length;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts?.slice(indexOfFirstPost, indexOfLastPost);
  const lastPage = Math.ceil(blogPosts?.length / postsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(blogPosts?.length / postsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const options = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
  ];
  const resultPerPage = (selectedOption) => {
    setPostPerPage(selectedOption.value);
    setCurrentPage(1);
  };
  const startResult = Math.min(indexOfFirstPost, totalPosts);
  const endResult = Math.min(indexOfLastPost, totalPosts);

  return (
    <div className="dashboard-head">
      {isPageLoading ? <Loader /> : null}
      <main className="main_wrapper">
        <Header />
        <div className="container">
          <section className="dashboard_start">
          <div className="search_dash_div">
          <label htmlFor="">
                {/* <label for="site-search">Search here...</label> */}
                <input
                placeholder="Search here..."
                  type="search"
                  id="site-search"
                  name="q"
                  className="search_input_dash"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </label>
          </div>
            {currentPosts?.length ?<>
              <div className="dashboard-main-div">
             
             <div className="dashboard_table">
               <table className="table table-bordered table-striped table-responsive d-block dash-table">
                 {currentPosts?.length > 0 ? (
                   <>
                     <thead>
                       <tr>
                         <th>Add Image </th>
                         <th>Description</th>
                         <th>Category</th>
                         <th>Publish Date</th>
                         <th>End Date</th>
                         <th>Plan</th>
                         <th>Status</th>
                         <th>Delete Ad </th>
                         <th>Re-Publish Ad</th>
                         <th>Edit Ad</th>
                       </tr>
                     </thead>
                   </>
                 ) : null}
                 <tbody>
                   {currentPosts?.map((MyAds, index) => {
                     const DeleteId = MyAds.Id;
                     const data = MyAds.data;
                     const AdsId = MyAds.AdvertisementId;
                     const AdsCategory = MyAds.AdsCategory;
                     const endDate = new Date(MyAds.EndDate);
                     const formattedDateEnd = moment(
                       endDate,
                       "YYYY-MM-DD HH:mm:ss"
                     )
                       .add({ hours: 12, minutes: 30 })
                       .format("YYYY-MM-DD HH:mm:ss");
                     const isButtonDisabled = currentDate <= endDate;
                     const messageDate = new Date(MyAds?.PublishDate);
                     const originalDate = messageDate;
                     const formattedDate = moment(
                       originalDate,
                       "YYYY-MM-DD HH:mm:ss"
                     )
                       .add({ hours: 12, minutes: 30 })
                       .format("YYYY-MM-DD HH:mm:ss");

                     return MyAds.Seller.Id ? (
                       <tr key={index}>
                         <td>
                           {MyAds.Images?.filter(
                             (item) => item.DefaultImage === true
                           )?.map((item, index) => (
                             <img
                               className="dashboard-image"
                               key={index}
                               src={item.UrlLink}
                               alt=""
                               onClick={() => handleShowModal(item)}
                             />
                           ))}
                         </td>
                         <td className="table-data-width ">
                           {MyAds.Description?.length > 80
                             ? MyAds.Description.substring(0, 80) + "..."
                             : MyAds.Description}
                         </td>
                         <td className="table-data-width">
                           {MyAds.Images[0]?.Type}
                         </td>
                         <td className="table-data-width">
                           <div>
                             <div>
                               {new Date(formattedDate).toLocaleDateString(
                                 "en-GB",
                                 {
                                   day: "numeric",
                                   month: "long",
                                   year: "numeric",
                                 }
                               )}
                             </div>
                             <div>
                               {new Date(formattedDate).toLocaleTimeString(
                                 [],
                                 {
                                   hour: "2-digit",
                                   minute: "2-digit",
                                 }
                               )}
                             </div>
                           </div>
                         </td>
                         <td className="table-data-width">
                           <div>
                             <div>
                               {new Date(formattedDateEnd).toLocaleDateString(
                                 "en-GB",
                                 {
                                   day: "numeric",
                                   month: "long",
                                   year: "numeric",
                                 }
                               )}
                             </div>
                             <div>
                               {new Date(formattedDateEnd).toLocaleTimeString(
                                 [],
                                 {
                                   hour: "2-digit",
                                   minute: "2-digit",
                                 }
                               )}{" "}
                             </div>
                           </div>
                         </td>
                         <td className="table-data-width">
                           {MyAds?.PackagePlan?.Name}
                         </td>
                         <td className="table-data-width">
                           {MyAds?.Status === 0
                             ? "Inactive"
                             : MyAds?.Status === 1
                             ? "Active"
                             : MyAds?.Status === 2
                             ? "Sold"
                             : MyAds?.Status === 3
                             ? "NoPublished"
                             : "Expired"}
                         </td>
                         <th>
                           <button
                             type="button"
                             className={
                               MyAds.MarkDelete === true || MyAds.Status === 0
                                 ? "disableButt"
                                 : "btn btn-danger dashboard-butt "
                             }
                             disabled={MyAds.MarkDelete === true}
                             onClick={(e) =>
                               handleShow(e, DeleteId, AdsId, AdsCategory)
                             }
                           >
                             Delete
                           </button>
                         </th>
                         <th>
                           <button
                             type="button"
                             className={
                               MyAds.CanRePublish === false
                                 ? "disableButt-republish"
                                 : "btn btn-success dashboard-butt"
                             }
                             disabled={MyAds.CanRePublish === false}
                             onClick={() => RepublishAd(MyAds)}
                           >
                             Re-Publish
                           </button>
                         </th>
                         <th>
                           <button
                             type="button"
                             className={
                               MyAds.MarkDelete === true || MyAds.Status === 0
                                 ? "disableButt-edit"
                                 : "btn btn-warning dashboard-butt"
                             }
                             disabled={MyAds.MarkDelete === true}
                             onClick={() => EditVehicleData(MyAds)}
                           >
                             Edit
                           </button>
                         </th>
                       </tr>
                     ) : null;
                   })}
                 </tbody>
               </table>
             </div>
           </div>
            </> : 
            <>
            <p className="p_tag_for">No search results</p>
            </>
            }
            {currentPosts?.length ? (
              <>
                <nav
                  aria-label="Page navigation example "
                  className="pagination-botttom"
                >
                  <div className="showing-para-pagination">
                    <p>
                      Showing {startResult + 1} - {endResult} of {totalPosts}{" "}
                      results
                    </p>
                  </div>
                  <div>
                    <ul className="pagination result-pagination">
                      <li className="page-item">
                        <button
                          disabled={currentPage === 1 ? true : false}
                          className={`page-link ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={previousPage}
                        >
                          Previous
                        </button>
                      </li>
                      {pageNumbers?.map((number) => (
                        <li
                          key={number}
                          onClick={() => paginate(number)}
                          className={
                            "page-item" +
                            (number === currentPage ? "active" : "")
                          }
                        >
                          <a className="page-link">{number}</a>
                        </li>
                      ))}

                      <li className="page-item">
                        <button
                          disabled={currentPage === lastPage ? true : false}
                          className={`page-link ${
                            currentPage === lastPage ? "disabled" : ""
                          }`}
                          onClick={nextPage}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="result-per-page-div">
                    <p>Results Per Page</p>
                    <Select
                      options={options}
                      onChange={resultPerPage}
                      placeholder="Per Page"
                      menuPosition="fixed"
                      menuPlacement="top"
                    />
                  </div>
                </nav>
              </>
            ) : null}
          </section>
        </div>
        <Footer />
      </main>
      <Modal show={show1} className="dashboard-modal">
        <Modal.Header>
          <Modal.Title className="modal-head-warning">
            <RiErrorWarningLine />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-data">
          <div className="modal-body-aid">
            <p>No Ad Posted</p>
          </div>
          <div className="modal-body-button">
            <Button
              variant="secondary"
              onClick={handleNavigate}
              className="btn-danger"
            >
              Go To Sell
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {selectedImage ? (
        <Modal
          className="image-modal-dashboard"
          show={show2}
          onHide={() => setSelectedImage(null)}
        >
          <Modal.Body className="dashboard-modal-body-img">
            <ImCross
              onClick={() => handleShow2()}
              className="delete-image-icon-dashboard delete-image-icon-delete"
            />
            {/* <TiDelete
              className="delete-image-dashboard "
              onClick={() => handleShow2()}
            /> */}
            <img
              className="dashboard-popup-img"
              src={selectedImage.UrlLink}
              alt=""
            />
          </Modal.Body>
        </Modal>
      ) : null}

      <Modal show={show} onHide={handleClose} className="dashboard-modal">
        <Modal.Header closeButton>
          <Modal.Title className="modal-head-warning">
            <RiErrorWarningLine />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-data">
          <div className="modal-head-para">
            <p>Please select an option :-</p>
          </div>
          {radioButtons?.map((radio, index) => {
            return (
              <Fragment key={index}>
                <div className="modal-body-radio">
                  <label>
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="HTML"
                      onChange={() => handleOptionChange(radio)}
                    />
                    {radio.Name}
                  </label>
                </div>
              </Fragment>
            );
          })}
          <div>
            {radioButtonsId === 5 ? (
              <>
                <div className="modal-body-radio">
                  <input
                    type="text"
                    id="otherOption"
                    placeholder="Enter other option"
                    onChange={(e) => setDeleteReasonText(e.target.value)}
                  />
                </div>
              </>
            ) : null}
          </div>
          {/* <div className="modal-body-aid">
            <p>Are you sure?</p>
          </div> */}
          <div className="modal-body-button">
            <Button
              className="btn-danger"
              variant="secondary"
              onClick={() => DeleteAid()}
            >
              Delete Ad
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Dashboard;

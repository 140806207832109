import React, { useEffect, useState } from "react";

import googleSignIn from "../../Images/google-signin.png";
import cross from "../../Images/cross.png";
import arrowDown from "../../Images/arrow-down.png";
import "../../../src/App.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import { Apiurl } from "../../Utils/Utils";
import Loader from "../loader/loader";
import { AiOutlineWarning } from "react-icons/ai";

const Google = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [googleTokenId, setGoogleTokenId] = useState("");
  const [isPageLoading, setIspageLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const onSuccess = (credentialResponse) => {
    const decoded = jwt_decode(credentialResponse.credential);
    localStorage.setItem("LoginData", JSON.stringify(decoded));

    const googleToken = credentialResponse.credential;
    setGoogleTokenId(googleToken);
    setProfile(profile);
    setShow(false);

  };
  const onError = () => {};

  const loginData = async () => {
    try {
      setIspageLoading(true);
      const data = { tokenid: googleTokenId };
      const obj = { data: JSON.stringify(data) };
      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
        },
        data: JSON.stringify(obj),
        url: `${Apiurl()}/Account/SignInWithGoogle`,
      };

      const response = await axios(option);
      setIspageLoading(false);
      const tokenId = response.data.data;

      localStorage.setItem("tokenID", JSON.stringify(tokenId));
      navigate("/profile");
    } catch (error) {
      setIspageLoading(false);
      const errorMessgae=error.response.data.data ;

      if (errorMessgae?.active === false) {
        handleShow2();
      }

      // toast.error(error.response.data.message,{
      //   theme: "colored",
      //
      // })
    }
  };

  useEffect(() => {
    if (googleTokenId) {
      loginData();
    }
  }, [googleTokenId]);

  return (
    <>
      {isPageLoading ? <Loader /> : null}

      <li className="nav-item">
        <button
          className="nav-link p-0"
          data-bs-toggle="modal"
          data-bs-target="#logIN"
          onClick={handleShow}
        >
          <img src={googleSignIn} alt="GoogleSignIn" />
        </button>
      </li>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-google"
        id="logIN"
      >
        <Modal.Body className="modal_body_content text-center">
          <div className="body_popup">
            <a
              className="close_button"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <img src={cross} alt="Cross" />
            </a>
            <h1>
              Sign in with <span>Google</span>
            </h1>
            <a>
              <img src={arrowDown} className="aroow_down" alt="arrowDown" />
            </a>
            {/* <button className="signin-butt-google" onClick={() => login()}>
              <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              
            </button> */}
            {/* <button className="signin-butt-google">
            <img
                className="signin-butt-google-image"
                src={googleicon}
                alt=""
              />
              Login with Google{" "} */}
            <div className="googleLoginButt">
              <GoogleLogin
                theme="filled_blue"
                size="large"
                shape="circle"
                width="220px"
                logo_alignment="left"
                onSuccess={onSuccess}
                onError={onError}
                className="google-butt"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* -----If user blocked than modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        id="logIN"
        className="modal_header_google"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-head-warning">
            <AiOutlineWarning className="google-block-icon" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body_content text-center">
          <p className="google-modal-block">Account is disable or inactive</p>
          <p className="google-modal-block">
            Please contact support for more info.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Google;

import React, { useEffect, useState } from "react";
import "../../../src/App.css";
import { ImCross } from "react-icons/im";
import Resizer from "react-image-file-resizer";
import { Cloudinary, Transformation } from "@cloudinary/url-gen";
import { opacity } from "@cloudinary/url-gen/actions/adjust";
import { source } from "@cloudinary/url-gen/actions/overlay";
import { Position } from "@cloudinary/url-gen/qualifiers";
import { compass } from "@cloudinary/url-gen/qualifiers/gravity";
import { text } from "@cloudinary/url-gen/qualifiers/source";
import { TextStyle } from "@cloudinary/url-gen/qualifiers/textStyle";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Loader from "../loader/loader";
import axios from "axios";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import Location from "./List/Location";
import List from "./List/List";
import { useNavigate } from "react-router-dom";
import AccessoryList from "./List/AccessoryList";
import { Apiurl, RemoveSession } from "../../Utils/Utils";

const Accessories = ({ AccessorieseditLocation }) => {
  const [accessoriesType, setAccesoriesType] = useState("");
  const [accessoriesName, setAccessoriesName] = useState("");
  const [accessoriesTitle, setAccessoriesTitle] = useState("");
  const [accessoriesDescription, setAccessoriesDescription] = useState("");
  const [showAccessoriesImage, setShowAccessoriesImage] = useState([]);
  const [accessoriesSelectedImage, setAccessoriesSelectedImage] = useState("");
  const [accessoriesImages, setAccessoriesImages] = useState([]);
  const [isPageLoading, setIspageLoading] = useState(false);
  const [accessoriesSelectedFile, setAccessoriesSelectedFile] = useState([]);
  const [accessoriesImageName, setAccessoriesImageName] = useState(""); //---
  const [accessoriesImageSrc, setAccessoriesImagesSrc] = useState([]);
  const [accessoriesBase64id, setAccessoriesBase64id] = useState(""); //---
  const [accessoriesImageFormat, setAccessoriesImageFormat] = useState("");
  const [AccessoriesPrice, setAccessoriesPrice] = useState("");
  const [accessoriesPriceState, setAccessoriesPriceState] = useState("");
  const [Accessoriescity, setAccessoriessetCity] = useState("");
  const [accessoryneighbourhood, setAccessoryNeighbourhood] = useState("");
  const [countryId, setCountryId] = useState();
  const [country, setCountry] = useState([]);
  const [accessorystate, setAcessoryState] = useState();
  const [oldbrandId, setOldBrandId] = useState();
  const [oldStateid, setOldStateId] = useState();
  const [AccessoryUserId, setAccessoryUserId] = useState();
  const [dummyDataImage, setDummyDataImg] = useState();
  const [dummyImageValue, setDummyImageValue] = useState();

  const [userSelectedDefaultImage, setUserSelectedDefaultImage] =
    useState(false);
  const [trueImageData, setTrueImageData] = useState();

  const tokenid = JSON.parse(localStorage.getItem("tokenID"));
  const [oneImagTrue, setOneImageTrue] = useState();
  const [dummyTest, setDummyTest] = useState();

  useEffect(() => {
    if (AccessorieseditLocation) {
      if (!dummyDataImage && showAccessoriesImage?.length > 1) {
        setUserSelectedDefaultImage(true);
      }
    }
  }, [dummyDataImage, showAccessoriesImage]);

  useEffect(() => {
    if (dummyImageValue === true) {
      setUserSelectedDefaultImage(true);
    }
  }, [dummyImageValue]);

  useEffect(() => {
    if (dummyTest?.length === 1) {
      setUserSelectedDefaultImage(false);
    }
  }, [dummyTest]);

  //------------------------------
  const Editimage = AccessorieseditLocation?.Images;
  useEffect(() => {
    if (Editimage) {
      const gettrueData = Editimage?.find(
        (item) => item?.DefaultImage === true
      );
      setTrueImageData(gettrueData?.UrlLink);
      setDummyDataImg(gettrueData);
      setUserSelectedDefaultImage(false);

      const accessoriesImageSrc = Editimage?.map((img) => ({
        imageId: img.Id,
        imageName: img.FileName || "",
        imageUrl: img.UrlLink,
        imageFormat: img.ImageExtenssion,
        base64id: "",
        MarkDelete: false,
      }));
      setAccessoriesImagesSrc(accessoriesImageSrc);
      setShowAccessoriesImage(accessoriesImageSrc);
    }
  }, [AccessorieseditLocation, Editimage]);

  useEffect(() => {
    setAccesoriesType(AccessorieseditLocation?.Type);
    setAccessoriesName(AccessorieseditLocation?.Name);
    setAccessoriesTitle(AccessorieseditLocation?.AdvertisementTitle);
    setAccessoriesDescription(AccessorieseditLocation?.Description);
    setAccessoriesPriceState(AccessorieseditLocation?.PriceType);
    setAccessoriesPrice(AccessorieseditLocation?.Price);
    setAccessoryNeighbourhood(AccessorieseditLocation?.Neighbourhood);
    setAccessoryUserId(AccessorieseditLocation?.Id);
    setAccessoriessetCity(AccessorieseditLocation?.City);
    setAcessoryState(AccessorieseditLocation?.State?.Id);
    const typeExternalData = accessoriesType;
    if (typeExternalData === "External") {
      setAccesoriesType(0);
    } else {
      setAccesoriesType(1);
    }
  }, [AccessorieseditLocation]);

  useEffect(() => {
    if (country) {
      const selectedCountry = country?.find(
        (c) => c.Name === AccessorieseditLocation?.Country?.Name
      );
      const oldstate = setOldStateId(AccessorieseditLocation?.State?.Id);
      if (selectedCountry) {
        setCountryId(selectedCountry.Id);
      }
    }
  }, [country, AccessorieseditLocation?.Country]);

  const navigate = useNavigate();
  // -----------------getting user location---------------/
  const [location, setLocation] = useState({
    state: "",
    city: "",
    neighborhood: "",
  });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }, []);

  const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const state = data.address.city_district;
        const city = data.address.city;
        const neighborhood = data.address.neighbourhood;

        setLocation({
          state: state,
          city: city,
          neighborhood: neighborhood,
        });
      })
      .catch((error) => {});
  };

  // --------Upload Image and compress it------------//

  const handleAccessoriesImageUpload = (event) => {
    setTrueImageData("");
    setDummyDataImg("");
    const accessoriesFiles = event.target.files;
    if (
      showAccessoriesImage?.length === 1 &&
      event.target.files?.length === 1
    ) {
      setDummyImageValue(true);
    } else if (event.target.files.length > 1) {
      setDummyImageValue(false);
    }
    if (event.target.files.length > 1) {
      setUserSelectedDefaultImage(true);
    }

    if (accessoriesFiles.length === 0) {
      setIspageLoading(false);
      return;
    }
    const newFiles = [...accessoriesSelectedFile, ...accessoriesFiles];
    setIspageLoading(true);

    if (newFiles.length > 20) {
      toast.error("You can select up to 20  only.", {
        theme: "colored",
      });
      setIspageLoading(false);
      return;
    }

    setAccessoriesSelectedFile(newFiles);
    const newImages = [];
    for (let i = 0; i < accessoriesFiles.length; i++) {
      const file = accessoriesFiles[i];
      setAccessoriesImageName(file.name);

      Resizer.imageFileResizer(
        file,
        700,
        700,
        "JPEG",
        100,
        0,
        (uri) => {
          newImages.push(uri);
          if (newImages.length === accessoriesFiles.length) {
            setAccessoriesImages(newImages);
            accessoriesUploadImages(newImages);
          }
        },
        "base64",
        0.1
      );
    }
  };
  const handleImageSelection = (imageUrl) => {
    setTrueImageData("");
    setAccessoriesSelectedImage(imageUrl);
    setUserSelectedDefaultImage(false);
  };

  // -----------Upload to Cloudinary and then fetch it--------//
  const accessoriesUploadImages = async (images) => {
    let imgArray = [...accessoriesImageSrc];
    let multiplePartImage = [...showAccessoriesImage];
    for (const image of images) {
      const body = new FormData();
      body.append("upload_preset", "z5yxtsvv");
      body.append("file", image);
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dmrw6iq4q/image/upload",
        {
          method: "POST",
          body,
        }
      ).then((r) => r.json());
      const myImage = cld.image(response.public_id);
      myImage
        .overlay(
          source(
            text("JTC Marketplace", new TextStyle("arial", 17))
              .textColor("#ffffff")
              .transformation(
                new Transformation()
                  .adjust(opacity(100))
                  .backgroundColor("#08080898")
                  .adjust(opacity(30))
                  .roundCorners(byRadius(10))
              )
          ).position(new Position().gravity(compass("center")).offsetY(5))
        )
        .format("png");
      const myUrl = myImage.toURL();
      imgArray.push(myUrl);
      multiplePartImage.push(myUrl);
      // --------------converting image into base 64--------------------------//
      const imageUrl = myUrl;
      // Get the image data using fetch
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Convert the image data to a base64 string
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            const base64String = reader.result;

            const modifiedBase64Content = base64String.replace(
              /^data:image\/png;base64,/,
              ""
            );

            // Update the image name and base64 content for this image
            const accessoriesImageName = response.public_id;
            const accessoriesImageFormat = response.format;
            const accessoriesBase64id = modifiedBase64Content;
            setAccessoriesBase64id(accessoriesBase64id);
            setAccessoriesImageFormat(accessoriesImageFormat);
            // Add the image object to the state
            setAccessoriesImagesSrc((prevState) => [
              ...prevState,
              {
                imageUrl,
                accessoriesImageName,
                accessoriesImageFormat,
                accessoriesBase64id,
              },
            ]);

            setShowAccessoriesImage(multiplePartImage);

            setOneImageTrue(multiplePartImage?.[0]);
          };
        });
    }
    setIspageLoading(false);
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dmrw6iq4q",
    },
  });

  // --------------delete uploaded Image--------------------------//
  const deleteItem = (e, index) => {
    const datan = accessoriesImageSrc?.map((item) =>
      item?.imageId
        ? item?.imageId === e?.imageId
          ? { ...item, MarkDelete: "true" }
          : item
        : item
    );
    const newdata = datan?.filter((item) =>
      item?.imageId
        ? item
        : item?.accessoriesImageName !== e?.accessoriesImageName
    );
    const preview = newdata.filter(
      (item) =>
        item?.MarkDelete === false || item?.accessoriesImageName?.length > 0
    );

    setAccessoriesImagesSrc(newdata);
    setShowAccessoriesImage(preview);
    setAccessoriesSelectedFile(preview);
    setDummyTest(preview);
    if (
      e?.imageUrl === trueImageData ||
      e?.imageUrl === accessoriesSelectedImage ||
      e === trueImageData ||
      e === accessoriesSelectedImage
    ) {
      setUserSelectedDefaultImage(true);
    }
  };

  // -----------------------------
  const handlePriceChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    value = new Intl.NumberFormat("en-IN").format(value);
    setAccessoriesPrice(value);
  };
  // -------------------------
  const AccessoriesImages = accessoriesImageSrc.map((img) => {
    const filterUrl = dummyTest?.filter(
      (item) => item.imageUrl === img.imageUrl
    );
    const imageObj = {
      ImageExtenssion: img.accessoriesImageFormat,
      Type: "Accessories",
      FileName: img.accessoriesImageName,
      DefaultImage:
      
        (showAccessoriesImage?.length === 1 && img.imageUrl === oneImagTrue) ||
        img.imageUrl === accessoriesSelectedImage ||
        img.imageUrl === trueImageData ||
        (dummyTest?.length === 1 && img.imageUrl === filterUrl?.[0]?.imageUrl),
      Base64Content: img.accessoriesBase64id,
    };

    if (img?.imageId) {
      imageObj.Id = img.imageId;
    }
    if (img.MarkDelete !== undefined) {
      imageObj.MarkDelete = img.MarkDelete;
    }
    return imageObj;
  });

  // -------------validation------------------
  // const hasSelectedImage = AccessoriesImages.find(
  //   (item) => item.DefaultImage === true
  // );
  const Validate = () => {
    if (!accessoriesType) {
      toast.error("Please Select AccessoriesType", {
        theme: "colored",
      });
      return false;
    }
    if (!accessoriesName) {
      toast.error("Please Fill Accessories Name", {
        theme: "colored",
      });
      return false;
    }
    if (!accessoriesTitle) {
      toast.error("Please Fill Accessories Title", {
        theme: "colored",
      });
      return false;
    }
    if (!accessoriesDescription) {
      toast.error("Please Fill Accessories Description", {
        theme: "colored",
      });
      return false;
    }
    if (!accessoriesPriceState) {
      toast.error("Please Select Accessories Price Category", {
        theme: "colored",
      });
      return false;
    }
    if (!AccessoriesPrice) {
      toast.error("Please Fill Accessories Price ", {
        theme: "colored",
      });
      return false;
    }
    if (accessoriesImageSrc.length === 0) {
      toast.error("Please Select Images To Upload", {
        theme: "colored",
      });
      return false;
    }
    if (userSelectedDefaultImage === true) {
      toast.error("Please select a default image", {
        theme: "colored",
      });
      return false;
    }
    if (!countryId) {
      toast.error("Please select country ", {
        theme: "colored",
      });
      return false;
    }
    if (countryId === "Select") {
      toast.error("Please select country", {
        theme: "colored",
      });
      return false;
    }
    if (!accessorystate) {
      toast.error("please select state", {
        theme: "colored",
      });
      return false;
    }
    if (!Accessoriescity) {
      toast.error("Please Fill city", {
        theme: "colored",
      });
      return false;
    }
    if (!accessoryneighbourhood) {
      toast.error("Please Fill Neighbourhood", {
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  const handleAccesoriesSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!Validate()) return;
      setIspageLoading(true);
      const data = {};
      data.TypeId = accessoriesType;
      data.Name = accessoriesName;
      data.AdvertisementTitle = accessoriesTitle; //---
      data.Description = accessoriesDescription;
      data.PriceType = accessoriesPriceState; //---
      data.Price = AccessoriesPrice;
      data.AccesoryImages = AccessoriesImages;
      data.CountryId = countryId;
      data.StateId = accessorystate;
      data.City = Accessoriescity;
      data.Neighbourhood = accessoryneighbourhood;

      

      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },

        url: `${Apiurl()}/Seller/PostAccesoryAd`,
      };

      const response = await axios(option);
      setAccesoriesType("");
      setAccessoriesName("");
      setAccessoriesTitle("");
      setAccessoriesDescription("");
      setAccessoriesPriceState("");
      setAccessoriesPrice("");
      setAccessoriesImages("");
      setCountryId("");
      setAcessoryState("");
      setAccessoriessetCity("");
      setAccessoryNeighbourhood("");
      setIspageLoading(false);
      navigate("/dashboard");

      toast.success("Ad Submitted Successfully", {
        theme: "colored",
      });
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  //-------------------------------

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      if (!Validate()) return;
      setIspageLoading(true);
      const data = {};
      data.Id = JSON.stringify(AccessoryUserId);
      data.TypeId = accessoriesType;
      data.Name = accessoriesName;
      data.AdvertisementTitle = accessoriesTitle; //---
      data.Description = accessoriesDescription;
      data.PriceType = accessoriesPriceState; //---
      data.Price = AccessoriesPrice;
      data.AccesoryImages = AccessoriesImages;
      data.CountryId = countryId;
      data.StateId = accessorystate;
      data.City = Accessoriescity;
      data.Neighbourhood = accessoryneighbourhood;

      const option = {
        method: "POST",
        headers: {
          "access-control-allow-origin": "*",
          "content-type": "application/json",
          Authorization: `Bearer ${tokenid}`,
        },
        data: { data: JSON.stringify(data) },

        url: `${Apiurl()}/Seller/UpdateAccesoryAd`,
      };

      const response = await axios(option);
      setAccesoriesType("");
      setAccessoriesName("");
      setAccessoriesTitle("");
      setAccessoriesDescription("");
      setAccessoriesPriceState("");
      setAccessoriesPrice("");
      setAccessoriesImages("");
      setCountryId("");
      setAcessoryState("");
      setAccessoriessetCity("");
      setAccessoryNeighbourhood("");
      setIspageLoading(false);
      navigate("/dashboard");

      toast.success("Ad Submitted Successfully", {
        theme: "colored",
      });
    } catch (error) {
      setIspageLoading(false);

      toast.error(error.response.data.message, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="main-all-div-post">
      {isPageLoading ? <Loader /> : null}

      <div className="main_border postAdd-3">
        <div className="data_Category mt-3">
          <h2 className="text-center fw-bold mb-4">Post Your Ad</h2>
          <h3 className="selct_hding mb-4">Select Category</h3>
          <form>
            <div className="mb-4 radio--buttons">
              <label className="brnd_label mb-3">
                Accessories Type<span className="required">*</span>
              </label>
              <div className="parts-input-type">
                <input
                  label="Internal"
                  type="radio"
                  name="Transmission"
                  value="-1"
                  defaultChecked={AccessorieseditLocation?.Type === "Internal"}
                  onChange={(e) => setAccesoriesType(e.target.value)}
                />
                <input
                  label="External"
                  type="radio"
                  name="Transmission"
                  value="-2"
                  defaultChecked={AccessorieseditLocation?.Type === "External"}
                  onChange={(e) => setAccesoriesType(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Accessories Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control type_input"
                required
                value={accessoriesName}
                onChange={(e) => setAccessoriesName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Ad Title<span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control type_input"
                required
                value={accessoriesTitle}
                onChange={(e) => setAccessoriesTitle(e.target.value)}
              />
              <p className="mntion_key">
                Mention the key features of your item (e.g. brand, model, age,
                type)
              </p>
            </div>
            <div className="mb-4">
              <label className="brnd_label mb-3">
                Ad Description<span className="required">*</span>
              </label>
              <textarea
                id="w3review"
                name="w3review"
                rows="7"
                cols="50"
                className="type_input"
                value={accessoriesDescription}
                onChange={(e) => setAccessoriesDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="data_Category mb-4">
              <h2 className="selct_hding">Set A Price</h2>
              <label className="brnd_label mb-3">
                Price Category<span className="required">*</span>
              </label>
              <div className="buttons">
                <div className="parts-input-type">
                  <input
                    label="Per Piece"
                    type="radio"
                    name="price"
                    value="Per Piece"
                    defaultChecked={
                      AccessorieseditLocation?.PriceType === "Per Piece"
                    }
                    onClick={(e) => setAccessoriesPriceState(e.target.value)}
                  />
                  <input
                    label="Per Pair"
                    type="radio"
                    name="price"
                    value="Per Pair"
                    defaultChecked={
                      AccessorieseditLocation?.PriceType === "Per Pair"
                    }
                    onClick={(e) => setAccessoriesPriceState(e.target.value)}
                  />
                  <input
                    label="Per Set"
                    type="radio"
                    name="price"
                    value="Per Set"
                    defaultChecked={
                      AccessorieseditLocation?.PriceType === "Per Set"
                    }
                    onClick={(e) => setAccessoriesPriceState(e.target.value)}
                  />
                  <input
                    label="Bulk"
                    type="radio"
                    name="price"
                    value="Bulk "
                    defaultChecked={
                      AccessorieseditLocation?.PriceType === "Bulk "
                    }
                    onClick={(e) => setAccessoriesPriceState(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="brnd_label mb-3">
                Price<span className="required">*</span>
              </label>
              <input
                type="text"
                id="kmDriven"
                name="kmDriven"
                className="form-control type_input"
                value={AccessoriesPrice}
                onChange={handlePriceChange}
              />
            </div>

            <div className="data_Category mb-4">
              <h2 className="selct_hding">
                Upload up to 20 photos<span className="required">*</span>
              </h2>
              {!showAccessoriesImage.length ? (
                <p className="mntion_key-image">
                  Please Select at least 1 Image
                </p>
              ) : null}

              <div className="upload-parts-image-grid">
                {showAccessoriesImage.length > 0
                  ? showAccessoriesImage.map((accessoriesImg, i) => {
                      return (
                        <div className="outer-div-vehicle" key={i}>
                          <div className="image-parts-radio-buttons">
                            <label>
                              <input
                                type="radio"
                                className="image-radio select-icons-image"
                                name="partImage"
                                onChange={() =>
                                  handleImageSelection(
                                    accessoriesImg.imageUrl || accessoriesImg
                                  )
                                }
                                checked={
                                  accessoriesSelectedImage ===
                                    (accessoriesImg.imageUrl ||
                                      accessoriesImg) ||
                                  (oneImagTrue &&
                                    showAccessoriesImage.length === 1) ||
                                  accessoriesImg?.imageUrl === trueImageData ||
                                  (dummyTest?.length === 1 &&
                                    accessoriesImg?.imageUrl ===
                                      dummyTest?.[0]?.imageUrl)
                                }
                              />

                              <img
                                src={accessoriesImg.imageUrl || accessoriesImg}
                                alt={accessoriesImg}
                                className="upload-part-images"
                              />
                            </label>
                          </div>
                          <ImCross
                            onClick={() => deleteItem(accessoriesImg, i)}
                            className="delete-part-image-icon  delete-image-icon-delete"
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>

              <input
                type="file"
                multiple
                name="partsuploadfile"
                id="accessoriesimg"
                className="input-parts-field-image"
                onChange={handleAccessoriesImageUpload}
              />

              <label
                htmlFor="accessoriesimg"
                className="label-parts-upload-image"
              >
                <AiOutlineCloudUpload className="upload-icon-image" />
                <h5 className="para-parts-upload-image">
                  browse to choose files
                </h5>
              </label>
            </div>
            <div className="data_Category mb-4">
              <h2 className="selct_hding">Confirm Your Location</h2>

              <div className="mt-2">
                <div className="upload_tabs">
                  <div className="tab-content mt-3" id="pills-tabContent">
                    <Tabs
                      defaultActiveKey="AccessoryList"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                    >
                      <Tab
                        eventKey="AccessoryList"
                        title="List"
                        className="postAdd_btn_1"
                      >
                        <AccessoryList
                          AccessorieseditLocation={AccessorieseditLocation}
                          setAccessoriessetCity={setAccessoriessetCity}
                          Accessoriescity={Accessoriescity}
                          accessoryneighbourhood={accessoryneighbourhood}
                          acessoryState={accessorystate}
                          setAccessoryNeighbourhood={setAccessoryNeighbourhood}
                          countryId={countryId}
                          setCountryId={setCountryId}
                          country={country}
                          setCountry={setCountry}
                          setAcessoryState={setAcessoryState}
                          oldStateid={oldStateid}
                        />
                      </Tab>
                      <Tab
                        eventKey="Location"
                        title="Location"
                        className="postAdd_btn_2"
                      >
                        <Location />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="data_Category mb-4">
              <div className="mt-2">
                {AccessorieseditLocation ? (
                  <button onClick={handleUpdate} className="sbmit_btn_end">
                    Update
                  </button>
                ) : (
                  <button
                    onClick={handleAccesoriesSubmit}
                    className="sbmit_btn_end"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Accessories;
